import { Action, History, Location, LocationState, Path, UnregisterCallback } from 'history';
import { Container, Service, Token } from 'typedi';

export const HistoryToken = new Token<History>();

@Service()
export class Router {
  private get history(): History {
    if (!this._history) {
      this._history = Container.get(HistoryToken);
    }
    return this._history;
  }

  // Lazy-loading
  private _history: History;

  private unblock: UnregisterCallback;

  push(path: Path, state?: LocationState): void {
    this.history.push(this.computePath(path), state);
    try {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant' as any,
      });
    } catch (error) {
      console.error('ERROR: router.ts ~ line 29 ~ Router ~ push ~ error', error);
    }
  }

  replace(path: Path, state?: LocationState): void {
    this.history.replace(this.computePath(path), state);
  }

  go(n: number): void {
    this.history.go(n);
  }
  goBack(): void {
    this.history.goBack();
  }

  goForward(): void {
    this.history.goForward();
  }
  /**
   * returns the "UnregisterCallback" which could be called to unblock navigation
   * @param unblockCallback a call that has the "UnregisterCallback" which must
   * be called in order to block subsequent navigations
   */
  block(
    unblockCallback: (unblock: UnregisterCallback, location: Location, action: Action) => void,
  ): UnregisterCallback {
    // https://github.com/ReactTraining/history/blob/master/docs/blocking-transitions.md#blocking-transitions
    this.unblock = this.history.block((location, action) => {
      unblockCallback(this.unblock, location, action);
      return false;
    });
    return this.unblock;
  }

  private computePath(path: Path): Path {
    if (isRelative(path)) {
      return this.resolveRelativePath(path);
    }
    return path;
  }

  private resolveRelativePath(path: Path): Path {
    if (isRelativeToLocation(path)) {
      return this.history.location.pathname + path.substr(1);
    }

    const pathSegments = path.split('/');
    const trimCount = pathSegments.filter(segment => segment === '..').length;
    const locationPathSegments = this.history.location.pathname.split('/').filter(segment => segment.length > 0);

    locationPathSegments.splice(trimCount);

    return '/' + [...locationPathSegments, ...pathSegments.slice(trimCount)].join('/');
  }
}

function isRelative(path: Path): boolean {
  return /^.{1,2}\/\S+$/.test(path);
}

function isRelativeToLocation(path: Path): boolean {
  return /^.{1}\/\S+$/.test(path);
}
