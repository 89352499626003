

/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AllUserDataRequest
// ====================================================

export interface AllUserDataRequest_AllUserDataRequest {
  id: string;                        // Doctor id
  name: string;                      // Doctor name
  email: string;                     // Doctor email
  phone: string | null;              // Doctor phone
  crm: string;                       // Doctor CRM
  uf: string;                        // Doctor federal unit
  rememberMe: boolean | null;        // Remember me login option
  repTerritory: string | null;       // Doctor rep territory code
  repTerritoryAdult: string | null;  // Doctor rep adult territory code
  newsletterOptIn: boolean | null;   // Whether user wants to receive VacinaCerta email newsletter
  smsOptIn: boolean | null;          // Whether user wants to receive VacinaCerta sms
}

export interface AllUserDataRequest {
  AllUserDataRequest: AllUserDataRequest_AllUserDataRequest | null;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ChangePassword
// ====================================================

export interface ChangePassword_ChangePassword {
  id: string;                        // Doctor id
  name: string;                      // Doctor name
  email: string;                     // Doctor email
  phone: string | null;              // Doctor phone
  crm: string;                       // Doctor CRM
  uf: string;                        // Doctor federal unit
  rememberMe: boolean | null;        // Remember me login option
  repTerritory: string | null;       // Doctor rep territory code
  repTerritoryAdult: string | null;  // Doctor rep adult territory code
  newsletterOptIn: boolean | null;   // Whether user wants to receive VacinaCerta email newsletter
  smsOptIn: boolean | null;          // Whether user wants to receive VacinaCerta sms
}

export interface ChangePassword {
  ChangePassword: ChangePassword_ChangePassword | null;
}

export interface ChangePasswordVariables {
  data: ChangePasswordInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Contact
// ====================================================

export interface Contact_Contact {
  success: boolean;  // Whether the email/message was sent
}

export interface Contact {
  Contact: Contact_Contact | null;
}

export interface ContactVariables {
  data: ContactInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteUser
// ====================================================

export interface DeleteUser_DeleteUser {
  id: string;                        // Doctor id
  name: string;                      // Doctor name
  email: string;                     // Doctor email
  phone: string | null;              // Doctor phone
  crm: string;                       // Doctor CRM
  uf: string;                        // Doctor federal unit
  rememberMe: boolean | null;        // Remember me login option
  repTerritory: string | null;       // Doctor rep territory code
  repTerritoryAdult: string | null;  // Doctor rep adult territory code
  newsletterOptIn: boolean | null;   // Whether user wants to receive VacinaCerta email newsletter
  smsOptIn: boolean | null;          // Whether user wants to receive VacinaCerta sms
}

export interface DeleteUser {
  DeleteUser: DeleteUser_DeleteUser | null;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ForgotPassword
// ====================================================

export interface ForgotPassword_ForgotPassword {
  message: string;  // Forgot password flow final message.
}

export interface ForgotPassword {
  ForgotPassword: ForgotPassword_ForgotPassword | null;
}

export interface ForgotPasswordVariables {
  data: ForgotPasswordInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: Login
// ====================================================

export interface Login_Login_user {
  id: string;                        // Doctor id
  name: string;                      // Doctor name
  email: string;                     // Doctor email
  phone: string | null;              // Doctor phone
  crm: string;                       // Doctor CRM
  uf: string;                        // Doctor federal unit
  rememberMe: boolean | null;        // Remember me login option
  repTerritory: string | null;       // Doctor rep territory code
  repTerritoryAdult: string | null;  // Doctor rep adult territory code
  newsletterOptIn: boolean | null;   // Whether user wants to receive VacinaCerta email newsletter
  smsOptIn: boolean | null;          // Whether user wants to receive VacinaCerta sms
}

export interface Login_Login {
  user: Login_Login_user;
  token: string;  // JWT token
}

export interface Login {
  Login: Login_Login | null;
}

export interface LoginVariables {
  data: LoginInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: OptOutUserMutation
// ====================================================

export interface OptOutUserMutation_OptOutUser {
  success: boolean;  // Whether opt out was succesful
  message: string;   // Opt out user flow final message.
}

export interface OptOutUserMutation {
  OptOutUser: OptOutUserMutation_OptOutUser | null;
}

export interface OptOutUserMutationVariables {
  data: OptOutUserInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RecommendationCreate
// ====================================================

export interface RecommendationCreate_RecommendationCreate {
  id: string;  // Recommendation id
}

export interface RecommendationCreate {
  RecommendationCreate: RecommendationCreate_RecommendationCreate | null;
}

export interface RecommendationCreateVariables {
  data: RecommendationInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RecommendationUpdate
// ====================================================

export interface RecommendationUpdate_RecommendationUpdate {
  id: string;  // Recommendation id
}

export interface RecommendationUpdate {
  RecommendationUpdate: RecommendationUpdate_RecommendationUpdate | null;
}

export interface RecommendationUpdateVariables {
  data: RecommendationUpdateInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SuggestAdministrationPlace
// ====================================================

export interface SuggestAdministrationPlace_SuggestAdministrationPlace {
  id: string | null;                // Administration place suggestion id
  name: string;                     // Administration place name
  email: string | null;             // Administration place email
  street: string | null;            // Administration place street
  complement: string | null;        // Administration place complement
  neighborhood: string | null;      // Administration place neighborhood
  zipCode: string | null;           // Administration place zip code
  city: string | null;              // Administration place city
  state: string | null;             // Administration state
  phoneNumber: string | null;       // Administration place phone number
  homeVaccination: boolean | null;  // Whether this place administrates vaccination at home
}

export interface SuggestAdministrationPlace {
  SuggestAdministrationPlace: SuggestAdministrationPlace_SuggestAdministrationPlace | null;
}

export interface SuggestAdministrationPlaceVariables {
  data: AdministrationPlaceSuggestionInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateEmailAsAdmin
// ====================================================

export interface UpdateEmailAsAdmin_UpdateEmailAsAdmin {
  message: string;  // Response message
}

export interface UpdateEmailAsAdmin {
  UpdateEmailAsAdmin: UpdateEmailAsAdmin_UpdateEmailAsAdmin | null;
}

export interface UpdateEmailAsAdminVariables {
  data: UpdateEmailAsAdminInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePasswordAsAdmin
// ====================================================

export interface UpdatePasswordAsAdmin_UpdatePasswordAsAdmin {
  name: string;      // Doctor name
  email: string;     // Doctor email
  password: string;  // Doctor password
}

export interface UpdatePasswordAsAdmin {
  UpdatePasswordAsAdmin: UpdatePasswordAsAdmin_UpdatePasswordAsAdmin | null;
}

export interface UpdatePasswordAsAdminVariables {
  data: UpdatePasswordAsAdminInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdatePassword
// ====================================================

export interface UpdatePassword_UpdatePassword {
  message: string;  // Update password flow final message.
}

export interface UpdatePassword {
  UpdatePassword: UpdatePassword_UpdatePassword | null;
}

export interface UpdatePasswordVariables {
  data: UpdatePasswordInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UserCreate
// ====================================================

export interface UserCreate_UserCreate_user {
  id: string;                        // Doctor id
  name: string;                      // Doctor name
  email: string;                     // Doctor email
  phone: string | null;              // Doctor phone
  crm: string;                       // Doctor CRM
  uf: string;                        // Doctor federal unit
  rememberMe: boolean | null;        // Remember me login option
  repTerritory: string | null;       // Doctor rep territory code
  repTerritoryAdult: string | null;  // Doctor rep adult territory code
  newsletterOptIn: boolean | null;   // Whether user wants to receive VacinaCerta email newsletter
  smsOptIn: boolean | null;          // Whether user wants to receive VacinaCerta sms
}

export interface UserCreate_UserCreate {
  user: UserCreate_UserCreate_user;
  token: string;  // JWT token
}

export interface UserCreate {
  UserCreate: UserCreate_UserCreate | null;
}

export interface UserCreateVariables {
  data: DoctorInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UserUpdate
// ====================================================

export interface UserUpdate_UserUpdate {
  id: string;                       // Doctor id
  phone: string | null;             // Doctor phone
  email: string;                    // Doctor email
  newsletterOptIn: boolean | null;  // Whether user wants to receive VacinaCerta email newsletter
  smsOptIn: boolean | null;         // Whether user wants to receive VacinaCerta sms
}

export interface UserUpdate {
  UserUpdate: UserUpdate_UserUpdate | null;
}

export interface UserUpdateVariables {
  data: UpdateDoctorInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdministrationPlacesForPosition
// ====================================================

export interface AdministrationPlacesForPosition_AdministrationPlacesForPosition {
  id: string;                       // Administration place id
  name: string;                     // Administration place name
  email: string | null;             // Administration place email
  latitude: number;                 // Administration place latitude
  longitude: number;                // Administration place longitude
  phoneNumber: string | null;       // Administration place phone number
  type: AdministrationPlaceKind;    // Administration place kind
  street: string | null;            // Administration place street
  complement: string | null;        // Administration place complement
  neighborhood: string | null;      // Administration place neighborhood
  zipCode: string | null;           // Administration place zip code
  homeVaccination: boolean | null;  // Whether home vaccination is offered
  city: string | null;              // Administration place city
  state: string | null;             // Administration state
  distance: number | null;          // Administration place distance. This   can be null if the server doesnt have a reference to consider when computing this distance
}

export interface AdministrationPlacesForPosition {
  AdministrationPlacesForPosition: (AdministrationPlacesForPosition_AdministrationPlacesForPosition | null)[] | null;
}

export interface AdministrationPlacesForPositionVariables {
  data: AdministrationPlacesForPositionInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllRecommendations
// ====================================================

export interface AllRecommendations_Recommendations_vaccines {
  id: string;                      // Vaccine id
  name: string;                    // Vaccine name
  brand: string | null;            // Vaccine brand
  laboratory: string | null;       // Vaccine laboratory
  vaccineFamilyId: number | null;  // Vaccine family Id
  injectionCount: number;          // Number of injections for this vaccine
  priority: number | null;         // Vaccine priority (related to "specialNeed" field - Whether the vaccine falls into the "especialmente recomendadas" (2) or "outras vacinas recomendadas (1) group
  specialNeed: string | null;      // Special need name (a.k.a immunocompromised comorbidities)
}

export interface AllRecommendations_Recommendations {
  id: string;                                                               // Recommendation id
  age: string | null;                                                       // Patient age
  vaccines: (AllRecommendations_Recommendations_vaccines | null)[] | null;  // Associated vaccines
}

export interface AllRecommendations {
  Recommendations: (AllRecommendations_Recommendations | null)[] | null;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllVaccineFamiliesByAge
// ====================================================

export interface AllVaccineFamiliesByAge_AllVaccineFamiliesByAge_families {
  id: string;    // Vaccine family id
  name: string;  // Vaccine family name
}

export interface AllVaccineFamiliesByAge_AllVaccineFamiliesByAge {
  months: number;                                                                 // age (in months)
  families: (AllVaccineFamiliesByAge_AllVaccineFamiliesByAge_families | null)[];  // Families for an age.
}

export interface AllVaccineFamiliesByAge {
  AllVaccineFamiliesByAge: (AllVaccineFamiliesByAge_AllVaccineFamiliesByAge | null)[] | null;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Doctor
// ====================================================

export interface Doctor_Doctor {
  id: string;                        // Doctor id
  name: string;                      // Doctor name
  email: string;                     // Doctor email
  phone: string | null;              // Doctor phone
  crm: string;                       // Doctor CRM
  uf: string;                        // Doctor federal unit
  rememberMe: boolean | null;        // Remember me login option
  repTerritory: string | null;       // Doctor rep territory code
  repTerritoryAdult: string | null;  // Doctor rep adult territory code
  newsletterOptIn: boolean | null;   // Whether user wants to receive VacinaCerta email newsletter
  smsOptIn: boolean | null;          // Whether user wants to receive VacinaCerta sms
}

export interface Doctor {
  Doctor: Doctor_Doctor | null;
}

export interface DoctorVariables {
  id?: number | null;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: VaccineByFamily
// ====================================================

export interface VaccineByFamily_VaccineByFamily_family {
  id: string;    // Vaccine family id
  name: string;  // Vaccine family name
}

export interface VaccineByFamily_VaccineByFamily_vaccines {
  id: string;                               // Vaccine id
  name: string;                             // Vaccine name
  brand: string | null;                     // Vaccine brand
  description: string | null;               // Vaccine description
  isAdministeredOnPublicClinics: boolean;   // Whether this vaccine is administered on public clinics
  isAdministeredOnPrivateClinics: boolean;  // Whether this vaccine is administered on private clinics
  mouthDropCount: number;                   // Number of mouth drops for this vaccine
  injectionCount: number;                   // Number of injections for this vaccine
  ages: (number | null)[] | null;           // Ages (in months) to be administered
  comment: string | null;                   // Vaccine comments/observations
  laboratory: string | null;                // Vaccine laboratory
  specialNeed: string | null;               // Special need name (a.k.a immunocompromised comorbidities)
  priority: number | null;                  // Vaccine priority (related to "specialNeed" field - Whether the vaccine falls into the "especialmente recomendadas" (2) or "outras vacinas recomendadas (1) group
}

export interface VaccineByFamily_VaccineByFamily {
  family: VaccineByFamily_VaccineByFamily_family;                 // Vaccine family.
  vaccines: (VaccineByFamily_VaccineByFamily_vaccines | null)[];  // Vaccines for a family.
}

export interface VaccineByFamily {
  VaccineByFamily: (VaccineByFamily_VaccineByFamily | null)[] | null;
}

export interface VaccineByFamilyVariables {
  data: VaccineByFamilyInput;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: VaccineBySpecialNeed
// ====================================================

export interface VaccineBySpecialNeed_VaccineBySpecialNeed_family {
  id: string;    // Vaccine family id
  name: string;  // Vaccine family name
}

export interface VaccineBySpecialNeed_VaccineBySpecialNeed_vaccines {
  id: string;                               // Vaccine id
  name: string;                             // Vaccine name
  brand: string | null;                     // Vaccine brand
  description: string | null;               // Vaccine description
  isAdministeredOnPublicClinics: boolean;   // Whether this vaccine is administered on public clinics
  isAdministeredOnPrivateClinics: boolean;  // Whether this vaccine is administered on private clinics
  mouthDropCount: number;                   // Number of mouth drops for this vaccine
  injectionCount: number;                   // Number of injections for this vaccine
  ages: (number | null)[] | null;           // Ages (in months) to be administered
  comment: string | null;                   // Vaccine comments/observations
  laboratory: string | null;                // Vaccine laboratory
  specialNeed: string | null;               // Special need name (a.k.a immunocompromised comorbidities)
  priority: number | null;                  // Vaccine priority (related to "specialNeed" field - Whether the vaccine falls into the "especialmente recomendadas" (2) or "outras vacinas recomendadas (1) group
}

export interface VaccineBySpecialNeed_VaccineBySpecialNeed {
  family: VaccineBySpecialNeed_VaccineBySpecialNeed_family;                 // Vaccine family.
  vaccines: (VaccineBySpecialNeed_VaccineBySpecialNeed_vaccines | null)[];  // Vaccines for a family.
}

export interface VaccineBySpecialNeed {
  VaccineBySpecialNeed: (VaccineBySpecialNeed_VaccineBySpecialNeed | null)[] | null;
}

export interface VaccineBySpecialNeedVariables {
  specialNeed: string;
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AdministrationPlaceFields
// ====================================================

export interface AdministrationPlaceFields {
  id: string;                       // Administration place id
  name: string;                     // Administration place name
  email: string | null;             // Administration place email
  latitude: number;                 // Administration place latitude
  longitude: number;                // Administration place longitude
  phoneNumber: string | null;       // Administration place phone number
  type: AdministrationPlaceKind;    // Administration place kind
  street: string | null;            // Administration place street
  complement: string | null;        // Administration place complement
  neighborhood: string | null;      // Administration place neighborhood
  zipCode: string | null;           // Administration place zip code
  homeVaccination: boolean | null;  // Whether home vaccination is offered
  city: string | null;              // Administration place city
  state: string | null;             // Administration state
  distance: number | null;          // Administration place distance. This   can be null if the server doesnt have a reference to consider when computing this distance
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DoctorFields
// ====================================================

export interface DoctorFields {
  id: string;                        // Doctor id
  name: string;                      // Doctor name
  email: string;                     // Doctor email
  phone: string | null;              // Doctor phone
  crm: string;                       // Doctor CRM
  uf: string;                        // Doctor federal unit
  rememberMe: boolean | null;        // Remember me login option
  repTerritory: string | null;       // Doctor rep territory code
  repTerritoryAdult: string | null;  // Doctor rep adult territory code
  newsletterOptIn: boolean | null;   // Whether user wants to receive VacinaCerta email newsletter
  smsOptIn: boolean | null;          // Whether user wants to receive VacinaCerta sms
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: VaccineFamilyFields
// ====================================================

export interface VaccineFamilyFields {
  id: string;    // Vaccine family id
  name: string;  // Vaccine family name
}


/* tslint:disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: VaccineFields
// ====================================================

export interface VaccineFields {
  id: string;                               // Vaccine id
  name: string;                             // Vaccine name
  brand: string | null;                     // Vaccine brand
  description: string | null;               // Vaccine description
  isAdministeredOnPublicClinics: boolean;   // Whether this vaccine is administered on public clinics
  isAdministeredOnPrivateClinics: boolean;  // Whether this vaccine is administered on private clinics
  mouthDropCount: number;                   // Number of mouth drops for this vaccine
  injectionCount: number;                   // Number of injections for this vaccine
  ages: (number | null)[] | null;           // Ages (in months) to be administered
  comment: string | null;                   // Vaccine comments/observations
  laboratory: string | null;                // Vaccine laboratory
  specialNeed: string | null;               // Special need name (a.k.a immunocompromised comorbidities)
  priority: number | null;                  // Vaccine priority (related to "specialNeed" field - Whether the vaccine falls into the "especialmente recomendadas" (2) or "outras vacinas recomendadas (1) group
}

/* tslint:disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

// Administration place kind enum
export enum AdministrationPlaceKind {
  PRIVATE = "PRIVATE",
  PUBLIC = "PUBLIC",
}

// Change password input object.
export interface ChangePasswordInput {
  oldPassword: string;
  newPassword: string;
}

// Contact screen input
export interface ContactInput {
  name: string;
  email: string;
  message: string;
}

// Forgot password input object.
export interface ForgotPasswordInput {
  email?: string | null;
  crm?: string | null;
  uf?: string | null;
}

// Login input object.
export interface LoginInput {
  email: string;
  password: string;
  rememberMe: boolean;
}

// Opt out user input object.
export interface OptOutUserInput {
  email?: string | null;
  crm?: string | null;
  uf?: string | null;
}

// A recommendation input object.
export interface RecommendationInput {
  crm: string;
  uf: string;
  age?: string | null;
  repTerritory?: string | null;
  administrationPlacesIds?: (RecommendationAdministrationPlaceInput | null)[] | null;
  vaccinesIds?: (RecommendationVaccineInput | null)[] | null;
}

// A recommendation administration place input object.
export interface RecommendationAdministrationPlaceInput {
  administrationPlaceId?: string | null;
}

// A recommendation vaccine input object.
export interface RecommendationVaccineInput {
  vaccineId?: string | null;
}

// A recommendation update input object.
export interface RecommendationUpdateInput {
  id: string;
  print?: boolean | null;
  vaccineBrand?: boolean | null;
  email?: boolean | null;
}

// Vaccine administration place suggestion.
export interface AdministrationPlaceSuggestionInput {
  administrationPlaceId?: string | null;
  name: string;
  email?: string | null;
  phoneNumber?: string | null;
  street?: string | null;
  complement?: string | null;
  neighborhood?: string | null;
  zipCode?: string | null;
  city?: string | null;
  state?: string | null;
  homeVaccination?: boolean | null;
  comment?: string | null;
}

// Update password input object.
export interface UpdateEmailAsAdminInput {
  crm: string;
  uf: string;
  email: string;
}

// Update password input object.
export interface UpdatePasswordAsAdminInput {
  crm: string;
  uf: string;
}

// Update password input object.
export interface UpdatePasswordInput {
  email: string;
  password: string;
  code: string;
}

// An user input object.
export interface DoctorInput {
  name?: string | null;
  email: string;
  password: string;
  crm: string;
  uf: string;
  phone?: string | null;
  newsletterOptIn?: boolean | null;
  smsOptIn?: boolean | null;
}

// An user input object.
export interface UpdateDoctorInput {
  email: string;
  newsletterOptIn?: boolean | null;
  phone?: string | null;
  smsOptIn?: boolean | null;
}

// Administration places for position input
export interface AdministrationPlacesForPositionInput {
  includePublicAdministrationPlace?: boolean | null;
  maxDistanceInKm?: number | null;
  latitude: number;
  longitude: number;
  filter?: AdministrationPlaceSearchFilterInput | null;
}

// Administration place search filter
export interface AdministrationPlaceSearchFilterInput {
  includePublic: boolean;
  includePrivate: boolean;
  includeHomeVaccination?: boolean | null;
}

// Vaccine by family input
export interface VaccineByFamilyInput {
  families: (string | null)[];
}

//==============================================================
// END Enums and Input Objects
//==============================================================