/**
 * Service to manage GTM ID
 */
export class GtmService {
  constructor(
    public readonly gtmId: string,
  ) {
    this.gtmId = gtmId;
  }
}
