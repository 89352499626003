import { Label } from '@components/atm.typography';
import { Color, FontSize, Spacing } from '@components/obj.constants';
import { Col, Row } from '@components/obj.grid';
import { faCheckCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const VacineItemStyled = styled.div`
  position: relative;
  padding: ${Spacing.Large};
  cursor: pointer;

  @media all and (min-width: 48em) {
    padding: ${Spacing.XLarge};
  }

  & ~ * {
    border-top: 1px solid ${Color.GrayXLight};
  }

  :last-child {
    margin-bottom: ${Spacing.XXLarge};
  }
`;

export const VacineItemCheckedStyled = styled(FontAwesomeIcon).attrs({ icon: faCheckCircle })`
  position: absolute;
  font-size: ${FontSize.XLarge};
  color: ${Color.Success};
  z-index: 1;
  opacity: 0;
`;

export const VacineItemTextContainerStyled = styled.div`
  display: block;
  padding-left: ${Spacing.XXXLarge};
  min-height: 100px;
`;

export const VacineItemWrapperStyled = styled.div`
  width: auto;
  cursor: pointer;
`;

export const VacineItemCheckContainerStyled = styled.div`
  display: block;
`;

export const VacineItemUncheckedStyled = styled(FontAwesomeIcon).attrs({ icon: faPlusCircle })`
  position: absolute;
  font-size: ${FontSize.XLarge};
  z-index: 0;
  opacity: 1;
  color: ${Color.GrayXDark};
`;

export const VacineItemInfoContainerStyled = styled.div`
  position: relative;
  height: 100%;
`;

export const VacineItemQuantityIconStyled = styled.div`
  width: 100%;
  text-align: right;
`;

export const VaccineItemMouthDropIconStyled = styled.div`
  text-align: right;
`;

export const VacineItemIconPlaceStyled = styled.div`
  color: ${Color.Success};
  font-size: ${FontSize.Large};
  margin-right: ${Spacing.Small};
`;

export const VacineItemPlaceStyled = styled.div`
  display: inline-flex;
  margin-left: ${Spacing.XLarge};
`;

export const VacineItemPlaceContainerStyled = styled.div`
  width: 100%;
`;

export const VacineItemInputValueStyled = styled.label`
  background-color: ${Color.White};
`;

export const VaccineItemQuantityTooltipStyled = styled.div`
  right: -30%;
  top: -65%;
  position: relative;
  min-width: 340px;
`;

export const VaccineItemClinicTooltipStyled = styled.div`
  left: 75%;
  top: -150px;
  position: relative;
  min-width: 340px;
`;

export const VacineItemDoseTextStyled = styled(Label)`
  vertical-align: top;
  margin-right: ${Spacing.Large};
  cursor: pointer;
`;

export const VacineItemBulletStyled = styled.input`
  display: none;

  &:checked + ${VacineItemInputValueStyled} {
    border-width: 0;
    color: ${Color.White};
    background-color: ${Color.Secondary};

    ${VacineItemCheckedStyled} {
      opacity: 1;
    }

    ${VacineItemUncheckedStyled} {
      color: ${Color.White};
    }
  }
`;

export const VacineItemRowStyled = styled(Row)`
  min-height: 100px;
`;

export const VacineInformationIcon = styled.span`
  margin-left: ${Spacing.Large};
  display: inline-block;
  vertical-align: middle;
  z-index: 10;
  cursor: pointer;
`;

export const VacineLabelStyled = styled(Label)`
  cursor: pointer;
`;

export const VaccineNameColStyled = styled(Col)``;

export const VaccinePlaceRowStyled = styled(Row)`
  text-align: right;
`;

export const VaccinePlaceColStyled = styled(Col)``;

export const VaccineTooltipStyled = styled.div`
  width: 220px;
  position: absolute;
  top: -310px;
`;
