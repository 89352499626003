import * as React from 'react';
import CookieConsentDataSource from '@app/resource/cookie-consent.datasource';
import CookieConsent, { CookieConsentKind } from './cookie-consent.component';

export const CookieConsentContainer: React.FunctionComponent = () => {
  const {
    decisionTimespan,
    initialValue,
    onAccept,
    onReject,
  } = useCookieConsent();
  if (initialValue === undefined) { return null; }
  return (
    <CookieConsent
      key={decisionTimespan}
      initialValue={initialValue}
      initialOpen={!decisionTimespan}
      onAccept={onAccept}
      onReject={onReject}
    />
  );
};

const useCookieConsent = (): {
  decisionTimespan: number;
  initialValue: (CookieConsentKind)[];
  onAccept: (kind: CookieConsentKind[]) => void;
  onReject: () => void;
} => {
  const [decisionTimespan, setDecisionTimespan] = React.useState<Date>(null);
  const [initialValue, setInitialValue] = React.useState<CookieConsentKind[]>();

  React.useEffect(() => {
    const value: CookieConsentKind[] = [
      CookieConsentDataSource.acceptedAdvertising ? CookieConsentKind.Advertising : null,
      CookieConsentDataSource.acceptedFunctional ? CookieConsentKind.Functional : null,
      CookieConsentDataSource.acceptedPerformance ? CookieConsentKind.Performance : null,
    ].filter(item => item);
    setInitialValue(value);
    setDecisionTimespan(CookieConsentDataSource.decisionTimespan);
  }, []);

  const onAccept = (kind: CookieConsentKind[]): void => {
    const kindSet = new Set(kind);
    CookieConsentDataSource.acceptedAdvertising = kindSet.has(CookieConsentKind.Advertising);
    CookieConsentDataSource.acceptedFunctional = kindSet.has(CookieConsentKind.Functional);
    CookieConsentDataSource.acceptedPerformance = kindSet.has(CookieConsentKind.Performance);
    setDecisionTimespan(new Date());
  };
  const onReject = (): void => {
    CookieConsentDataSource.acceptedAdvertising = false;
    CookieConsentDataSource.acceptedFunctional = false;
    CookieConsentDataSource.acceptedPerformance = false;
    setDecisionTimespan(new Date());
  };
  return {
    decisionTimespan: decisionTimespan && decisionTimespan.getTime && decisionTimespan.getTime(),
    initialValue,
    onAccept,
    onReject,
  };
};
