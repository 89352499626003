import { Observer } from '@app/core/decorator/observer.decorator';
import { Router } from '@app/core/route/router';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { MobileService } from '@app/core/service/mobile-service';
import { APPOINTMENT_PATH } from '@app/modules/appointment/appointment.route';
import { AppointmentStore } from '@app/modules/appointment/appointment.store';
import { AppointmentStep, QuickRecommendationForContext } from '@app/modules/appointment/appointment.store.model';
import {
  QuickRecommendation,
} from '@components/mol.quick-recommendation-sticky-menu/quick-recommendation-fixed-menu.component';
import * as React from 'react';
import { Container } from 'typedi';

interface QuickRecommendationFixedMenuContainerChildProps {
  recommendations: QuickRecommendation[];
  handleItemClick: (recommendation: QuickRecommendation) => void;
}

interface QuickRecommendationFixedMenuContainerProps {
  quickRecommendationContext: QuickRecommendationForContext;
  children?: (props: QuickRecommendationFixedMenuContainerChildProps) => React.ReactNode;
}

@Observer()
export class QuickRecommendationFixedMenuContainer extends React.Component<QuickRecommendationFixedMenuContainerProps> {
  private appointmentStore?: AppointmentStore = Container.get(AppointmentStore);
  private router?: Router = Container.get(Router);

  render() {
    const recommendations: QuickRecommendation[] = this.appointmentStore.getAllRecommendationsForCurrentContext(
      this.props.quickRecommendationContext,
    );
    return this.props.children({
      recommendations,
      handleItemClick: this.handleQuickRecommendationClick,
    });
  }

  private handleQuickRecommendationClick = (recommendation: QuickRecommendation) => {
    this.appointmentStore.selectedRecommendationChanged(recommendation);
    if (recommendation.age) {
      const nextPath = `${APPOINTMENT_PATH}/${AppointmentStep.VaccineChoiceByAge}`;
      this.navigateToPathIfNeeded(nextPath);
    } else if (recommendation.vaccines.some(v => v.specialNeed)) {
      const nextPath = `${APPOINTMENT_PATH}/${AppointmentStep.VaccineChoiceBySpecialNeed}`;
      this.navigateToPathIfNeeded(nextPath);
    }
  };

  private navigateToPathIfNeeded(nextPath: string) {
    if (!window.location.href.includes(nextPath)) {
      this.router.push(nextPath);
    }
  }
}
