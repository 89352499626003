import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { MobileService } from '@app/core/service/mobile-service';
import shareLogDatasource from '@app/resource/share-log.datasource';
import { Button } from '@components/atm.button';
import { CloseIconStyled, CloseWrapStyled } from '@components/atm.close/close.styled';
import { Body, H3 } from '@components/atm.typography';
import { BooleanContainer } from '@components/obj.abstract-container';
import { Hbox } from '@components/obj.box';
import { Color, FontFamily, FontSize, Spacing } from '@components/obj.constants';
import { Frame } from '@components/obj.frame';
import { Separator } from '@components/obj.grid';
import { Modal } from '@components/obj.modal';
import { faFacebook, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { Container } from 'typedi';

const urlToShare = `https://www.vacinacerta.com.br`;
const textToShare = `Olá, estou usando o Vacina Certa para receitar vacinas aos meus pacientes. Recomendo!
${urlToShare}`;

export const ShareVacinaCerta: React.FunctionComponent<any> = () => {
  const [showShareFlash, setShowShareFlash] = React.useState(false);
  React.useEffect(() => {
    setShowShareFlash(!shareLogDatasource.hasClosedShare);
  }, []);
  const handleCloseClick = () => {
    shareLogDatasource.hasClosedShare = true;
    setShowShareFlash(false);
  };

  return (
    <>
      <BooleanContainer>
        {bool => {
          const analyticsService: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);
          const showModal = bool.setTrue;
          const hideModal = bool.setFalse;
          const handleShareFlashClick = () => {
            showModal();
            analyticsService.event('share_flash_open', 'click');
          };
          const handleShareClick = () => {
            showModal();
            analyticsService.event('share_open', 'click');
          };
          const handleModalClose = () => {
            hideModal();
            analyticsService.event('share_close', 'click');
          };
          const handleSendEmailClick = () => {
            const href = `mailto:?subject=Recomendação médica&body=${encodeURIComponent(textToShare)}`;
            window.open(href, '_blank');
            analyticsService.event('share_vc_email', 'click');
          };

          const handleWhatsappClick = () => {
            const href = `https://api.whatsapp.com/send?text=${encodeURIComponent(textToShare)}`;
            window.open(href, '_blank');
            analyticsService.event('share_vc_whatsapp', 'click');
          };

          const handleFacebookClick = () => {
            const href = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(urlToShare)}`;
            window.open(href, '_blank');
            analyticsService.event('share_vc_facebook', 'click');
          };

          const mobileService: MobileService = Container.get(MobileService);
          return (
            <>
              {showShareFlash ? (
                <ShareFlash onCloseClick={handleCloseClick} onShareClick={handleShareFlashClick} />
              ) : (
                <ShareFixed onClick={handleShareClick} />
              )}
              <Modal small={true} opened={bool.value} onClose={handleModalClose}>
                <>
                  <H3>Recomende o Vacina Certa</H3>
                  <Body>Compartilhe o Vacina Certa com outros profissionais de saúde!</Body>
                  <Separator />
                  <Hbox>
                    <Hbox.Item noGrow={true}>
                      <Button
                        kind='primary'
                        width='50px'
                        data-tip={mobileService.onMobile ? undefined : 'E-mail'}
                        onClick={handleSendEmailClick}
                      >
                        <FontAwesomeIcon fixedWidth={true} icon={faEnvelope} />
                      </Button>
                    </Hbox.Item>
                    <Hbox.Separator />
                    <Hbox.Separator />
                    <Hbox.Item noGrow={true}>
                      <Button
                        kind='primary'
                        width='50px'
                        data-tip={mobileService.onMobile ? undefined : 'Whatsapp'}
                        onClick={handleWhatsappClick}
                      >
                        <FontAwesomeIcon fixedWidth={true} icon={faWhatsapp} />
                      </Button>
                    </Hbox.Item>
                    <Hbox.Separator />
                    <Hbox.Separator />
                    <Hbox.Item noGrow={true}>
                      <Button
                        kind='primary'
                        width='50px'
                        data-tip={mobileService.onMobile ? undefined : 'Facebook'}
                        onClick={handleFacebookClick}
                      >
                        <FontAwesomeIcon fixedWidth={true} icon={faFacebook} />
                      </Button>
                    </Hbox.Item>
                  </Hbox>
                  <ReactTooltip
                    className={'react-tooltip'}
                    backgroundColor={Color.White}
                    border={true}
                    borderColor={Color.Black}
                    textColor={Color.Black}
                  />
                </>
              </Modal>
            </>
          );
        }}
      </BooleanContainer>
    </>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////

interface IShareFlashProps {
  onShareClick: () => void;
  onCloseClick: () => void;
}

const ShareFlash: React.FunctionComponent<IShareFlashProps> = props => {
  return (
    <Frame>
      <CloseWrapStyled>
        <CloseIconStyled onClick={props.onCloseClick} />
      </CloseWrapStyled>
      <Body center={true}>Compartilhe o Vacina Certa com outros profissionais da saúde!</Body>
      <Separator />
      <Hbox hAlign='center'>
        <Button kind='primary' onClick={props.onShareClick}>
          Recomendar o Vacina Certa
        </Button>
      </Hbox>
    </Frame>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////

interface IShareFixedProps {
  onClick: () => void;
}

const ShareFixed: React.FunctionComponent<IShareFixedProps> = props => {
  return (
    <ShareFixedStyled onClick={props.onClick}>
      <FontAwesomeIcon fixedWidth={true} icon={faShareAlt} /> <ShareFixedTextStyled> Recomendar </ShareFixedTextStyled>
    </ShareFixedStyled>
  );
};

const ShareFixedStyled = styled.div`
  display: flex;
  align-items: center;

  position: fixed;
  z-index: 98;
  right: 0;
  top: 50%;
  transform: translate(0px, -50%);

  cursor: pointer;
  background: ${Color.Primary};
  padding: ${Spacing.Small};

  color: ${Color.White};
  border-radius: ${Spacing.Small} 0 0 ${Spacing.Small};
  text-transform: uppercase;
  writing-mode: vertical-lr;
  text-orientation: sideways;
`;

const ShareFixedTextStyled = styled.span`
  font-size: ${FontSize.XSmall};
  font-family: ${FontFamily.Secondary};
  transform: rotate(180deg);
  margin-top: ${Spacing.Small};
`;
