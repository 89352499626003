import SvgAge from '@app/components/svg/img-how-it-works-age';
import SvgAvatar from '@app/components/svg/img-how-it-works-avatar';
import SvgClinic from '@app/components/svg/img-how-it-works-clinic';
import SvgVaccine from '@app/components/svg/img-how-it-works-vaccine';
import { Col } from '@components/obj.grid';
import * as React from 'react';
import { HowItWorksCell } from './how-it-works-cell.component';
import { HowItWorksRowStyled } from './how-it-works-row.style';

export interface HowItWorksRowProps {
  title: string;
  description: string;
  img: string;
}

export class HowItWorksRow extends React.Component<HowItWorksRowProps, any> {
  constructor(props: HowItWorksRowProps) {
    super(props);
  }

  render() {
    return (
      <HowItWorksRowStyled center='xs'>
        <Col xs={10} sm={3}>
          <HowItWorksCell
            img={<SvgAge />}
            title={'Calendários para todas as idades'}
            description={'Praticidade na consulta dos calendários vacinais recomendados pela SBP e SBIm.'}
          />
        </Col>
        <Col xs={10} sm={3}>
          <HowItWorksCell
            img={<SvgAvatar />}
            title={'Resgate de doses em atraso'}
            description={'Acesso rápido sobre como regularizar os esquemas vacinais atrasados.'}
          />
        </Col>
        <Col xs={10} sm={3}>
          <HowItWorksCell
            img={<SvgVaccine />}
            title={'Mais agilidade nas suas consultas'}
            description={'Em poucos cliques você pode gerar uma recomendação vacinal para os seus pacientes.'}
          />
        </Col>
        <Col xs={10} sm={3}>
          <HowItWorksCell
            img={<SvgClinic />}
            title={'Encontre locais de vacinação'}
            description={'Saiba onde estão os centros públicos e privados mais convenientes para seus pacientes.'}
          />
        </Col>
      </HowItWorksRowStyled>
    );
  }
}
