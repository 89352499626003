import { Spacing } from '@components/obj.constants';
import styled, { css, keyframes } from 'styled-components';
import { ModalProps } from './modal.component';

export const ModalStyled = styled.div`
  position: absolute;
  visibility: ${(props: ModalProps) => (props.opened ? 'visible' : 'hidden')};
  z-index: 99;
`;

const rotate360 = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const ModalOpacityStyled = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 8;
  background-color: #000;
  opacity: ${(props: ModalProps) => (props.opened ? '0.5' : '0')};
  transition: all 0.4s ease-out;
  visibility: ${(props: ModalProps) => (props.opened ? 'visible' : 'hidden')};
`;

const modalAnimation = css`
  ${rotate360} 0.5s ease-out;
`;

export const ModalBoxStyled = styled.div`
  position: fixed;
  top: 80px;

  width: ${(props: ModalProps) => (props.small ? '360px' : '90%')};
  max-width: 90%;
  left: 50%;
  transform: translate(-50%, 0);

  z-index: 10;
  background-color: #fff;
  border-radius: 2px;
  overflow: hidden;
  animation: ${(props: ModalProps) => (props.noAnimation ? 'none' : modalAnimation)};
`;

export const ModalBoxBodyStyled = styled.div`
  max-height: ${(props: ModalProps) => props.maxHeight || '80vh'};
  overflow-y: scroll;
  padding: ${(props: ModalProps) => (props.noGutter ? '0' : Spacing.XLarge)};
`;
