import { PropsBase } from '@app/core/base/props.base';
import { Observer } from '@app/core/decorator/observer.decorator';
import { AppointmentStore } from '@app/modules/appointment/appointment.store';
import { ProfileChoiceView } from '@app/modules/appointment/profile-choice.view';
import * as React from 'react';
import { Container } from 'typedi';
import { AppointmentStep } from './appointment.store.model';

@Observer()
export class ProfileChoiceData extends React.Component<PropsBase> {
  private appointmentStore?: AppointmentStore = Container.get(AppointmentStore);

  constructor(props) {
    super(props);
    this.appointmentStore.currentAppointmentStep = AppointmentStep.ProfileChoice;
  }

  render() {
    return <ProfileChoiceView {...this.props} />;
  }
}
