import styled from 'styled-components';

export const PrintableStyled = styled.div`
  display: none;

  @media print {
    @page {
      size: A5;
    }
    display: block;
  }
`;

export const NotPrintableStyled = styled.div`
  display: block;

  @media print {
    display: none;
  }
`;
