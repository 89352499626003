import { Service } from 'typedi';

// TODO: move this class to a package folder. sub-folder extension?
@Service()
export class DateStringService {
  getFormattedTodayDate = (): string => {
    const today = new Date();
    let dd = today.getDate().toString();
    let mm = (today.getMonth() + 1).toString();
    const yyyy = today.getFullYear().toString();

    if (+dd < 10) {
      dd = '0' + dd.toString();
    }

    if (+mm < 10) {
      mm = '0' + mm.toString();
    }

    return `${dd}/${mm}/${yyyy}`;
  };

  getFormattedTodayTime = (): string => {
    const date = new Date();
    return `${date.getHours()}:${date.getMinutes()}`;
  };

  getFormattedDateForFile() {
    const d = new Date();
    const month = '' + (d.getMonth() + 1);
    const day = '' + d.getDate();
    const year = d.getFullYear();
    const hour = d.getHours();
    const minute = d.getMinutes();

    const twoDigit = num => `0${num}`.slice(-2);

    return [year, ...[month, day, hour, minute].map(twoDigit)].join('-');
  }
}
