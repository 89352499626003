import SvgAdolescente from '@app/components/svg/adolescente';
import SvgAdulto from '@app/components/svg/adulto';
import SvgBebe from '@app/components/svg/bebe';
import SvgGestante from '@app/components/svg/gestante';
import SvgIdoso from '@app/components/svg/idoso';
import SvgImunoComprometidos from '@app/components/svg/imuno-comprometidos';
import SvgLactente from '@app/components/svg/lactente';
import SvgOcupacional from '@app/components/svg/ocupacional';
import SvgPrematuro from '@app/components/svg/prematuro';
import { PropsBase } from '@app/core/base/props.base';
import { Observer } from '@app/core/decorator/observer.decorator';
import { Router } from '@app/core/route/router';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { APPOINTMENT_PATH } from '@app/modules/appointment/appointment.route';
import { AppointmentStore } from '@app/modules/appointment/appointment.store';
import { Body, H1 } from '@components/atm.typography';
import { MediaObject } from '@components/mol.media-object/media-object.component';
import QuickRecommendationFixedMenu from '@components/mol.quick-recommendation-sticky-menu/quick-recommendation-fixed-menu.component';
import { QuickRecommendationFixedMenuContainer } from '@components/mol.quick-recommendation-sticky-menu/quick-recommendation-fixed-menu.container';
import { Col, Grid, Row } from '@components/obj.grid';
import PwaInstall from '@components/obj.pwa-install/pwa-install.component';
import * as React from 'react';
import { Container } from 'typedi';
import { allProfilesMap, AppointmentStep, PatientProfileKey, PatientProfileValue } from './appointment.store.model';
import { OverdueDosesChatbotContainer } from './components/overdue-doses-chatbot.container.component';

@Observer()
export class ProfileChoiceView extends React.Component<PropsBase> {
  private appointmentStore?: AppointmentStore = Container.get(AppointmentStore);
  private analyticsService?: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);
  private router?: Router = Container.get(Router);

  constructor(props) {
    super(props);
    this.appointmentStore.reset = true;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Grid>
        <Row>
          <Col xs={12}>
            <H1>1. Escolha do calendário</H1>
            <Body>Selecione o calendário que mais se aplica ao seu paciente.</Body>
          </Col>
        </Row>
        <Row>
          {Array.from(allProfilesMap)
            .map((value: [PatientProfileKey, PatientProfileValue]) => value[1])
            .map((profile: PatientProfileValue) => (
              <Col xs={12} sm={6} md={4} key={profile.id}>
                <MediaObject
                  name={profile.name}
                  icon={this.mapPatientProfileKeyToIcon(profile.id)}
                  caption={profile.age}
                  enabled={profile.enabled}
                  onClick={this.handleButtonOnClick(profile)}
                />
              </Col>
            ))}
        </Row>
        <PwaInstall />
        <QuickRecommendationFixedMenuContainer
          quickRecommendationContext='none'
        >
          {childProps => (
            <>
              <QuickRecommendationFixedMenu
                recommendations={childProps.recommendations}
                onItemClick={childProps.handleItemClick}
              />
              <OverdueDosesChatbotContainer recommendationsCount={childProps.recommendations.length} />
            </>
          )}
        </QuickRecommendationFixedMenuContainer>

      </Grid>
    );
  }

  private mapPatientProfileKeyToIcon(key: PatientProfileKey) {
    const dict: Record<PatientProfileKey, JSX.Element> = {
      [PatientProfileKey.Premature]: <SvgPrematuro />,
      [PatientProfileKey.Infant]: <SvgLactente />,
      [PatientProfileKey.Child]: <SvgBebe />,
      [PatientProfileKey.Teenager]: <SvgAdolescente />,
      [PatientProfileKey.Adult]: <SvgAdulto />,
      [PatientProfileKey.Pregnant]: <SvgGestante />,
      [PatientProfileKey.Senior]: <SvgIdoso />,
      [PatientProfileKey.Occupational]: <SvgOcupacional />,
      [PatientProfileKey.Immunocompromised]: <SvgImunoComprometidos />,
    };
    return dict[key];
  }

  private handleButtonOnClick = (profile: PatientProfileValue) => () => {
    this.analyticsService.event('consulta_escolha_perfil', 'click', false, `${profile.name}: ${profile.age}`);
    if (profile.enabled) {
      this.appointmentStore.selectedProfileChanged(profile.id);

      const ages = this.appointmentStore.availableAgesForSelectedProfile;
      if (profile.id === PatientProfileKey.Immunocompromised) {
        this.goToImmunocompromisedChoice();
      } else if (ages.length === 1) {
        this.appointmentStore.reset = true;
        this.appointmentStore.selectedAgeChanged(ages[0]);
        this.goToFamilyChoice();
      } else if (ages.length > 1) {
        this.goToAgeChoice();
      }
    }
  };

  private goToImmunocompromisedChoice() {
    this.router.push(`${APPOINTMENT_PATH}/${AppointmentStep.ImmunocompromisedChoice}`);
  }

  private goToAgeChoice() {
    this.router.push(`${APPOINTMENT_PATH}/${AppointmentStep.AgeChoice}`);
  }

  private goToFamilyChoice() {
    this.router.push(`${APPOINTMENT_PATH}/${AppointmentStep.VaccineChoiceByAge}`);
  }
}
