// tslint:disable: max-line-length
import styled, { css, keyframes } from 'styled-components';
import { isNullOrUndefined } from 'util';

export interface FadeProps {
  show: boolean;
  duration?: number;
}

/**
 * This fade animates only when `opacity` changes
 */
export const Fade = styled.div`
  border: 0 solid transparent;
  pointer-events: ${(props: FadeProps) => (props.show ? 'inherit' : 'none')};
  opacity: ${(props: FadeProps) => (props.show ? 1 : 0)};
  transition: opacity ${(props: FadeProps) => (isNullOrUndefined(props.duration) ? '0.3s' : props.duration)};
`;

////////////////////////////////////////////////////////////////////////////////////////////////////
const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

interface ForcedFadeProps {
  show: boolean;
  duration?: string;
}

const animationRule = css`
  animation: ${(props: ForcedFadeProps) => (props.show ? fadeIn : fadeOut)}
    ${(props: ForcedFadeProps) => props.duration || '.3s'} linear forwards;
`;

/**
 * This fade will happen everytime, which is different from `Fade` (component) which animates only when `opacity` changes
 */
export const ForcedFade = styled.div`
  border: 0;
  opacity: 0;
  pointer-events: ${(props: ForcedFadeProps) => (props.show ? 'inherit' : 'none')};
  ${animationRule};
`;
