import { Col, Row, Separator } from '@components/obj.grid';
import * as React from 'react';

export class ButtonsContainer extends React.Component<any, any> {
  render() {
    const buttons = React.Children.map(this.props.children, (child: any, index: number) => {
      return (
        <Col xs={12} sm={6} md={4} mdOffset={index === 0 ? 2 : 0}>
          <Separator />
          {child}
        </Col>
      );
    });

    return (
      <div>
        <Row>{buttons}</Row>
      </div>
    );
  }
}
