import * as React from 'react';

import { LinkButtonContentStyled, LinkButtonStyled } from './link-button.component.style';

export interface LinkButtonProps {
  expanded?: boolean;
  href?: string;
  target?: string;
  onClick?: (e?: any) => any;
  external?: boolean;
  noPadding?: boolean;
}

export class LinkButton extends React.Component<LinkButtonProps, any> {
  constructor(props: LinkButtonProps) {
    super(props);
  }

  render() {
    let onClickHandler;
    if (!this.props.external) {
      onClickHandler = this.props.onClick;
    } else {
      onClickHandler = this.handleExternalLinkClick;
    }
    return (
      <LinkButtonStyled
        onClick={onClickHandler ? onClickHandler : null}
        href={this.props.href ? this.props.href : null}
        noPadding={this.props.noPadding}
        {...this.props}
      >
        <LinkButtonContentStyled>{this.props.children}</LinkButtonContentStyled>
      </LinkButtonStyled>
    );
  }

  private handleExternalLinkClick = event => {
    event.preventDefault();
    const result = confirm('Você está saindo de um ambiente seguro. Deseja continuar?');

    if (result) {
      const href = this.props.href;
      window.open(href, '_blank');
    }
  };
}
