import { PropsBase } from '@app/core/base/props.base';
import { Observer } from '@app/core/decorator/observer.decorator';
import { Router } from '@app/core/route/router';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { MobileService } from '@app/core/service/mobile-service';
import { APPOINTMENT_PATH } from '@app/modules/appointment/appointment.route';
import { AppointmentStore } from '@app/modules/appointment/appointment.store';
import { Button } from '@components/atm.button';
import { Body, H1 } from '@components/atm.typography';
import { CheckoutFooter } from '@components/mol.checkout-footer';
import { MediaObject } from '@components/mol.media-object/media-object.component';
import QuickRecommendationFixedMenu, {
  QuickRecommendation,
} from '@components/mol.quick-recommendation-sticky-menu/quick-recommendation-fixed-menu.component';
import { QuickRecommendationFixedMenuContainer } from '@components/mol.quick-recommendation-sticky-menu/quick-recommendation-fixed-menu.container';
import { Col, Grid, Row, Separator } from '@components/obj.grid';
import * as React from 'react';
import { Container } from 'typedi';
import { allProfilesMap, AppointmentStep } from './appointment.store.model';
import { OverdueDosesChatbotContainer } from './components/overdue-doses-chatbot.container.component';
import { ImmunocompromisedComorbidities, mapImmunocompromisedComorbiditiesToString } from './immunocompromised-choice.utils';

@Observer()
export class ImmunocompromisedChoiceView extends React.Component<PropsBase> {
  private appointmentStore?: AppointmentStore = Container.get(AppointmentStore);
  private analyticsService?: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);
  private mobileService?: MobileService = Container.get(MobileService);
  private router?: Router = Container.get(Router);

  private comorbidities = [
    ImmunocompromisedComorbidities.Diabetes,
    ImmunocompromisedComorbidities.CardiovascularPulmonaryDisease,
    ImmunocompromisedComorbidities.ChronicHepatopathy,
    ImmunocompromisedComorbidities.ChronicKidneyDisease,
    ImmunocompromisedComorbidities.SplenicDysfunctions,
    ImmunocompromisedComorbidities.RheumatoAndAutoimmuneDiseases,
    ImmunocompromisedComorbidities.ChildAndAdolescentHiv,
    ImmunocompromisedComorbidities.AdultAndElderlyLivingWithHiv,
    ImmunocompromisedComorbidities.PrimaryImmunodeficiency,
    ImmunocompromisedComorbidities.NeoplasmsOrImmunosuppressants,
    ImmunocompromisedComorbidities.TransplantationOfSolidOrgans,
    // ImmunocompromisedComorbidities.Hsct,
  ];

  componentDidMount() {
    window.scrollTo(0, 0);
    this.analyticsService.pageView('consulta_escolha_comorbidade');
    console.assert(
      this.comorbidities.length === Object.keys(ImmunocompromisedComorbidities).length,
      'Check if "comorbidities" is complete. It seems that some comorbidity is missing.',
    );
  }

  render() {

    return (
      <div>
        <Grid>
          <Row>
            <Col xs={12}>
              <H1>1. Pacientes especiais</H1>
              <Body> Selecione a comorbidade do paciente. </Body>

              <Row>
                {this.comorbidities
                  .map(comorbidity => (
                    <Col xs={12} sm={6} md={4} key={comorbidity}>
                      <MediaObject
                        name={mapImmunocompromisedComorbiditiesToString(comorbidity)}
                        enabled={true}
                        onClick={this.handleButtonOnClickHOF(comorbidity)}
                        minHeight={'94px'}
                      />
                    </Col>
                  ))}
              </Row>

            </Col>
          </Row>

          {this.mobileService.onMobile || (
            <QuickRecommendationFixedMenuContainer
              quickRecommendationContext='profile'
            >
              {childProps => (
                <QuickRecommendationFixedMenu
                  recommendations={childProps.recommendations}
                  onItemClick={childProps.handleItemClick}
                  age={allProfilesMap.get(this.appointmentStore.selectedProfileId).age}
                  bottom={74}
                />
              )}
            </QuickRecommendationFixedMenuContainer>
          )}
        </Grid>

        <OverdueDosesChatbotContainer
          initialClosed={true}
          additionalBottomMargin={64}
        />

        <CheckoutFooter>
          <Grid>
            <Row center={'xs'}>
              <Col xs={12} sm={4}>
                <Separator />
                <Button kind='primary' outlined={true} expanded={true} onClick={this.goBack}>
                  Voltar
                </Button>
              </Col>
            </Row>
          </Grid>
        </CheckoutFooter>
      </div>
    );
  }

  private handleButtonOnClickHOF = (comorbidity: ImmunocompromisedComorbidities) => () => {
    this.appointmentStore.reset = true;
    this.appointmentStore.selectedImmunocompromisedComorbidityChanged(comorbidity);
    this.analyticsService.event(
      'consulta_escolha_comorbidade',
      'click',
      false,
      mapImmunocompromisedComorbiditiesToString(comorbidity),
    );
    this.goToPriorityChoice();
  };

  private goToPriorityChoice() {
    this.router.push(`${APPOINTMENT_PATH}/${AppointmentStep.PriorityChoice}`);
  }

  private goBack = () => {
    this.analyticsService.event('consulta_escolha_comorbidade_back', 'click');
    this.props.history.goBack();
  };
}
