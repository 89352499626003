// tslint:disable: max-line-length
import { ErrorParsingService } from '@app/core/service/error-parsing.service';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { sendDataLayer } from '@app/core/service/send-datalayer.service';
import { Doctor } from '@app/model/doctor.model';
import {
  AllUserDataRequest,
  ChangePassword,
  ChangePasswordInput,
  ChangePasswordVariables,
  DoctorInput,
  ForgotPassword,
  ForgotPasswordInput,
  ForgotPasswordVariables,
  Login,
  LoginInput,
  LoginVariables,
  OptOutUserInput,
  OptOutUserMutation,
  OptOutUserMutationVariables,
  UpdateDoctorInput,
  UpdateEmailAsAdmin,
  UpdateEmailAsAdminInput,
  UpdateEmailAsAdminVariables,
  UpdatePassword,
  UpdatePasswordAsAdmin,
  UpdatePasswordAsAdminInput,
  UpdatePasswordAsAdminVariables,
  UpdatePasswordInput,
  UpdatePasswordVariables,
  UserCreate,
  UserCreateVariables,
  UserUpdate,
  UserUpdateVariables,
} from '@app/resource/graphql/graphql-schema';
import { action, computed, observable } from 'mobx';
import { persist } from 'mobx-persist';
import { MutationFunction } from 'react-apollo';
import { Container, Service } from 'typedi';
// tslint:enable: max-line-length

export const LOGIN_MAP_KEY = 'GSK_VACINAS_LOGIN_MAP_KEY';
export const SIGN_UP_MAP_KEY = 'GSK_VACINAS_SIGN_UP_MAP_KEY';
export const CHANGE_PASSWORD_MAP_KEY = 'GSK_VACINAS_CHANGE_PASSWORD_MAP_KEY';
export const FORGOT_PASSWORD_MAP_KEY = 'GSK_VACINAS_FORGOT_PASSWORD_MAP_KEY';
export const UDPATE_PASSWORD_MAP_KEY = 'GSK_VACINAS_UPDATE_PASSWORD_MAP_KEY';
export const UDPATE_PASSWORD_AS_ADMIN_MAP_KEY = 'GSK_VACINAS_UPDATE_PASSWORD_AS_ADMIN_MAP_KEY';
export const OPT_OUT_USER_MAP_KEY = 'GSK_VACINAS_OPT_OUT_USER_MAP_KEY';
export const UDPATE_USER_MAP_KEY = 'GSK_VACINAS_UPDATE_USER_MAP_KEY';
export const REQUEST_DATA_USER_MAP_KEY = 'GSK_VACINAS_REQUEST_DATA_USER_MAP_KEY';
export const DELETE_USER_MAP_KEY = 'GSK_VACINAS_DELETE_USER_MAP_KEY';

export enum UserType {
  Doctor = 'MEDICO',
  Rep = 'REPRESENTANTE',
  Test = 'TESTE',
  Anonimous = 'ANONIMO',
}

// Hotjar
declare var hj: any;

@Service()
export class AuthenticationStore {

  @computed
  get loggedIn(): boolean {
    return this.token != null;
  }

  get userType(): UserType {
    let userType;

    if (!this.user) {
      return UserType.Anonimous;
    }

    if (this.loggedIn) {
      if (this.user.crm === '999999') {
        userType = UserType.Rep;
      } else {
        switch (this.user.email) {
          case 'doctor@vacinacerta.com.br':
          case 'bruno.x.americo@gsk.com':
          case 'hugo.x.graziani@gsk.com':
          case 'candrepc@gmail.com':
            userType = UserType.Test;
            break;
          default:
            userType = UserType.Doctor;
        }
      }
    } else {
      userType = UserType.Anonimous;
    }

    return userType;
  }

  get email(): string {
    return this.user ? this.user.email : null;
  }

  get phone(): string {
    return this.user ? this.user.phone : null;
  }

  get name(): string {
    return this.user ? this.user.name : null;
  }

  get uf(): string {
    return this.user ? this.user.uf : null;
  }

  get crm(): string {
    return this.user ? this.user.crm : null;
  }

  get userId(): string {
    return this.loggedIn ? this.user && this.user.id : null;
  }

  @persist
  @observable
  repTerritory: string;

  @persist
  @observable
  repTerritoryAdult: string;

  @persist
  @observable
  newsletterOptIn: boolean;

  @persist
  @observable
  smsOptIn: boolean;

  @persist
  @observable
  token: string;

  @observable loadingMap = observable.map<string, boolean>();
  @observable errorMap = observable.map<string, boolean>();
  @observable errorMessageMap = observable.map<string, string>();
  @observable successMap = observable.map<string, boolean>();
  @observable successMessageMap = observable.map<string, string>();

  @observable
  private user: {
    id: string;
    name: string;
    email: string;
    password?: string;
    crm: string;
    uf: string;
    phone?: string;
  };

  private errorParsingService: ErrorParsingService = Container.get(ErrorParsingService);
  private analyticsService: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);

  constructor(private adminEmail: string) {
    this.adminEmail = adminEmail;
  }

  @action
  logOut(): void {
    this.updateDoctor(null);
    this.token = null;
  }

  @action
  async logIn(input: LoginInput, mutationFunc: MutationFunction<Login>): Promise<void> {
    const variables: LoginVariables = {
      data: input,
    };
    this.resetMapItem(LOGIN_MAP_KEY);
    this.loadingMap.set(LOGIN_MAP_KEY, true);
    try {
      const response = await mutationFunc({ variables });
      if (typeof hj !== 'undefined') {
        hj('formSubmitSuccessful');
      }
      this.updateDoctor(response.data.Login.user);
      this.token = response.data.Login.token;
      this.setMapItemToSuccessState(LOGIN_MAP_KEY);
    } catch (error) {
      if (typeof hj !== 'undefined') {
        hj('formSubmitFailed');
      }
      this.setMapItemToErrorState(LOGIN_MAP_KEY, error);
      this.analyticsService.event('erro', 'login_form', true, this.errorParsingService.getErrorMessage(error));
    }
  }

  @action
  async signUp(input: DoctorInput, mutationFunc: MutationFunction<UserCreate>): Promise<void> {
    const variables: UserCreateVariables = {
      data: input,
    };
    this.resetMapItem(SIGN_UP_MAP_KEY);
    this.loadingMap.set(SIGN_UP_MAP_KEY, true);
    try {
      const response = await mutationFunc({ variables });
      if (typeof hj !== 'undefined') {
        hj('formSubmitSuccessful');
      }
      const crm = input.crm;
      sendDataLayer({ event: 'signup_submit', crm });
      this.updateDoctor(response.data.UserCreate.user);
      this.token = response.data.UserCreate.token;
      this.setMapItemToSuccessState(SIGN_UP_MAP_KEY);
    } catch (error) {
      if (typeof hj !== 'undefined') {
        hj('formSubmitFailed');
      }
      this.setMapItemToErrorState(SIGN_UP_MAP_KEY, error);
      this.analyticsService.event('erro', 'cadastrar_form', true, this.errorParsingService.getErrorMessage(error));
    }
  }

  @action
  async changePassword(input: ChangePasswordInput, mutationFunc: MutationFunction<ChangePassword>): Promise<void> {
    const variables: ChangePasswordVariables = {
      data: input,
    };
    this.resetMapItem(CHANGE_PASSWORD_MAP_KEY);
    this.loadingMap.set(CHANGE_PASSWORD_MAP_KEY, true);
    try {
      await mutationFunc({ variables });
      this.setMapItemToSuccessState(CHANGE_PASSWORD_MAP_KEY, 'Senha alterada com sucesso');
    } catch (error) {
      this.setMapItemToErrorState(CHANGE_PASSWORD_MAP_KEY, error);
      this.analyticsService.event('erro', 'alterar_senha_form', true, this.errorParsingService.getErrorMessage(error));
    }
  }

  @action
  async forgotPassword(input: ForgotPasswordInput, mutationFunc: MutationFunction<ForgotPassword>): Promise<void> {
    const variables: ForgotPasswordVariables = {
      data: input,
    };
    this.resetMapItem(FORGOT_PASSWORD_MAP_KEY);
    this.loadingMap.set(FORGOT_PASSWORD_MAP_KEY, true);
    try {
      const response = await mutationFunc({ variables });
      this.resetMapItem(LOGIN_MAP_KEY);
      this.setMapItemToSuccessState(
        FORGOT_PASSWORD_MAP_KEY,
        response.data.ForgotPassword
          ? response.data.ForgotPassword.message
          : 'Você receberá um e-mail com as próximas instruções para resetar sua senha, caso esteja registrado em nossa base de dados.',
      );
    } catch (error) {
      this.setMapItemToErrorState(FORGOT_PASSWORD_MAP_KEY, error);
      this.analyticsService.event('erro', 'esqueci_senha_form', true, this.errorParsingService.getErrorMessage(error));
    }
  }

  @action
  async updateUser(input: UpdateDoctorInput, mutationFunc: MutationFunction<UserUpdate>): Promise<void> {
    const variables: UserUpdateVariables = {
      data: input,
    };

    this.resetMapItem(UDPATE_USER_MAP_KEY);
    this.loadingMap.set(UDPATE_USER_MAP_KEY, true);
    try {
      const response = await mutationFunc({ variables });
      this.user.email = response.data.UserUpdate.email;
      this.user.phone = response.data.UserUpdate.phone;
      this.setMapItemToSuccessState(UDPATE_USER_MAP_KEY, 'Dados atualizados com sucesso.');
    } catch (error) {
      this.setMapItemToErrorState(UDPATE_USER_MAP_KEY, error);
      this.analyticsService.event(
        'erro',
        'atualizar_usuario_form',
        true,
        this.errorParsingService.getErrorMessage(error),
      );
    }
  }

  @action
  async getAllUserData(mutationFunc: MutationFunction<AllUserDataRequest>): Promise<AllUserDataRequest> {
    this.resetMapItem(REQUEST_DATA_USER_MAP_KEY);
    this.loadingMap.set(REQUEST_DATA_USER_MAP_KEY, true);
    try {
      const response = await mutationFunc();
      this.setMapItemToSuccessState(REQUEST_DATA_USER_MAP_KEY, 'Sucesso! Em breve você receberá um e-mail com seus dados registrados no Vacina Certa.');
      return response.data;
    } catch (error) {
      this.setMapItemToErrorState(REQUEST_DATA_USER_MAP_KEY, error);
      this.analyticsService.event(
        'erro',
        'atualizar_usuario_form',
        true,
        this.errorParsingService.getErrorMessage(error),
      );
      return null;
    }
  }

  @action
  async deleteUserData(mutationFunc: MutationFunction): Promise<AllUserDataRequest> {
    this.resetMapItem(DELETE_USER_MAP_KEY);
    this.loadingMap.set(DELETE_USER_MAP_KEY, true);
    try {
      const response = await mutationFunc();
      this.setMapItemToSuccessState(DELETE_USER_MAP_KEY, 'Seus dados foram deletados com sucesso.');
      this.logOut();
      return response.data;
    } catch (error) {
      this.setMapItemToErrorState(DELETE_USER_MAP_KEY, error);
      this.analyticsService.event(
        'erro',
        'deletar_usuario',
        true,
        this.errorParsingService.getErrorMessage(error),
      );
      return null;
    }
  }

  @action
  async updateEmailAsAdmin(
    input: UpdateEmailAsAdminInput,
    mutationFunc: MutationFunction<UpdateEmailAsAdmin>,
  ): Promise<void> {
    const variables: UpdateEmailAsAdminVariables = {
      data: input,
    };

    this.resetMapItem(UDPATE_PASSWORD_AS_ADMIN_MAP_KEY);
    this.loadingMap.set(UDPATE_PASSWORD_AS_ADMIN_MAP_KEY, true);
    try {
      const response = await mutationFunc({ variables });
      const updateEmailAsAdmin = response.data.UpdateEmailAsAdmin;
      this.setMapItemToSuccessState(
        UDPATE_PASSWORD_AS_ADMIN_MAP_KEY,
        updateEmailAsAdmin.message,
      );
    } catch (error) {
      this.setMapItemToErrorState(UDPATE_PASSWORD_AS_ADMIN_MAP_KEY, error);
      this.analyticsService.event(
        'erro',
        'reset_email_doutor_form',
        true,
        this.errorParsingService.getErrorMessage(error),
      );
    }
  }

  @action
  async optOutUser(
    input: OptOutUserInput,
    mutationFunc: MutationFunction<OptOutUserMutation>,
  ): Promise<void> {
    const variables: OptOutUserMutationVariables = {
      data: input,
    };

    this.resetMapItem(OPT_OUT_USER_MAP_KEY);
    this.loadingMap.set(OPT_OUT_USER_MAP_KEY, true);
    try {
      const response = await mutationFunc({ variables });
      const mutation = response.data.OptOutUser;
      if (mutation.success) {
        this.setMapItemToSuccessState(OPT_OUT_USER_MAP_KEY,
          mutation.message,
        );
      } else {
        this.setMapItemToErrorState(OPT_OUT_USER_MAP_KEY, new Error(mutation.message));
      }
    } catch (error) {
      this.setMapItemToErrorState(OPT_OUT_USER_MAP_KEY, error);
      this.analyticsService.event(
        'erro',
        'opt_out_user_form',
        true,
        this.errorParsingService.getErrorMessage(error),
      );
    }
  }

  @action
  async updatePasswordAsAdmin(
    input: UpdatePasswordAsAdminInput,
    mutationFunc: MutationFunction<UpdatePasswordAsAdmin>,
  ): Promise<void> {
    const variables: UpdatePasswordAsAdminVariables = {
      data: input,
    };

    this.resetMapItem(UDPATE_PASSWORD_AS_ADMIN_MAP_KEY);
    this.loadingMap.set(UDPATE_PASSWORD_AS_ADMIN_MAP_KEY, true);
    try {
      const response = await mutationFunc({ variables });
      const updatePasswordAsAdmin = response.data.UpdatePasswordAsAdmin;
      this.setMapItemToSuccessState(
        UDPATE_PASSWORD_AS_ADMIN_MAP_KEY,
        `A senha de ${updatePasswordAsAdmin.name} foi resetada com sucesso!\n  - E-mail: ${updatePasswordAsAdmin.email}\n  - Nova senha: ${updatePasswordAsAdmin.password}`,
      );
    } catch (error) {
      this.setMapItemToErrorState(UDPATE_PASSWORD_AS_ADMIN_MAP_KEY, error);
      this.analyticsService.event(
        'erro',
        'reset_senha_doutor_form',
        true,
        this.errorParsingService.getErrorMessage(error),
      );
    }
  }

  @action
  async updatePassword(input: UpdatePasswordInput, mutationFunc: MutationFunction<UpdatePassword>): Promise<void> {
    const variables: UpdatePasswordVariables = {
      data: input,
    };

    this.resetMapItem(UDPATE_PASSWORD_MAP_KEY);
    this.loadingMap.set(UDPATE_PASSWORD_MAP_KEY, true);
    try {
      await mutationFunc({ variables });
      this.setMapItemToSuccessState(UDPATE_PASSWORD_MAP_KEY, 'Senha atualizada com sucesso.');
    } catch (error) {
      this.setMapItemToErrorState(UDPATE_PASSWORD_MAP_KEY, error);
      this.analyticsService.event(
        'erro',
        'redefinir_senha_form',
        true,
        this.errorParsingService.getErrorMessage(error),
      );
    }
  }

  @action
  isUpdatePasswordExpired(expirationDate: number): boolean {
    const isExpired = new Date().getTime() - expirationDate > 0;
    if (isExpired) {
      const error: Error = new Error(`A chave de redefinição de senha já está expirada.
Gere uma nova chave em "Esqueceu sua senha?" ou entre em contato conosco.`);
      this.setMapItemToErrorState(UDPATE_PASSWORD_MAP_KEY, error);
    }

    return isExpired;
  }

  @action
  resetMapItem(key: string) {
    this.errorMap.set(key, false);
    this.errorMessageMap.set(key, null);
    this.successMap.set(key, false);
    this.successMessageMap.set(key, null);
    this.loadingMap.set(key, false);
  }

  @action
  setMapItemToErrorState(key: string, error: Error) {
    this.errorMessageMap.set(key, this.errorParsingService.getErrorMessage(error));
    this.errorMap.set(key, true);
    this.loadingMap.set(key, false);
    this.successMap.set(key, false);
    this.successMessageMap.set(key, null);
  }

  @action
  setMapItemToSuccessState(key: string, message?: string) {
    this.loadingMap.set(key, false);
    this.successMap.set(key, true);
    this.errorMap.set(key, false);
    this.errorMessageMap.set(key, null);
    if (message) {
      this.successMessageMap.set(key, message);
    }
  }

  @action
  resetErrorState() {
    this.errorMap.forEach((_value: boolean, key: string) => {
      this.errorMap.set(key, false);
    });
    this.errorMessageMap.forEach((_value: string, key: string) => {
      this.errorMessageMap.set(key, null);
    });
  }

  updateToken(token: string) {
    this.token = token;
  }

  updateDoctor(doctor: Doctor) {
    this.user = doctor;

    this.repTerritory = doctor && doctor.repTerritory;
    this.repTerritoryAdult = doctor && doctor.repTerritoryAdult;
    this.newsletterOptIn = doctor && doctor.newsletterOptIn;
    this.smsOptIn = doctor && doctor.smsOptIn;
    this.analyticsService.setUserId(doctor && doctor.id);
  }

  isAdminUser(): boolean {
    return this.user && this.user.email === this.adminEmail;
  }
}
