import styled from 'styled-components';
import { Color, Spacing } from '@components/obj.constants';

export const FrameStyled = styled.div`
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  min-width: 12.5rem;
  background-color: ${Color.White};
  border: 1px solid ${Color.GrayLight};
  position: relative;
  padding: ${Spacing.XXLarge};
  margin-top: ${Spacing.XXLarge};
  overflow-y: hidden;

  @media print {
    border: 0 solid transparent;
    box-shadow: 0 0 0 0 transparent;
    padding: 0;
    margin-top: ${Spacing.Small};
  }
`;
