import { PropsBase } from '@app/core/base/props.base';
import { Observer } from '@app/core/decorator/observer.decorator';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { MobileService } from '@app/core/service/mobile-service';
import { VaccineFamiliesByAge } from '@app/model/vaccine-families-by-age.model';
import { Vaccine } from '@app/model/vaccine.model';
import { ApplicationStore } from '@app/modules/app/application.store';
import { AppointmentStore } from '@app/modules/appointment/appointment.store';
import { allProfilesMap, AppointmentStep } from '@app/modules/appointment/appointment.store.model';
import { VaccineChoiceByAgeViewState } from '@app/modules/appointment/vaccine-choice-by-age.view-state';
import { Button, LinkButton } from '@components/atm.button';
import { CheckboxField } from '@components/atm.checkbox';
import { Body, Caption, H1, H2 } from '@components/atm.typography';
import { ButtonFooterStyled } from '@components/mol.button-footer';
import { CheckFamilyGroup, CheckFamilySwitchGroup } from '@components/mol.check-family-group';
import { CheckMoreButton } from '@components/mol.check-more-button';
import { LoadingWrapper } from '@components/mol.loading-wrapper';
import QuickRecommendationFixedMenu from '@components/mol.quick-recommendation-sticky-menu/quick-recommendation-fixed-menu.component';
import { QuickRecommendationFixedMenuContainer } from '@components/mol.quick-recommendation-sticky-menu/quick-recommendation-fixed-menu.container';
import { Tooltip } from '@components/mol.tooltip';
import { Hbox } from '@components/obj.box';
import { ButtonsContainer } from '@components/obj.buttons-container';
import { Col, Grid, Row, Separator } from '@components/obj.grid';
import { ListContainer } from '@components/obj.list-container';
import { Modal } from '@components/obj.modal';
import { RelativeContainer } from '@components/obj.relative-container';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { action } from 'mobx';
import * as React from 'react';
import { Container } from 'typedi';
import { APPOINTMENT_PATH } from './appointment.route';
import { OverdueDosesChatbotContainer } from './components/overdue-doses-chatbot.container.component';
import { FamilyTooltipStyled } from './vaccine-choice-by-age.style';
import { VaccineListContainerData } from './vaccine-list.container.data';

const submitButtonText = 'VER RECOMENDAÇÃO';

@Observer()
export class VaccineChoiceView extends React.Component<PropsBase, any> {
  private appointmentStore?: AppointmentStore = Container.get(AppointmentStore);
  private vaccineChoiceViewState?: VaccineChoiceByAgeViewState = Container.get(VaccineChoiceByAgeViewState);
  private applicationStore?: ApplicationStore = Container.get(ApplicationStore);
  private analyticsService?: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);
  private mobileService?: MobileService = Container.get(MobileService);

  private modalRef: Modal;
  private didScrollEventTracked: boolean = false;

  constructor(props) {
    super(props);
    this.vaccineChoiceViewState.setupReaction();
  }

  componentWillUnmount() {
    this.vaccineChoiceViewState.dispose();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.analyticsService.pageView('consulta_ciclos_anteriores');
    window.addEventListener('scroll', event => this.handleScrollEvent(event));
  }

  handleScrollEvent = event => {
    if (!this.didScrollEventTracked && window.scrollY > 0 && event.type === 'scroll') {
      this.analyticsService.event('consulta_ciclos_scroll', 'scroll', true);
      this.didScrollEventTracked = true;
    }
  };

  render() {
    const calendarCode = allProfilesMap.get(this.appointmentStore.selectedProfileId).calendarCode;
    const vaccineTargetName = this.appointmentStore.selectedAge.getAgeText();
    return (
      <div>
        <Grid>
          <Row>
            <Col xs={12}>
              <RelativeContainer>
                <H1>2. Escolha de vacinas para {vaccineTargetName}</H1>

                {this.props.data.loading && (
                  <Row center='xs'>
                    <Col>
                      <LoadingWrapper type='boy-gif-clean' />
                    </Col>
                  </Row>
                )}

                {!this.props.data.loading && this.appointmentStore.lastVaccineFamiliesBelowSelectedAge.length === 0 && (
                  <H2>Não existem ciclos anteriores à idade selecionada. Clique em "{submitButtonText}".</H2>
                )}

                {!this.props.data.loading && this.appointmentStore.lastVaccineFamiliesBelowSelectedAge.length > 0 && (
                  <>
                    <Body>Selecione as vacinas que deseja incluir na sua recomendação.</Body>
                    <ListContainer>
                      <FamilyTooltipStyled>
                        <Tooltip
                          opened={
                            typeof this.applicationStore.showFamilyChoiceTooltip === 'undefined'
                              ? true
                              : this.applicationStore.showFamilyChoiceTooltip
                          }
                          onClose={this.handleTooltipCloseClick}
                          message={`Selecione as vacinas que deseja recomendar.
                          Além da idade selecionada, também é possível recomendar vacinas
                          para ciclos anteriores.`}
                        />
                      </FamilyTooltipStyled>

                      {this.buidCheckFamilyGroup(this.appointmentStore.firstVaccineFamiliesBelowSelectedAge)}

                      {this.appointmentStore.lastExceptFirstVaccineFamiliesBelowSelectedAge.length > 0 && (
                        <>
                          <CheckFamilySwitchGroup show={this.vaccineChoiceViewState.showMore}>
                            {this.appointmentStore.lastExceptFirstVaccineFamiliesBelowSelectedAge.map(
                              this.buidCheckFamilyGroup,
                            )}
                          </CheckFamilySwitchGroup>

                          <CheckMoreButton>
                            <LinkButton expanded={true} onClick={this.handleShowMoreClick}>
                              <FontAwesomeIcon icon={faCalendarAlt} />
                              {this.vaccineChoiceViewState.showMore ? ' Ver menos' : ' Ver ciclos anteriores'}
                            </LinkButton>
                          </CheckMoreButton>

                        </>
                      )}
                    </ListContainer>
                  </>
                )}
              </RelativeContainer>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <ButtonFooterStyled>
                <ButtonsContainer>
                  <Button kind='primary' outlined={true} expanded={true} onClick={this.goToAgeChoice}>
                    Voltar
                  </Button>

                  <Button
                    kind='callToAction'
                    expanded={true}
                    onClick={this.goToNextPage}
                    disabled={this.props.data.loading}
                  >
                    {submitButtonText}
                  </Button>
                </ButtonsContainer>
              </ButtonFooterStyled>
            </Col>
          </Row>

          {calendarCode &&
            <Row>
              <Col xs={12}>
                <Hbox hAlign='flex-end'>
                  <Hbox.Item noGrow={true}>
                    <Caption>
                      {calendarCode}
                    </Caption>
                  </Hbox.Item>
                </Hbox>
              </Col>
            </Row>
          }

          {this.mobileService.onMobile || (
            <QuickRecommendationFixedMenuContainer
              quickRecommendationContext='age'
            >
              {childProps => (
                <>
                  <QuickRecommendationFixedMenu
                    recommendations={childProps.recommendations}
                    onItemClick={childProps.handleItemClick}
                    age={this.appointmentStore.selectedAge.getAgeText()}
                    hideAgesOnItems={true}
                  />
                  <Separator />
                  <Separator />
                  <Separator />
                </>
              )}
            </QuickRecommendationFixedMenuContainer>
          )}

          <OverdueDosesChatbotContainer
            initialClosed={true}
          />

        </Grid>

        <Modal
          ref={it => (this.modalRef = it)}
          opened={this.vaccineChoiceViewState.vaccineModalOpened}
          onClose={this.closeVaccineModal}
          noGutter={true}
          noAnimation={true}
        >
          <VaccineListContainerData
            age={this.vaccineChoiceViewState.age}
            vaccineFamily={this.vaccineChoiceViewState.selectedVaccineFamily}
            onVaccineClick={this.onVaccineClick}
          />
        </Modal>
      </div>
    );
  }

  private buidCheckFamilyGroup = (familiesByAge: VaccineFamiliesByAge, key: number = 0) => {
    if (!familiesByAge) {
      return null;
    }
    const ageText = familiesByAge.actualAge.getAgeText();
    const value = this.appointmentStore.selectedVaccinesTuple.map(item =>
      this.generateCheckFamilyValue(item.age.getAgeText(), item.familyId),
    );
    return (
      <div key={key}>
        <CheckFamilyGroup
          title={ageText}
          onValueChange={this.handleVaccineFamilyClick}
          value={value}
          vaccineCheckbox={true}
        >
          {familiesByAge.families.map(family => {
            const itemValue = this.generateCheckFamilyValue(familiesByAge.actualAge.getAgeText(), family.id);
            return (
              <CheckboxField key={itemValue} value={itemValue}>
                {family.name}
              </CheckboxField>
            );
          })}
        </CheckFamilyGroup>
      </div>
    );
  };

  private goToAgeChoice = () => {
    this.analyticsService.event('consulta_ciclos_voltar', 'click');
    this.props.history.goBack();
  };

  private goToNextPage = () => {
    if (this.appointmentStore.selectedVaccinesTuple.length === 0) {
      alert('Selecionar pelo menos uma vacina para seguir.');
      return;
    }
    this.applicationStore.showFamilyChoiceTooltip = false;
    this.props.history.push(`${APPOINTMENT_PATH}/${AppointmentStep.Recommendation}`);
  };

  @action
  private closeVaccineModal = () => {
    this.vaccineChoiceViewState.vaccineModalOpened = false;
  };

  private onVaccineClick = async (item: Vaccine, checked: boolean) => {
    const family = this.vaccineChoiceViewState.selectedVaccineFamily;
    const age = this.appointmentStore.findAgeByText(this.vaccineChoiceViewState.age);

    if (checked) {
      this.appointmentStore.selectVaccine(age, item, family.id);

      this.analyticsService.event(
        'consulta_escolha_vacina_adicionar',
        age.value[0] + ' - ' + item.name + ' - ' + item.brand,
        false,
        item.laboratory,
        null,
        false,
      );
      this.analyticsService.event(
        'consulta_ciclos_grupo_adicionar',
        'click',
        true,
        age.value[0] + ' - ' + family.name,
        null,
        false,
      );
      await this.delay(100);
      this.modalRef.close();
    } else {
      this.appointmentStore.unselectVaccine(age, item, family.id);
      this.analyticsService.event(
        'consulta_escolha_vacina_remover',
        age.value[0] + ' - ' + item.name + ' - ' + item.brand,
        false,
        item.laboratory,
        null,
        false,
      );
      this.analyticsService.event(
        'consulta_ciclos_grupo_remover',
        'click',
        true,
        age.value[0] + ' - ' + family.name,
        null,
        false,
      );
    }
    this.forceUpdate();
  };

  private async delay(ms: number): Promise<any> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private handleVaccineFamilyClick = (_groupValue: any[], value: string) => {
    const [age, familyId] = value.split('_');
    const family = this.appointmentStore.allVaccineFamilies.find(item => item.id === familyId);
    if (!family) {
      throw new Error(`Not found family for value: ${value}`);
    }
    this.vaccineChoiceViewState.selectedVaccineFamily = family;
    this.vaccineChoiceViewState.age = age;
    this.vaccineChoiceViewState.vaccineModalOpened = true;

    this.applicationStore.showFamilyChoiceTooltip = false;
  };

  private handleShowMoreClick = () => {
    this.analyticsService.event('consulta_ciclos_ver_anteriores', 'click', true);
    this.vaccineChoiceViewState.showMoreTapped();
  };

  private handleTooltipCloseClick = () => {
    this.applicationStore.showFamilyChoiceTooltip = false;
  };

  private generateCheckFamilyValue = (age: string, familyId: string) => {
    return `${age}_${familyId}`;
  };

}
