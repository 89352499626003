// tslint:disable: max-line-length
import * as React from 'react';

const SvgAge = props => (
  <svg viewBox='0 30 300 240' {...props}>
    <defs>
      <filter x='-8%' y='-8%' width='116%' height='116%' filterUnits='objectBoundingBox' id='age_svg__a'>
        <feOffset dy={2} in='SourceAlpha' result='shadowOffsetOuter1' />
        <feGaussianBlur stdDeviation={1} in='shadowOffsetOuter1' result='shadowBlurOuter1' />
        <feColorMatrix
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0'
          in='shadowBlurOuter1'
          result='shadowMatrixOuter1'
        />
        <feMerge>
          <feMergeNode in='shadowMatrixOuter1' />
          <feMergeNode in='SourceGraphic' />
        </feMerge>
      </filter>
      <filter x='-5.3%' y='-5.3%' width='110.5%' height='110.5%' filterUnits='objectBoundingBox' id='age_svg__b'>
        <feOffset dy={2} in='SourceAlpha' result='shadowOffsetOuter1' />
        <feGaussianBlur stdDeviation={1} in='shadowOffsetOuter1' result='shadowBlurOuter1' />
        <feColorMatrix
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0'
          in='shadowBlurOuter1'
          result='shadowMatrixOuter1'
        />
        <feMerge>
          <feMergeNode in='shadowMatrixOuter1' />
          <feMergeNode in='SourceGraphic' />
        </feMerge>
      </filter>
      <filter x='-17.3%' y='-16.2%' width='136.9%' height='132.5%' filterUnits='objectBoundingBox' id='age_svg__c'>
        <feOffset dy={1} in='SourceAlpha' result='shadowOffsetOuter1' />
        <feGaussianBlur stdDeviation={0.4} in='shadowOffsetOuter1' result='shadowBlurOuter1' />
        <feColorMatrix
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0'
          in='shadowBlurOuter1'
          result='shadowMatrixOuter1'
        />
        <feMerge>
          <feMergeNode in='shadowMatrixOuter1' />
          <feMergeNode in='SourceGraphic' />
        </feMerge>
      </filter>
      <filter x='-8%' y='-8%' width='116%' height='116%' filterUnits='objectBoundingBox' id='age_svg__d'>
        <feOffset dy={2} in='SourceAlpha' result='shadowOffsetOuter1' />
        <feGaussianBlur stdDeviation={1} in='shadowOffsetOuter1' result='shadowBlurOuter1' />
        <feColorMatrix
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0'
          in='shadowBlurOuter1'
          result='shadowMatrixOuter1'
        />
        <feMerge>
          <feMergeNode in='shadowMatrixOuter1' />
          <feMergeNode in='SourceGraphic' />
        </feMerge>
      </filter>
    </defs>
    <g fill='none' fillRule='evenodd'>
      <g filter='url(#age_svg__a)' transform='translate(32 168)'>
        <path d='M50 0c27.614 0 50 22.386 50 50s-22.386 50-50 50S0 77.614 0 50 22.386 0 50 0z' fill='#FFF' />
        <g fill='#4F2748'>
          <text fontFamily='Lato-Regular, Lato' fontSize={13} transform='translate(25.21 27.658)'>
            <tspan x={6.498} y={46.342}>
              {'nascer'}
            </tspan>
          </text>
          <text fontFamily='Lato-Bold, Lato' fontSize={27} fontWeight='bold' transform='translate(25.21 27.658)'>
            <tspan x={8.03} y={27.342}>
              {'Ao'}
            </tspan>
          </text>
        </g>
      </g>
      <g filter='url(#age_svg__b)' transform='translate(74 74)'>
        <path
          d='M76 0c13.991 0 27.1 3.78 38.359 10.376a76.377 76.377 0 0 1 27.265 27.265C148.219 48.901 152 62.01 152 76c0 13.991-3.78 27.1-10.376 38.359a76.377 76.377 0 0 1-27.265 27.265C103.099 148.219 89.99 152 76 152c-13.991 0-27.1-3.78-38.359-10.376a76.377 76.377 0 0 1-27.265-27.265C3.781 103.099 0 89.99 0 76c0-13.991 3.78-27.1 10.376-38.359a76.377 76.377 0 0 1 27.265-27.265C48.901 3.781 62.01 0 76 0z'
          fill='#BA54A1'
        />
        <g fill='#FFF'>
          <text fontFamily='Lato-Regular, Lato' fontSize={20} transform='translate(48 39)'>
            <tspan x={0.63} y={69}>
              {'meses'}
            </tspan>
          </text>
          <text fontFamily='Lato-Bold, Lato' fontSize={42} fontWeight='bold' transform='translate(48 39)'>
            <tspan x={4.14} y={41}>
              {'03'}
            </tspan>
          </text>
        </g>
      </g>
      <g filter='url(#age_svg__c)' transform='translate(173.5 156)'>
        <path
          d='M4.905 18.42c-.42-.54-.945-1.635-1.86-3-.525-.75-1.815-2.175-2.205-2.91a1.89 1.89 0 0 1-.225-1.5 1.98 1.98 0 0 1 2.145-1.5 3.99 3.99 0 0 1 2.025 1.08c.387.365.744.761 1.065 1.185.24.3.3.42.57.765.27.345.45.69.315.18-.105-.75-.285-2.01-.54-3.135-.195-.855-.24-.99-.42-1.635s-.285-1.185-.48-1.92a21.24 21.24 0 0 1-.42-2.19 4.23 4.23 0 0 1 .39-2.775 1.785 1.785 0 0 1 1.95-.33 3.96 3.96 0 0 1 1.41 1.95c.393.96.655 1.97.78 3 .24 1.5.705 3.69.72 4.14 0-.555-.105-1.725 0-2.25a1.605 1.605 0 0 1 1.005-1.2 3 3 0 0 1 1.38-.09c.465.097.877.365 1.155.75a8.13 8.13 0 0 1 .57 2.745c.04-.824.181-1.64.42-2.43a1.86 1.86 0 0 1 1.035-.72 4.17 4.17 0 0 1 1.5 0c.407.136.762.392 1.02.735a8.28 8.28 0 0 1 .57 2.49c0 .21.105-.585.435-1.11a1.504 1.504 0 1 1 2.85.96v3.39a17.61 17.61 0 0 1-.36 2.61 8.865 8.865 0 0 1-1.08 2.145c-.728.81-1.33 1.725-1.785 2.715a6 6 0 0 0-.15 1.5c-.002.466.059.93.18 1.38a8.775 8.775 0 0 1-1.845 0c-.585-.09-1.305-1.26-1.5-1.62a.57.57 0 0 0-1.02 0c-.33.57-1.065 1.605-1.575 1.665-1.005.12-3.075 0-4.71 0 0 0 .27-1.5-.345-2.04s-1.245-1.17-1.71-1.59l-1.26-1.44z'
          fill='#FFF'
          fillRule='nonzero'
        />
        <path
          d='M4.905 18.42c-.42-.54-.945-1.635-1.86-3-.525-.75-1.815-2.175-2.205-2.91a1.89 1.89 0 0 1-.225-1.5 1.98 1.98 0 0 1 2.145-1.5 3.99 3.99 0 0 1 2.025 1.08c.387.365.744.761 1.065 1.185.24.3.3.42.57.765.27.345.45.69.315.18-.105-.75-.285-2.01-.54-3.135-.195-.855-.24-.99-.42-1.635s-.285-1.185-.48-1.92a21.24 21.24 0 0 1-.42-2.19 4.23 4.23 0 0 1 .39-2.775 1.785 1.785 0 0 1 1.95-.33 3.96 3.96 0 0 1 1.41 1.95c.393.96.655 1.97.78 3 .24 1.5.705 3.69.72 4.14 0-.555-.105-1.725 0-2.25a1.605 1.605 0 0 1 1.005-1.2 3 3 0 0 1 1.38-.09c.465.097.877.365 1.155.75a8.13 8.13 0 0 1 .57 2.745c.04-.824.181-1.64.42-2.43a1.86 1.86 0 0 1 1.035-.72 4.17 4.17 0 0 1 1.5 0c.407.136.762.392 1.02.735a8.28 8.28 0 0 1 .57 2.49c0 .21.105-.585.435-1.11a1.504 1.504 0 1 1 2.85.96v3.39a17.61 17.61 0 0 1-.36 2.61 8.865 8.865 0 0 1-1.08 2.145c-.728.81-1.33 1.725-1.785 2.715a6 6 0 0 0-.15 1.5c-.002.466.059.93.18 1.38a8.775 8.775 0 0 1-1.845 0c-.585-.09-1.305-1.26-1.5-1.62a.57.57 0 0 0-1.02 0c-.33.57-1.065 1.605-1.575 1.665-1.005.12-3.075 0-4.71 0 0 0 .27-1.5-.345-2.04s-1.245-1.17-1.71-1.59l-1.26-1.44z'
          stroke='#000'
          strokeWidth={0.75}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.625 19.239V14.06a.562.562 0 0 0-1.125 0v5.178a.562.562 0 0 0 1.125 0zM14.655 19.237l-.03-5.18a.561.561 0 0 0-.566-.557.561.561 0 0 0-.559.563l.03 5.18c.002.31.255.559.566.557a.561.561 0 0 0 .559-.563zM10.5 14.068l.03 5.17a.564.564 0 0 0 .566.562.564.564 0 0 0 .559-.568l-.03-5.17a.564.564 0 0 0-.566-.562.564.564 0 0 0-.559.568z'
          fill='#000'
          fillRule='nonzero'
        />
      </g>
      <g filter='url(#age_svg__d)' transform='translate(168 32)'>
        <path d='M50 0c27.614 0 50 22.386 50 50s-22.386 50-50 50S0 77.614 0 50 22.386 0 50 0z' fill='#FFF' />
        <g fill='#4F2748'>
          <text fontFamily='Lato-Regular, Lato' fontSize={13} transform='translate(25.21 27.658)'>
            <tspan x={12.261} y={46.342}>
              {'anos'}
            </tspan>
          </text>
          <text fontFamily='Lato-Bold, Lato' fontSize={27} fontWeight='bold' transform='translate(25.21 27.658)'>
            <tspan x={9.629} y={27.342}>
              {'50'}
            </tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgAge;
