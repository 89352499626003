import { CheckboxFieldGroup } from '@components/atm.checkbox';
import { Col, Row } from '@components/obj.grid';
import * as React from 'react';
import { CheckFamilyGroupStyled, CheckFamilyH3Styled } from './check-family-group.component.style';

export interface CheckFamilyGroupProps {
  title?: string;
  onValueChange?: (groupValue: any[], value: any, selected: boolean) => void;
  value?: string[];
  vaccineCheckbox?: boolean;
}

export class CheckFamilyGroup extends React.Component<CheckFamilyGroupProps, any> {
  constructor(props: CheckFamilyGroupProps) {
    super(props);
  }

  render() {
    return (
      <CheckFamilyGroupStyled>
        <Row>
          <Col xs={12} sm={2}>
            <CheckFamilyH3Styled>{this.props.title}</CheckFamilyH3Styled>
          </Col>
          <Col xs={12} sm={10}>
            <CheckboxFieldGroup
              value={this.props.value}
              onValueChange={this.props.onValueChange}
              vaccineCheckbox={this.props.vaccineCheckbox}
            >
              {this.props.children}
            </CheckboxFieldGroup>
          </Col>
        </Row>
      </CheckFamilyGroupStyled>
    );
  }
}
