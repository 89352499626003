import { HistoryToken } from '@app/core/route/router';
import { App } from '@app/modules/app/app';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Router } from 'react-router';
import { Container } from 'typedi';

export function bootstrap() {
  const history = Container.get(HistoryToken);
  const renderMethod = !!module.hot ? ReactDOM.render : ReactDOM.hydrate;

  renderMethod(
    <Router history={history}>
      <App />
    </Router>,
    document.getElementById('root'),
  );
}
