import { PropsBase } from '@app/core/base/props.base';
import { GraphQL } from '@app/core/decorator/graphql.decorator';
import { Observer } from '@app/core/decorator/observer.decorator';
import { AuthenticationStore } from '@app/modules/authentication/authentication.store';
import { ChangePasswordView } from '@app/modules/authentication/change-password.view';
import { ChangePassword, ChangePasswordInput } from '@app/resource/graphql/graphql-schema';
import * as React from 'react';
import { Container } from 'typedi';

export type ChangePasswordDataProps = PropsBase<ChangePassword>;

@GraphQL('change-password.mutation')
@Observer()
export class ChangePasswordData extends React.Component<ChangePasswordDataProps, any> {
  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);

  render() {
    return <ChangePasswordView onSubmit={this.callMutation} />;
  }

  private callMutation = (input: ChangePasswordInput) => {
    this.authenticationStore.changePassword(input, this.props.mutate);
  };
}
