import { PropsBase } from '@app/core/base/props.base';
import { GraphQL } from '@app/core/decorator/graphql.decorator';
import { Observer } from '@app/core/decorator/observer.decorator';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { AllUserDataRequest } from '@app/resource/graphql/graphql-schema';
import { Button } from '@components/atm.button';
import * as React from 'react';
import { Container } from 'typedi';
import { AuthenticationStore, REQUEST_DATA_USER_MAP_KEY } from './authentication.store';

@GraphQL('all-user-data.mutation')
@Observer()
export class RequestAllUserDataMutation extends React.Component<Partial<PropsBase<AllUserDataRequest>>> {

  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);
  private analyticsService: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Button
        kind='secondary'
        expanded={true}
        loading={this.authenticationStore.loadingMap.get(REQUEST_DATA_USER_MAP_KEY)}
        onClick={this.callMutation}
      >
        Solicitar dados
      </Button>
    );
  }

  private callMutation = async () => {
    this.analyticsService.event('pedir_todos_dados', 'click', true);
    await this.authenticationStore.getAllUserData((this.props).mutate);
  };
}
