import { PropsBase } from '@app/core/base/props.base';
import { Observer } from '@app/core/decorator/observer.decorator';
import { Router } from '@app/core/route/router';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { MobileService } from '@app/core/service/mobile-service';
import { APPOINTMENT_PATH } from '@app/modules/appointment/appointment.route';
import { AppointmentStore } from '@app/modules/appointment/appointment.store';
import { Button } from '@components/atm.button';
import { Body, H1 } from '@components/atm.typography';
import { CheckoutFooter } from '@components/mol.checkout-footer';
import { MediaObject } from '@components/mol.media-object/media-object.component';
import QuickRecommendationFixedMenu, {
  QuickRecommendation,
} from '@components/mol.quick-recommendation-sticky-menu/quick-recommendation-fixed-menu.component';
import { QuickRecommendationFixedMenuContainer } from '@components/mol.quick-recommendation-sticky-menu/quick-recommendation-fixed-menu.container';
import { Col, Grid, Row, Separator } from '@components/obj.grid';
import * as React from 'react';
import { Container } from 'typedi';
import { allProfilesMap, AppointmentStep } from './appointment.store.model';
import { OverdueDosesChatbotContainer } from './components/overdue-doses-chatbot.container.component';
import { mapImmunocompromisedComorbiditiesToString } from './immunocompromised-choice.utils';
import { mapVaccinePriorityToString, VaccinePriority } from './priority-choice.utils';

@Observer()
export class PriorityChoiceView extends React.Component<PropsBase> {
  private appointmentStore?: AppointmentStore = Container.get(AppointmentStore);
  private analyticsService?: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);
  private mobileService?: MobileService = Container.get(MobileService);
  private router?: Router = Container.get(Router);

  componentDidMount() {
    window.scrollTo(0, 0);
    this.analyticsService.pageView('consulta_escolha_comorbidade');
  }

  render() {

    const comorbidityName = mapImmunocompromisedComorbiditiesToString(
      this.appointmentStore.selectedImmunocompromisedComorbidity,
    );

    return (
      <div>
        <Grid>
          <Row mb={true}>
            <Col xs={12}>
              <H1>1. {comorbidityName}</H1>
              <Body> Selecione quais vacinas você deseja recomendar para "{comorbidityName}": </Body>
            </Col>
          </Row>

          <Row>
            {[
              VaccinePriority.Special,
              VaccinePriority.Other,
            ].map(p => (

              <Col xs={12} key={p}>
                <MediaObject
                  name={mapVaccinePriorityToString(p)}
                  enabled={true}
                  onClick={this.handleButtonOnClickHOF(p)}
                />
              </Col>
            ))}
          </Row>

          {this.mobileService.onMobile || (
            <QuickRecommendationFixedMenuContainer
              quickRecommendationContext='comorbidity'
            >
              {childProps => (
                <QuickRecommendationFixedMenu
                  recommendations={childProps.recommendations}
                  onItemClick={childProps.handleItemClick}
                  age={allProfilesMap.get(this.appointmentStore.selectedProfileId).age}
                  bottom={74}
                />
              )}
            </QuickRecommendationFixedMenuContainer>
          )}
        </Grid>

        <OverdueDosesChatbotContainer
          initialClosed={true}
          additionalBottomMargin={64}
        />

        <CheckoutFooter>
          <Grid>
            <Row center={'xs'}>
              <Col xs={12} sm={4}>
                <Separator />
                <Button kind='primary' outlined={true} expanded={true} onClick={this.goBack}>
                  Voltar
                </Button>
              </Col>
            </Row>
          </Grid>
        </CheckoutFooter>
      </div>
    );
  }

  private handleButtonOnClickHOF = (priority: VaccinePriority) => () => {
    this.appointmentStore.selectedVaccinePriorityChanged(priority);
    this.analyticsService.event(
      'consulta_escolha_prioridade',
      'click',
      false,
      mapVaccinePriorityToString(priority),
    );
    this.goToVaccineChoiceBySpecialNeed();
  };

  private goToVaccineChoiceBySpecialNeed() {
    this.router.push(`${APPOINTMENT_PATH}/${AppointmentStep.VaccineChoiceBySpecialNeed}`);
  }

  private goBack = () => {
    this.analyticsService.event('consulta_escolha_comorbidade_back', 'click');
    this.props.history.goBack();
  };
}
