import * as React from 'react';
import { Route } from 'react-router';
import { AdministrationPlacesView } from '@app/modules/administration-places/administration-places.view';
import { SEO, SEOConfig } from '@app/core/hoc/seo.hoc';

export const ADMINISTRATION_PLACES_ROUTE = '/centros';

const config: SEOConfig = {
  title: 'Vacina Certa - Centros',
  meta: [
    {
      name: 'description',
      content: 'Encontre o centro de vacinação mais próximo a você!',
    },
  ],
};
const AdministrationPlaces = SEO(config)(AdministrationPlacesView);

export const AdministrationPlacesRoute = (
  <Route path={ADMINISTRATION_PLACES_ROUTE} exact={true} component={AdministrationPlaces} />
);
