import 'reflect-metadata';

import { bootstrap } from '@app/bootstrap-client';
import { AppConfig, configure } from '@app/configure-app';

interface AppWindow extends Window {
  __APP_CONFIG__: AppConfig;
}

configure(((window as unknown) as AppWindow).__APP_CONFIG__ || ({} as AppConfig));

bootstrap();

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/service-worker.js')
      .then(registration => {
        console.log('SW registered: ', registration);
      })
      .catch(registrationError => {
        console.log('SW registration failed: ', registrationError);
      });
  });
}
