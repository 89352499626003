
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { AuthenticationStore } from '@app/modules/authentication/authentication.store';
import { QuickRecommendationFixedMenuHeaderHeight } from '@components/mol.quick-recommendation-sticky-menu/quick-recommendation-fixed-menu.styled';
import * as React from 'react';
import { Container } from 'typedi';
import { OverdueDosesChatbot } from './overdue-doses-chatbot.component';

export interface OverdueDosesChatbotContainerProps {
  initialClosed?: boolean;
  // additional bottom margin on all devices
  additionalBottomMargin?: number;
  recommendationsCount?: number;
}

export class OverdueDosesChatbotContainer extends React.Component<OverdueDosesChatbotContainerProps, unknown> {
  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);
  private analyticsService?: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);

  render() {
    return (
      <OverdueDosesChatbot
        initialClosed={this.props.initialClosed}
        additionalBottomMargin={this.props.additionalBottomMargin}
        mobileAdditionalBottomMargin={(this.props.recommendationsCount ? QuickRecommendationFixedMenuHeaderHeight : 0)}
        name={this.authenticationStore.name}
        id={this.authenticationStore.userId}
        onCloseClick={this.handleCloseClick}
        onPopClick={this.handlePopClick}
        onShowMoreClick={this.handleShowMoreClick}
        onCookieError={this.handleCookieError}
      />
    );
  }

  private handleCloseClick = () => this.analyticsService.event('doses_em_atraso_fechar_mensagem', 'click');
  private handlePopClick = () => this.analyticsService.event('doses_em_atraso_pop', 'click');
  private handleShowMoreClick = () => this.analyticsService.event('doses_em_atraso_ver_mais', 'click');
  private handleCookieError = () => this.analyticsService.event('doses_em_atraso_chatbot_cookie_error', 'error', true);

}
