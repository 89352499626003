/* tslint:disable:all */
import * as React from 'react';

const SvgPrematuro = props => (
  <svg width={69} height={69} {...props}>
    <defs>
      <path id='prematuro_svg__a' d='M0 .21h40.259v33.857H0z' />
      <path id='prematuro_svg__c' d='M.15.379h8.754v8.7H.15z' />
      <path id='prematuro_svg__e' d='M.434.379h8.754v8.7H.434z' />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <circle cx={34.5} cy={34.5} r={34.5} fill='#FEF5E2' />
      <g transform='translate(12.075 14.157)'>
        <mask id='prematuro_svg__b' fill='#fff'>
          <use xlinkHref='#prematuro_svg__a' />
        </mask>
        <path
          d='M23.225.21c-.63 0-1.142.508-1.142 1.135v14.658H8.362a5.78 5.78 0 0 0-5.666-4.61H1.142c-.63 0-1.142.51-1.142 1.136 0 .626.512 1.135 1.142 1.135h1.554c1.928 0 3.496 1.558 3.496 3.474 0 9.334 7.64 16.929 17.033 16.929 9.39 0 17.029-7.59 17.033-16.921v-.008C40.259 7.804 32.619.21 23.226.21'
          fill='#4F2748'
          mask='url(#prematuro_svg__b)'
        />
      </g>
      <path
        d='M35.3 45.955c-7.748 0-14.12-5.97-14.705-13.525h29.411c-.585 7.555-6.958 13.525-14.706 13.525'
        fill='#DD6386'
      />
      <path d='M36.442 30.16V16.68c7.224.553 13.009 6.301 13.565 13.48H36.442z' fill='#FCC74B' />
      <g transform='translate(24.065 47.636)'>
        <mask id='prematuro_svg__d' fill='#fff'>
          <use xlinkHref='#prematuro_svg__c' />
        </mask>
        <path
          d='M4.528.379C2.114.379.15 2.329.15 4.729c0 2.399 1.964 4.35 4.378 4.35 2.413 0 4.376-1.951 4.376-4.35 0-2.4-1.963-4.35-4.376-4.35'
          fill='#4F2748'
          mask='url(#prematuro_svg__d)'
        />
      </g>
      <path
        d='M28.592 54.446a2.09 2.09 0 0 1-2.093-2.081c0-1.147.939-2.08 2.093-2.08a2.09 2.09 0 0 1 2.094 2.08 2.09 2.09 0 0 1-2.094 2.08'
        fill='#FFF'
      />
      <g transform='translate(37.197 47.636)'>
        <mask id='prematuro_svg__f' fill='#fff'>
          <use xlinkHref='#prematuro_svg__e' />
        </mask>
        <path
          d='M4.811.379C2.397.379.434 2.329.434 4.729c0 2.399 1.963 4.35 4.377 4.35 2.414 0 4.377-1.951 4.377-4.35 0-2.4-1.963-4.35-4.377-4.35'
          fill='#4F2748'
          mask='url(#prematuro_svg__f)'
        />
      </g>
      <path
        d='M42.008 54.446a2.09 2.09 0 0 1-2.094-2.081c0-1.147.94-2.08 2.094-2.08a2.09 2.09 0 0 1 2.093 2.08 2.09 2.09 0 0 1-2.093 2.08'
        fill='#FFF'
      />
    </g>
  </svg>
);

export default SvgPrematuro;
