import { Spacing } from '@components/obj.constants';
import styled from 'styled-components';

export const HowItWorksCellStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const HowItWorksImageStyled = styled.img`
  position: relative;
  width: 60%;
  margin-bottom: ${Spacing.Large};

  @media only screen and (min-width: 48em) {
    width: 100%;
    margin-bottom: 0;
  } ;
`;
