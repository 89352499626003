import styled from 'styled-components';
import { Color, Spacing } from '@components/obj.constants';
import { FontFamily, FontSize, FontWeight } from '@components/obj.constants';
import { LinkButtonProps } from './link-button.component';

export const LinkButtonStyled = styled.a`
  user-select: none;
  display: ${(props: LinkButtonProps) => (props.expanded ? 'block' : 'inline-block')};
  min-height: 50px;
  text-align: center;
  padding: ${(props: LinkButtonProps) => (props.noPadding ? '0' : '10px')};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Bold};
  font-size: ${FontSize.Medium};
  cursor: pointer;
  color: ${Color.Accessory};
  text-decoration: underline;

  &:before {
    display: inline-block;
    vertical-align: middle;
    content: '';
    height: 100%;
  }

  &:hover {
    color: ${Color.Secondary};
  }

  p > &,
  label > & {
    padding: 0;
    min-height: ${FontSize.Medium};
  }

  @media print and (max-width: 148mm) {
    font-size: ${FontSize.Small};
  }
`;

export const LinkButtonContentStyled = styled.span`
  word-break: break-word;
  & .fa {
    padding-right: ${Spacing.Small};
  }
`;
