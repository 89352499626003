import styled from 'styled-components';
import { Row } from '@components/obj.grid';
import { Spacing } from '@components/obj.constants';

export const HowItWorksOverflowSection = styled.section`
  overflow: hidden;
`;

export const HowItWorksRowStyled = styled(Row)`
  margin-bottom: ${Spacing.XXXLarge};
`;

export const HowItWorksImageStyled = styled.img`
  position: relative;
  width: 60%;
  margin-bottom: ${Spacing.Large};

  @media only screen and (min-width: 48em) {
    width: 100%;
    margin-bottom: 0;
  } ;
`;
