import { Col, Row } from '@components/obj.grid';
import * as React from 'react';
import { CheckMoreButtonStyled } from './check-more-button.component.style';

export const CheckMoreButton = (props: any) => {
  return (
    <CheckMoreButtonStyled>
      <Row center='xs'>
        <Col xs={12}>{props.children}</Col>
      </Row>
    </CheckMoreButtonStyled>
  );
};
