import { NotPrintableStyled } from '@app/components/printable.component.style';
import { Button, LinkButton } from '@components/atm.button';
import { CheckboxField, CheckboxFieldGroup } from '@components/atm.checkbox';
import { Body, Caption, H2 } from '@components/atm.typography';
import { BooleanContainer } from '@components/obj.abstract-container';
import { Col, Grid, Row, Separator } from '@components/obj.grid';
import { Modal } from '@components/obj.modal';
import { faChevronDown, faChevronUp, faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import {
  CookieConsentBkgStyled, CookieConsentButtonStyled,
  CookieConsentOptionDescriptionWrapperStyled,
} from './cookie-consent.component.styled';
import { ShowMoreBox } from './mol.see-more-box';

export enum CookieConsentKind {
  Performance = 1,
  Functional = 2,
  Advertising = 3,
}

export interface CookieConsentProps {
  initialValue: CookieConsentKind[];
  initialOpen: boolean;
  onAccept?: (kind: CookieConsentKind[]) => void;
  onReject?: () => void;
}

const CookieConsent: React.FunctionComponent<CookieConsentProps> = props => {
  return (
    <NotPrintableStyled>
      <BooleanContainer defaultValue={props.initialOpen}>
        {modalBool => {
          const hideModal = modalBool.setFalse;
          const openModal = modalBool.setTrue;
          return (
            <>
              <CookieConsentBkgStyled />
              <CookieConsentButtonStyled onClick={openModal}>
                <FontAwesomeIcon fixedWidth={true} icon={faCog} />
              </CookieConsentButtonStyled>
              <CookieConsentModal
                {...props}
                opened={modalBool.value}
                onHideRequest={hideModal}
              />
            </>
          );
        }}
      </BooleanContainer>
    </NotPrintableStyled>
  );
};

export default CookieConsent;

////////////////////////////////////////////////////////////////////////////////////////////////////

interface ICookieConsentModalProps extends CookieConsentProps {
  opened: boolean;
  onHideRequest: () => void;
}

const CookieConsentModal: React.FunctionComponent<ICookieConsentModalProps> = props => {
  const [value, setValue] = React.useState<CookieConsentKind[]>(props.initialValue);
  const handleValueChange = nextValue => {
    setValue(nextValue.slice());
  };

  const handleReject = () => {
    props.onHideRequest();
    props.onReject();
  };
  const handleAccept = () => {
    props.onHideRequest();
    const allCookies = Object.values(CookieConsentKind) as CookieConsentKind[];
    const acceptedCookies: CookieConsentKind[] = value.length > 0 ? value : allCookies;
    props.onAccept(acceptedCookies);
  };
  const handleModalClose = () => {
    props.onHideRequest();
  };

  return (
    <Modal opened={props.opened} onClose={handleModalClose}>
      <Grid fluid={true}>
        <Row>
          <Col xs={12}>
            <H2>Utilizamos cookies </H2>
            <Body>
              Utilizamos cookies para melhorar a sua experiência no
              website. Alguns cookies são necessários para o funcionamento do
              site. Outros são opcionais: os cookies de desempenho revelam-nos
              como usa o nosso site e os seus recursos; os cookies funcionais
              retêm as suas preferências e os cookies publicitários que nos
              permitem partilhar conteúdo relevante.  <LinkButton href='https://br.gsk.com/pt-br/pol%C3%ADtica-de-cookies/' target='_blank'>Política de Cookies</LinkButton>
            </Body>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <BooleanContainer defaultValue={props.initialValue && props.initialValue.length > 0}>
              {showMoreBool => {
                return (
                  <>
                    <ShowMoreBox expanded={showMoreBool.value}>
                      <CheckboxFieldGroup value={value} onValueChange={handleValueChange} >
                        <Separator />
                        <CheckboxField isDefaultStyle={true} value={CookieConsentKind.Performance} >
                          Cookies de desempenho
                        </CheckboxField>
                        <CookieConsentOptionDescriptionWrapperStyled>
                          <Caption>Recolhe informações sobre o seu uso
                            do nosso site, por exemplo, visitas à página
                            ou mensagens de erro. Esta informação é
                            agregada e anonimizada sendo utilizada para
                            melhorar o nosso website. Se não permitir
                            estes cookies, não saberemos quando visitou o
                            nosso site e não será possível monitorizar o
                            seu desempenho </Caption>
                        </CookieConsentOptionDescriptionWrapperStyled>
                        <CheckboxField isDefaultStyle={true} value={CookieConsentKind.Functional} >
                          Cookies funcionais
                        </CheckboxField>
                        <CookieConsentOptionDescriptionWrapperStyled>
                          <Caption>Permitem que os nossos sites recordem
                            o seu nome de usuário, preferência de idioma
                            ou região geográfica. Esta informação é usada
                            para que possamos proporcionar uma experiência
                            personalizada e para tornar o site mais fácil
                            de usar. Caso não permita estes cookies,
                            alguns ou todos os recursos podem não
                            funcionar </Caption>
                        </CookieConsentOptionDescriptionWrapperStyled>
                        <CheckboxField isDefaultStyle={true} value={CookieConsentKind.Advertising} >
                          Cookies publicitários
                        </CheckboxField>
                        <CookieConsentOptionDescriptionWrapperStyled>
                          <Caption>Permitem a exibição de publicidade
                            que consideramos relevante para si.
                            Construimos perfis com base no seu histórico
                            de navegação, que partilhamos com os nossos
                            parceiros de publicidade permitindo a exibição
                            de anúncios relevantes noutros sites. Se não
                            permitir estes cookies vai continuar a receber
                            publicidade, mas esta será menos relevante
                            para si.  </Caption>
                        </CookieConsentOptionDescriptionWrapperStyled>
                      </CheckboxFieldGroup>
                    </ShowMoreBox>
                    <Row>
                      <Col xs={12}>
                        <LinkButton onClick={showMoreBool.toggle} noPadding={true}>
                          <FontAwesomeIcon
                            fixedWidth={true}
                            icon={showMoreBool.value ? faChevronUp : faChevronDown}
                          />
                          {showMoreBool.value ? 'Mostrar menos' : 'Mostrar detalhe'}
                        </LinkButton>
                      </Col>
                    </Row>
                    <Row center={'xs'}>
                      <Col xs={12} sm={6} lg={2}>
                        <Separator />
                        <Button kind='primary' outlined={true} expanded={true} onClick={handleReject} >
                          Rejeitar
                        </Button>
                      </Col>
                      <Col xs={12} sm={6} lg={2}>
                        <Separator />
                        <Button kind='primary' expanded={true} onClick={handleAccept}>
                          Aceitar {value.length ? '' : 'todos'}
                        </Button>
                      </Col>
                    </Row>
                  </>
                );
              }}
            </BooleanContainer>
          </Col>
        </Row>
      </Grid>
    </Modal>
  );
};
