import { InputValue } from '@components/atm.typography';
import { Color, FontSize, Spacing } from '@components/obj.constants';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle, faCheckSquare, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const checkboxHeight: number = 32;
const marginBetweenCheckboxItems = Spacing.Large;

export const CheckboxFieldStyled = styled.span`
  display: block;
  position: relative;
  flex-direction: row;

  & ~ * {
    margin-top: ${marginBetweenCheckboxItems};
  }
`;

export const CheckboxFieldTextStyled = styled(InputValue)`
  padding-left: 48px;
  line-height: ${checkboxHeight}px;
  z-index: 2;
  word-break: break-word;
  cursor: pointer;
  font-size: ${FontSize.Medium};
`;

export const DefaultCheckboxCheckedStyled = styled(FontAwesomeIcon).attrs({ icon: faCheckSquare })`
  position: absolute;
  font-size: ${checkboxHeight}px;
  color: ${Color.Accessory};
  z-index: 1;
  opacity: 0;
`;

export const DefaultCheckboxUncheckedStyled = styled(FontAwesomeIcon).attrs({ icon: faSquare })`
  position: absolute;
  font-size: ${checkboxHeight}px;
  color: ${Color.Secondary};
  z-index: 0;
  opacity: 1;
`;

export const CheckboxCheckedStyled = styled(FontAwesomeIcon).attrs({ icon: faCheckCircle })`
  position: absolute;
  font-size: ${checkboxHeight}px;
  color: ${Color.Success};
  z-index: 1;
  opacity: 0;
`;

export const CheckboxUncheckedStyled = styled(FontAwesomeIcon).attrs({ icon: faPlusCircle })`
  position: absolute;
  font-size: ${checkboxHeight}px;
  z-index: 0;
  opacity: 1;
  color: ${Color.GrayXDark};
`;

export const CheckboxFieldBulletStyled = styled.input`
  display: none;

  &:checked + ${CheckboxCheckedStyled}, &:checked + ${DefaultCheckboxCheckedStyled} {
    opacity: 1;
    + ${CheckboxUncheckedStyled}, + ${DefaultCheckboxUncheckedStyled} {
      opacity: 0;
    }
  }

  &:checked + ${DefaultCheckboxCheckedStyled} {
    opacity: 1;
    + ${DefaultCheckboxUncheckedStyled} {
      opacity: 0;
    }
  }
`;
