import { HeaderSmart } from '@app/components//header.smart-component';
import { PropsBase } from '@app/core/base/props.base';
import { GraphQL } from '@app/core/decorator/graphql.decorator';
import { Observer } from '@app/core/decorator/observer.decorator';
import { ErrorParsingService } from '@app/core/service/error-parsing.service';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { ApplicationStore } from '@app/modules/app/application.store';
import { AuthenticationStore } from '@app/modules/authentication/authentication.store';
import { Button, LinkButton } from '@components/atm.button';
import { Notification } from '@components/atm.notification';
import { TextAreaField, TextField } from '@components/atm.text-field';
import { Body, Display, InputLabel } from '@components/atm.typography';
import { MenuItems } from '@components/mol.header/header.model';
import { FieldValidator, Form, Validators } from '@components/obj.form';
import { Col, Grid, Row } from '@components/obj.grid';
import { If } from '@components/obj.if';
import { observable } from 'mobx';
import * as React from 'react';
import { Container, Service } from 'typedi';
import { CookieConsentContainer } from '@app/components/cookie-consent';

export type ContactViewProps = PropsBase<any>;

@GraphQL('contact.mutation')
@Observer()
@Service()
export class ContactView extends React.Component<ContactViewProps> {
  @observable private errorMessage: string;
  @observable private error: boolean;
  @observable private loading: boolean;
  @observable private success: boolean = false;

  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);
  private errorParsingService?: ErrorParsingService = Container.get(ErrorParsingService);
  private applicationStore?: ApplicationStore = Container.get(ApplicationStore);
  private analyticsService?: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);

  constructor(props) {
    super(props);
    this.applicationStore.currentMenuItem = MenuItems.perfil;
  }

  componentDidMount() {
    this.analyticsService.pageView('contato');
  }

  render() {
    return (
      <div>
        <CookieConsentContainer />
        {this.renderHeader()}
        {this.renderForm()}
      </div>
    );
  }

  private onSubmit = formData => {
    console.log(formData);
    if (formData.errors.length === 0) {
      this.sendForm({
        name: formData.data.name,
        email: formData.data.email,
        message: formData.data.message,
      });
    }
  };

  private async sendForm(input: any): Promise<void> {
    const variables: any = {
      data: input,
    };
    this.loading = true;
    this.error = false;

    try {
      await this.props.mutate({ variables });
      this.loading = false;
      this.error = false;
      this.success = true;
    } catch (error) {
      this.loading = false;
      this.success = false;
      this.error = true;
      this.errorMessage = this.errorParsingService.getErrorMessage(error);
    }
  }

  private renderHeader = () => {
    return (
      <HeaderSmart
        selectedItem={this.applicationStore.currentMenuItem}
        home={false}
        logged={this.authenticationStore.loggedIn}
      />
    );
  };

  private renderForm = () => {
    return (
      <Form onSubmit={this.onSubmit}>
        <Grid>
          <Row middle='xs'>
            <Col xs={12}>
              <Row center='xs'>
                <Col xs={12} sm={8} md={6}>
                  <Row align='center' mb={true}>
                    <Col xs={12}>
                      <Display>Contato</Display>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Body>
                        Preencha o formulário abaixo para entrar em contato com a GSK. Para dúvidas relacionadas ao uso,
                        eficácia e segurança dos produtos GSK, entre em contato através do &nbsp;
                        <LinkButton href='mailto:medinfo@gsk.com'>medinfo@gsk.com</LinkButton>
                        &nbsp;ou pelo telefone: <LinkButton href='tel:08007012233'>0800 701 2233</LinkButton>.
                      </Body>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Row start='xs' mb={true}>
                        <Col xs={12}>
                          <If cond={this.error}>
                            <Notification type='error' message={this.errorMessage} />
                          </If>
                        </Col>
                      </Row>
                      <Row start='xs' mb={true}>
                        <Col xs={12}>
                          <If cond={this.success}>
                            <Notification type='success' message='Mensagem enviada com sucesso!' />
                          </If>
                        </Col>
                      </Row>
                      <Row start='xs' mb={true}>
                        <Col xs={12}>
                          <InputLabel>Nome</InputLabel>
                          <FieldValidator name='name' validators={[Validators.Required('Campo obrigatório')]}>
                            <TextField
                              type='text'
                              defaultValue={this.authenticationStore.name}
                              placeholder='Seu nome'
                            />
                          </FieldValidator>
                        </Col>
                      </Row>
                      <Row start='xs' mb={true}>
                        <Col xs={12}>
                          <InputLabel>E-mail</InputLabel>
                          <FieldValidator
                            name='email'
                            validators={[
                              Validators.Required('Campo obrigatório'),
                              Validators.EmailRegex('O e-mail é inválido'),
                            ]}
                          >
                            <TextField
                              type='text'
                              defaultValue={this.authenticationStore.email}
                              placeholder='Seu e-mail'
                            />
                          </FieldValidator>
                        </Col>
                      </Row>
                      <Row start='xs' mb={true}>
                        <Col xs={12}>
                          <InputLabel>Sua mensagem</InputLabel>
                          <FieldValidator name='message' validators={[Validators.Required('Campo obrigatório')]}>
                            <TextAreaField placeholder='Mensagem' />
                          </FieldValidator>
                        </Col>
                      </Row>
                      <Row center='xs' mb={true}>
                        <Col xs={6}>
                          <Button type='submit' kind='primary' expanded={true} loading={this.loading}>
                            Enviar
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Grid>
      </Form>
    );
  };
}
