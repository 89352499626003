import { VaccineBySpecialNeed_VaccineBySpecialNeed_family } from '@app/resource/graphql/graphql-schema';
import { observable } from 'mobx';
import { Service } from 'typedi';

@Service()
export class VaccineChoiceBySpecialNeeViewState {
  @observable
  vaccineModalOpened: boolean = false;

  @observable
  selectedVaccineFamily: VaccineBySpecialNeed_VaccineBySpecialNeed_family = null;

  dispose() {
    this.vaccineModalOpened = false;
  }
}
