import { Button } from '@components/atm.button';
import { Color, Spacing } from '@components/obj.constants';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { keyframes } from 'styled-components';

const startColor: string = '#BA54A1';
const middleColor: string = '#DD6386';
const endColor: string = '#FCC74B';
export const HeroStyled = styled.div`
  background: linear-gradient(to bottom right, ${startColor}, ${middleColor}, ${endColor});
`;

export const HeroImageStyled = styled.div`
  background-image: url('/assets/img/img_people_hero.png');
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const HeroButtonStyled = styled(Button)`
  @media only screen and (max-width: 48em) {
    display: block;
  } ;
`;

const upAndDown = keyframes`
  0%, 100% { transform: translateY(0) scale(1.1); }
  50% { transform: translateY(-10px); }
`;

export const SeeMoreIconStyled = styled(FontAwesomeIcon).attrs({ icon: faChevronDown })`
  align-self: center;
  font-size: 20px;
  margin: ${Spacing.XLarge};
  color: ${Color.White};
  animation: ${upAndDown} 1s infinite ease-out;
`;
