
import { NotPrintableStyled } from '@app/components/printable.component.style';
import { ScriptLoader } from '@app/components/script-loader.component';
import { CosmobotsService } from '@app/core/service/cosmobots.service';
import { MobileService } from '@app/core/service/mobile-service';
import { LinkButton } from '@components/atm.button';
import { Body } from '@components/atm.typography';
import { ForcedFade } from '@components/obj.animation/animation.component.style';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Container } from 'typedi';
import {
  OverdueDosesChatbotCloseStyled,
  OverdueDosesChatbotMessageWrapperStyled,
  OverdueDosesChatbotPopStyled,
  OverdueDosesChatbotWrapperStyled,
} from './overdue-doses-chatbot.component.styled';

interface IOverdueDosesChatbotProps {
  initialClosed?: boolean;
  name: string;
  id: string;

  // additional bottom margin on all devices
  additionalBottomMargin: number;
  // additional bottom margin on mobile devices (this will be added to the previous margin)
  mobileAdditionalBottomMargin: number;

  onCloseClick: () => void;
  onPopClick: () => void;
  onShowMoreClick: () => void;
  onCookieError: () => void;
}

export const OverdueDosesChatbot: React.FunctionComponent<IOverdueDosesChatbotProps> = props => {
  const [placeholderOpen, setPlaceholderOpen] = React.useState(!props.initialClosed);

  const handleCloseClick = () => {
    setPlaceholderOpen(false);
    props.onCloseClick();
  };
  const cosmobotsService: CosmobotsService = Container.get(CosmobotsService);
  const handleCosmobotLoad = () => {
    cosmobotsService.drawChatIfNeeded();
  };

  const showChat = () => {
    setPlaceholderOpen(false);
    cosmobotsService.showChat();
  };
  const handleShowMoreClick = () => {
    showChat();
    props.onShowMoreClick();
  };
  const handlePopClick = () => {
    props.onPopClick();
    showChat();
  };

  const errorHasBeenDetected = React.useRef(false);
  React.useEffect(() => {
    const value = Math.random();
    const listener = (event: MessageEvent) => {
      if (
        !errorHasBeenDetected.current &&
        event.origin === 'https://chat.cosmobots.io' &&
        event.data && event.data.error && event.data.error.includes && event.data.error.includes('cookies')
      ) {
        console.error('ERROR: overdue-doses-chatbot.component.tsx ~ line 38 ~ ', value, event);
        props.onCookieError();
        errorHasBeenDetected.current = true;
      }
    };
    window.addEventListener('message', listener, false);
    return () => window.removeEventListener('message', listener, false);
  });

  return (
    <ScriptLoader
      src={'https://cosmobots.io/js/cosmo-plugin.js'}
      onLoad={handleCosmobotLoad}
      attributes={
        {
          id: 'cosmobots-loader',
          'cosmo-widget-shape': 'default',
          'cosmo-widget-id': cosmobotsService.id,
          'cosmo-widget-frame': `https://chat.cosmobots.io/popwebwidget?bot_id=${cosmobotsService.id}`,
          'cosmo-widget-chat': `https://chat.cosmobots.io/widget?bot_id=${cosmobotsService.id}&u__first_name=${props.name}&u__custom_id=${props.id}&persistent=0`,
        }
      }
    >
      {childState => {
        return (childState.error || !childState.done) ? null : (
          <NotPrintableStyled>
            <OverdueDosesChatbotWrapperStyled
              mobileAdditionalBottomMargin={props.mobileAdditionalBottomMargin}
              additionalBottomMargin={props.additionalBottomMargin}
            >
              {placeholderOpen && (
                <Placeholder
                  onChatbotCloseClick={handleCloseClick}
                  onShowMoreClick={handleShowMoreClick}
                />
              )}

              <OverdueDosesChatbotPopStyled onClick={handlePopClick} >
                <img
                  src='/assets/img/img_bot_avatar.png'
                  title={'Gessika'}
                  width='60px'
                />
              </OverdueDosesChatbotPopStyled >
            </OverdueDosesChatbotWrapperStyled>
          </NotPrintableStyled>
        );
      }}
    </ScriptLoader>
  );
};

OverdueDosesChatbot.defaultProps = {
  additionalBottomMargin: 0,
  mobileAdditionalBottomMargin: 0,
};

////////////////////////////////////////////////////////////////////////////////////////////////////

interface IPlaceholderProps {
  onShowMoreClick: () => void;
  onChatbotCloseClick: () => void;
}

const Placeholder: React.FunctionComponent<IPlaceholderProps> = props => {
  return (
    <ForcedFade show={true}>
      <OverdueDosesChatbotMessageWrapperStyled>
        <Body>Olá,<br />Gostaria de consultar doses em atraso?</Body>
        <LinkButton expanded={true} onClick={props.onShowMoreClick}>
          Ver mais
        </LinkButton>
        <OverdueDosesChatbotCloseStyled onClick={props.onChatbotCloseClick}>
          <FontAwesomeIcon icon={faTimes} />
        </OverdueDosesChatbotCloseStyled>
      </OverdueDosesChatbotMessageWrapperStyled>
    </ForcedFade>

  );
};
