import { RadioFieldGroup } from '@components/atm.radio';
import * as React from 'react';
import { VacineItem } from '.';

export class VacineItemGroup extends RadioFieldGroup {
  constructor(props) {
    super(props);
  }

  render() {
    const wrapped = React.Children.map(this.props.children, (child: any, i: number) => {
      if (child.type === VacineItem) {
        const key = 'VacineItem_' + i;
        return React.cloneElement(child, {
          key,
          name: this.props.name,
          onValueChange: this.handleValueChange,
          checked: this.state.value === child.props.value,
        });
      } else {
        return child;
      }
    });

    return <div>{wrapped}</div>;
  }
}
