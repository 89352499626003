// tslint:disable: max-line-length
import * as React from 'react';

const SvgVaccine = props => (
  <svg viewBox='0 30 300 240' {...props}>
    <defs>
      <rect id='vaccine_svg__b' x={0} y={0} width={236} height={236} rx={24} />
      <filter x='-1.7%' y='-.8%' width='103.4%' height='103.4%' filterUnits='objectBoundingBox' id='vaccine_svg__a'>
        <feOffset dy={2} in='SourceAlpha' result='shadowOffsetOuter1' />
        <feGaussianBlur stdDeviation={1} in='shadowOffsetOuter1' result='shadowBlurOuter1' />
        <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0' in='shadowBlurOuter1' />
      </filter>
      <path
        d='M13.107 13.502l-1.488-1.503 6.495-6.56 1.49 1.503-6.497 6.56zM8.98 17.671a1.045 1.045 0 0 1-1.488 0 1.071 1.071 0 0 1 0-1.504l2.503-2.528 1.49 1.503-2.505 2.529zm14.686-9.903c-1.143-1.16-.588-.597-2.303-2.329l.812-.82a1.168 1.168 0 0 0 0-1.639 1.14 1.14 0 0 0-1.623-.001l-.812.82-2.437-2.46a1.141 1.141 0 0 0-1.624 0 1.168 1.168 0 0 0 0 1.64l.812.82L4.95 15.455c-.778.786-.902 2.029.143 3.104C.933 22.418 1 21.957 1 23.075c0 1.066 2.136 1.074 2.48.703l3.235-3.277h.016c.837 1.173 2.11.752 3.125-.364L21.225 8.62l.807.803a1.149 1.149 0 0 0 1.632-.007 1.178 1.178 0 0 0 0-1.648z'
        id='vaccine_svg__c'
      />
      <path
        d='M13.107 13.502l-1.488-1.503 6.495-6.56 1.49 1.503-6.497 6.56zM8.98 17.671a1.045 1.045 0 0 1-1.488 0 1.071 1.071 0 0 1 0-1.504l2.503-2.528 1.49 1.503-2.505 2.529zm14.686-9.903c-1.143-1.16-.588-.597-2.303-2.329l.812-.82a1.168 1.168 0 0 0 0-1.639 1.14 1.14 0 0 0-1.623-.001l-.812.82-2.437-2.46a1.141 1.141 0 0 0-1.624 0 1.168 1.168 0 0 0 0 1.64l.812.82L4.95 15.455c-.778.786-.902 2.029.143 3.104C.933 22.418 1 21.957 1 23.075c0 1.066 2.136 1.074 2.48.703l3.235-3.277h.016c.837 1.173 2.11.752 3.125-.364L21.225 8.62l.807.803a1.149 1.149 0 0 0 1.632-.007 1.178 1.178 0 0 0 0-1.648z'
        id='vaccine_svg__e'
      />
      <path
        d='M13.107 13.502l-1.488-1.503 6.495-6.56 1.49 1.503-6.497 6.56zM8.98 17.671a1.045 1.045 0 0 1-1.488 0 1.071 1.071 0 0 1 0-1.504l2.503-2.528 1.49 1.503-2.505 2.529zm14.686-9.903c-1.143-1.16-.588-.597-2.303-2.329l.812-.82a1.168 1.168 0 0 0 0-1.639 1.14 1.14 0 0 0-1.623-.001l-.812.82-2.437-2.46a1.141 1.141 0 0 0-1.624 0 1.168 1.168 0 0 0 0 1.64l.812.82L4.95 15.455c-.778.786-.902 2.029.143 3.104C.933 22.418 1 21.957 1 23.075c0 1.066 2.136 1.074 2.48.703l3.235-3.277h.016c.837 1.173 2.11.752 3.125-.364L21.225 8.62l.807.803a1.149 1.149 0 0 0 1.632-.007 1.178 1.178 0 0 0 0-1.648z'
        id='vaccine_svg__g'
      />
      <path
        d='M13.107 13.502l-1.488-1.503 6.495-6.56 1.49 1.503-6.497 6.56zM8.98 17.671a1.045 1.045 0 0 1-1.488 0 1.071 1.071 0 0 1 0-1.504l2.503-2.528 1.49 1.503-2.505 2.529zm14.686-9.903c-1.143-1.16-.588-.597-2.303-2.329l.812-.82a1.168 1.168 0 0 0 0-1.639 1.14 1.14 0 0 0-1.623-.001l-.812.82-2.437-2.46a1.141 1.141 0 0 0-1.624 0 1.168 1.168 0 0 0 0 1.64l.812.82L4.95 15.455c-.778.786-.902 2.029.143 3.104C.933 22.418 1 21.957 1 23.075c0 1.066 2.136 1.074 2.48.703l3.235-3.277h.016c.837 1.173 2.11.752 3.125-.364L21.225 8.62l.807.803a1.149 1.149 0 0 0 1.632-.007 1.178 1.178 0 0 0 0-1.648z'
        id='vaccine_svg__i'
      />
      <path
        d='M12 0C5.371 0 0 5.371 0 12s5.371 12 12 12 12-5.371 12-12S18.629 0 12 0zm6.968 13.355c0 .32-.262.58-.58.58h-4.453v4.452c0 .32-.26.58-.58.58h-2.71a.582.582 0 0 1-.58-.58v-4.452H5.613a.582.582 0 0 1-.58-.58v-2.71c0-.32.26-.58.58-.58h4.452V5.613c0-.32.26-.58.58-.58h2.71c.32 0 .58.26.58.58v4.452h4.452c.32 0 .58.26.58.58v2.71z'
        id='vaccine_svg__k'
      />
      <path
        d='M12 0C5.371 0 0 5.371 0 12s5.371 12 12 12 12-5.371 12-12S18.629 0 12 0zm6.968 13.355c0 .32-.262.58-.58.58h-4.453v4.452c0 .32-.26.58-.58.58h-2.71a.582.582 0 0 1-.58-.58v-4.452H5.613a.582.582 0 0 1-.58-.58v-2.71c0-.32.26-.58.58-.58h4.452V5.613c0-.32.26-.58.58-.58h2.71c.32 0 .58.26.58.58v4.452h4.452c.32 0 .58.26.58.58v2.71z'
        id='vaccine_svg__m'
      />
      <path
        d='M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12zm-13.388 6.354l8.903-8.903a.774.774 0 0 0 0-1.095L18.42 7.26a.774.774 0 0 0-1.095 0l-7.26 7.26-3.39-3.39a.774.774 0 0 0-1.095 0l-1.095 1.096a.774.774 0 0 0 0 1.095l5.032 5.032a.774.774 0 0 0 1.095 0z'
        id='vaccine_svg__o'
      />
      <filter x='-17.3%' y='-16.2%' width='136.9%' height='132.5%' filterUnits='objectBoundingBox' id='vaccine_svg__q'>
        <feOffset dy={1} in='SourceAlpha' result='shadowOffsetOuter1' />
        <feGaussianBlur stdDeviation={0.4} in='shadowOffsetOuter1' result='shadowBlurOuter1' />
        <feColorMatrix
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0'
          in='shadowBlurOuter1'
          result='shadowMatrixOuter1'
        />
        <feMerge>
          <feMergeNode in='shadowMatrixOuter1' />
          <feMergeNode in='SourceGraphic' />
        </feMerge>
      </filter>
    </defs>
    <g transform='translate(32 32)' fill='none' fillRule='evenodd'>
      <use fill='#000' filter='url(#vaccine_svg__a)' xlinkHref='#vaccine_svg__b' />
      <use fill='#FFF' xlinkHref='#vaccine_svg__b' />
      <path d='M24.5 78.5h187M24.5 156.5h187' stroke='#686868' strokeLinecap='square' />
      <g transform='translate(180 106)'>
        <mask id='vaccine_svg__d' fill='#fff'>
          <use xlinkHref='#vaccine_svg__c' />
        </mask>
        <use fill='#FFF' xlinkHref='#vaccine_svg__c' />
        <g mask='url(#vaccine_svg__d)' fill='#BA54A1'>
          <path d='M0 0h24v24H0z' />
        </g>
      </g>
      <g transform='translate(180 29)'>
        <mask id='vaccine_svg__f' fill='#fff'>
          <use xlinkHref='#vaccine_svg__e' />
        </mask>
        <use fill='#FFF' xlinkHref='#vaccine_svg__e' />
        <g mask='url(#vaccine_svg__f)' fill='#BA54A1'>
          <path d='M0 0h24v24H0z' />
        </g>
      </g>
      <g transform='translate(175 179)'>
        <mask id='vaccine_svg__h' fill='#fff'>
          <use xlinkHref='#vaccine_svg__g' />
        </mask>
        <use fill='#FFF' xlinkHref='#vaccine_svg__g' />
        <g mask='url(#vaccine_svg__h)' fill='#BA54A1'>
          <path d='M0 0h24v24H0z' />
        </g>
      </g>
      <g transform='translate(185 189)'>
        <mask id='vaccine_svg__j' fill='#fff'>
          <use xlinkHref='#vaccine_svg__i' />
        </mask>
        <use fill='#FFF' xlinkHref='#vaccine_svg__i' />
        <g mask='url(#vaccine_svg__j)' fill='#BA54A1'>
          <path d='M0 0h24v24H0z' />
        </g>
      </g>
      <g transform='translate(25 29)'>
        <mask id='vaccine_svg__l' fill='#fff'>
          <use xlinkHref='#vaccine_svg__k' />
        </mask>
        <g mask='url(#vaccine_svg__l)' fill='#4F2748'>
          <path d='M0 0h24v24H0z' />
        </g>
      </g>
      <g transform='translate(25 184)'>
        <mask id='vaccine_svg__n' fill='#fff'>
          <use xlinkHref='#vaccine_svg__m' />
        </mask>
        <g mask='url(#vaccine_svg__n)' fill='#4F2748'>
          <path d='M0 0h24v24H0z' />
        </g>
      </g>
      <g transform='translate(25 106)'>
        <mask id='vaccine_svg__p' fill='#fff'>
          <use xlinkHref='#vaccine_svg__o' />
        </mask>
        <g mask='url(#vaccine_svg__p)' fill='#00C889'>
          <path d='M0 0h24v24H0z' />
        </g>
      </g>
      <g filter='url(#vaccine_svg__q)' transform='translate(34.5 121)'>
        <path
          d='M4.905 18.42c-.42-.54-.945-1.635-1.86-3-.525-.75-1.815-2.175-2.205-2.91a1.89 1.89 0 0 1-.225-1.5 1.98 1.98 0 0 1 2.145-1.5 3.99 3.99 0 0 1 2.025 1.08c.387.365.744.761 1.065 1.185.24.3.3.42.57.765.27.345.45.69.315.18-.105-.75-.285-2.01-.54-3.135-.195-.855-.24-.99-.42-1.635s-.285-1.185-.48-1.92a21.24 21.24 0 0 1-.42-2.19 4.23 4.23 0 0 1 .39-2.775 1.785 1.785 0 0 1 1.95-.33 3.96 3.96 0 0 1 1.41 1.95c.393.96.655 1.97.78 3 .24 1.5.705 3.69.72 4.14 0-.555-.105-1.725 0-2.25a1.605 1.605 0 0 1 1.005-1.2 3 3 0 0 1 1.38-.09c.465.097.877.365 1.155.75a8.13 8.13 0 0 1 .57 2.745c.04-.824.181-1.64.42-2.43a1.86 1.86 0 0 1 1.035-.72 4.17 4.17 0 0 1 1.5 0c.407.136.762.392 1.02.735a8.28 8.28 0 0 1 .57 2.49c0 .21.105-.585.435-1.11a1.504 1.504 0 1 1 2.85.96v3.39a17.61 17.61 0 0 1-.36 2.61 8.865 8.865 0 0 1-1.08 2.145c-.728.81-1.33 1.725-1.785 2.715a6 6 0 0 0-.15 1.5c-.002.466.059.93.18 1.38a8.775 8.775 0 0 1-1.845 0c-.585-.09-1.305-1.26-1.5-1.62a.57.57 0 0 0-1.02 0c-.33.57-1.065 1.605-1.575 1.665-1.005.12-3.075 0-4.71 0 0 0 .27-1.5-.345-2.04s-1.245-1.17-1.71-1.59l-1.26-1.44z'
          fill='#FFF'
          fillRule='nonzero'
        />
        <path
          d='M4.905 18.42c-.42-.54-.945-1.635-1.86-3-.525-.75-1.815-2.175-2.205-2.91a1.89 1.89 0 0 1-.225-1.5 1.98 1.98 0 0 1 2.145-1.5 3.99 3.99 0 0 1 2.025 1.08c.387.365.744.761 1.065 1.185.24.3.3.42.57.765.27.345.45.69.315.18-.105-.75-.285-2.01-.54-3.135-.195-.855-.24-.99-.42-1.635s-.285-1.185-.48-1.92a21.24 21.24 0 0 1-.42-2.19 4.23 4.23 0 0 1 .39-2.775 1.785 1.785 0 0 1 1.95-.33 3.96 3.96 0 0 1 1.41 1.95c.393.96.655 1.97.78 3 .24 1.5.705 3.69.72 4.14 0-.555-.105-1.725 0-2.25a1.605 1.605 0 0 1 1.005-1.2 3 3 0 0 1 1.38-.09c.465.097.877.365 1.155.75a8.13 8.13 0 0 1 .57 2.745c.04-.824.181-1.64.42-2.43a1.86 1.86 0 0 1 1.035-.72 4.17 4.17 0 0 1 1.5 0c.407.136.762.392 1.02.735a8.28 8.28 0 0 1 .57 2.49c0 .21.105-.585.435-1.11a1.504 1.504 0 1 1 2.85.96v3.39a17.61 17.61 0 0 1-.36 2.61 8.865 8.865 0 0 1-1.08 2.145c-.728.81-1.33 1.725-1.785 2.715a6 6 0 0 0-.15 1.5c-.002.466.059.93.18 1.38a8.775 8.775 0 0 1-1.845 0c-.585-.09-1.305-1.26-1.5-1.62a.57.57 0 0 0-1.02 0c-.33.57-1.065 1.605-1.575 1.665-1.005.12-3.075 0-4.71 0 0 0 .27-1.5-.345-2.04s-1.245-1.17-1.71-1.59l-1.26-1.44z'
          stroke='#000'
          strokeWidth={0.75}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.625 19.239V14.06a.562.562 0 0 0-1.125 0v5.178a.562.562 0 0 0 1.125 0zM14.655 19.237l-.03-5.18a.561.561 0 0 0-.566-.557.561.561 0 0 0-.559.563l.03 5.18c.002.31.255.559.566.557a.561.561 0 0 0 .559-.563zM10.5 14.068l.03 5.17a.564.564 0 0 0 .566.562.564.564 0 0 0 .559-.568l-.03-5.17a.564.564 0 0 0-.566-.562.564.564 0 0 0-.559.568z'
          fill='#000'
          fillRule='nonzero'
        />
      </g>
      <rect fill='#E5E5E5' x={65} y={36} width={99} height={10} rx={5} />
      <rect fill='#E5E5E5' x={65} y={191} width={99} height={10} rx={5} />
      <rect fill='#E5E5E5' x={65} y={113} width={99} height={10} rx={5} />
    </g>
  </svg>
);

export default SvgVaccine;
