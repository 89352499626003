import { GtmService } from '@app/core/service/gtm.service';
import CookieConsentDatasource, { CookieConsentDataSourceObserver } from '@app/resource/cookie-consent.datasource';
import * as React from 'react';
import { Container } from 'typedi';
/* tslint:disable:all */

/**
 * loads GTM script if possible (if user allows the cookie policy)
 * 		- LGPD: if user doesnt accept the cookie policy, then this component also cleans the created cookies/data
 * @returns
 */
export const GtmLoad: React.FunctionComponent = () => {
  const gtmId = Container.get(GtmService).gtmId;
  React.useEffect(() => {
    const loadGtmIfPossible = () => {
      if (!CookieConsentDatasource.acceptedPerformance) {
        CookieConsentDatasource.eraseCookie('fr');
        return;
      }
      // Google Tag Manager https://developers.google.com/tag-manager/quickstart
      ((w, d, s, l, i) => {
        w[l] = w[l] || [];
        w[l].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        });
        const f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        (j as any).async = true;
        (j as any).src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', gtmId);
    };
    const observer: CookieConsentDataSourceObserver = {
      onPerformanceCookieChanged: loadGtmIfPossible,
    };
    CookieConsentDatasource.subscribeObserver(observer);
    loadGtmIfPossible();
    return () => CookieConsentDatasource.unsubscribeObserver(observer);
  }, []);

  if (!CookieConsentDatasource.acceptedPerformance) {
    return null;
  }

  // Google Tag Manager (noscript) https://developers.google.com/tag-manager/quickstart
  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
        height='0'
        width='0'
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>
  );
};
