import { AppointmentStore } from '@app/modules/appointment/appointment.store';
import { observable, reaction } from 'mobx';
import { persist } from 'mobx-persist';
import { Container, Service } from 'typedi';

@Service()
export class RecommendationViewState {
  @observable
  generatePdfLoading: boolean = false;

  @observable
  showUnblockNavigationModal: boolean = false;

  @persist
  @observable
  signatureName: string;

  @persist
  @observable
  patientName: string = '';

  @persist
  @observable
  showBrandName: boolean = true;

  @persist
  @observable
  showRecommendationDate: boolean = true;

  @persist
  @observable
  showPatientAge: boolean = true;

  private readonly appointmentStore: AppointmentStore = Container.get(AppointmentStore);

  constructor() {
    reaction(() => this.appointmentStore.reset, this.resetState);
    reaction(() => this.appointmentStore.selectedAge, this.updateSignatureInfo);
    reaction(() => this.patientName, this.updateSignatureInfo);

    this.configureInitialState();
  }

  private configureInitialState() {
    this.updateSignatureInfo();
  }

  // TODO: dispose reaction
  private resetState = () => {
    this.signatureName = null;
    this.patientName = '';
    this.showBrandName = true;
    this.generatePdfLoading = false;
    this.showUnblockNavigationModal = false;
  };

  private updateSignatureInfo = () => {
    if (this.appointmentStore.selectedAge != null) {
      this.signatureName = `${this.patientName}, ${this.appointmentStore.selectedAge.getAgeText()}`;
    }
  };
}
