import * as React from 'react';
import { Field, FieldPropsInterface, FieldStateInterface } from '@components/obj.form';

import {
  CustomRadioCheckedStyled,
  CustomRadioFieldBulletStyled,
  CustomRadioFieldChevronStyled,
  CustomRadioFieldStyled,
  CustomRadioFieldTextStyled,
  CustomRadioMonthSpanStyled,
  CustomRadioSpanKind,
  CustomRadioSpanStyled,
} from './custom-radio-field.component.style';

export interface CustomRadioFieldSelectProps {
  selected: boolean;
}

export interface CustomRadioFieldProps extends FieldPropsInterface {
  name?: string;
  value: any;
  checked?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

interface CustomRadioFieldState extends FieldStateInterface {
  checked: boolean;
}

export class CustomRadioField extends Field<CustomRadioFieldProps, CustomRadioFieldState> {
  constructor(props: CustomRadioFieldProps) {
    super(props);
    this.state = { checked: !!this.props.checked, errors: [] };
  }

  UNSAFE_componentWillReceiveProps(nextProps: CustomRadioFieldProps) {
    if (nextProps.checked !== this.state.checked) {
      this.setState({ checked: nextProps.checked });
    }
  }

  onPress = () => {
    const state = !this.state.checked;
    this.setState({ checked: state });

    if (this.props.onValueChange) {
      this.props.onValueChange(this.props.value);
    }
  };

  onClick = () => {
    if (this.state.checked) {
      this.props.onClick();
    }
  };

  render() {
    return (
      <CustomRadioFieldStyled>
        <CustomRadioFieldBulletStyled
          type='radio'
          name={this.props.name}
          id={this.props.name + '_' + this.props.value}
          checked={this.state.checked}
          onChange={this.onPress}
          onClick={this.onClick}
        />

        <CustomRadioFieldTextStyled htmlFor={this.props.name + '_' + this.props.value}>
          <CustomRadioCheckedStyled />
          <CustomRadioSpanStyled kind={this.getCustomRadioSpanKind()}>
            {this.props.children.toString().split(' ')[0]}
          </CustomRadioSpanStyled>

          <CustomRadioMonthSpanStyled>{this.props.children.toString().split(' ')[1]}</CustomRadioMonthSpanStyled>
          <CustomRadioFieldChevronStyled />
        </CustomRadioFieldTextStyled>
      </CustomRadioFieldStyled>
    );
  }

  private getCustomRadioSpanKind = (): CustomRadioSpanKind => {
    // posibilities of agePrefix: "Ao" or some age (ex: "3") or some range (ex: "5-6")
    const agePrefix = this.props.children.toString().split(' ')[0];

    if (agePrefix.includes('-')) {
      return CustomRadioSpanKind.Range;
    }

    if (isNaN(parseInt(agePrefix, 0))) {
      return CustomRadioSpanKind.Text;
    }

    return CustomRadioSpanKind.Age;
  };
}
