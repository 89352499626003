import { VaccineFamily } from '@app/model/vaccine-family.model';
import { Vaccine } from '@app/model/vaccine.model';
import { ApplicationStore } from '@app/modules/app/application.store';
import { AppointmentStore } from '@app/modules/appointment/appointment.store';
import { VaccineByFamily } from '@app/resource/graphql/graphql-schema';
import { VaccineList, VaccineWithTooltip } from '@components/mol.vaccine-list/vaccine-list.component';
import * as React from 'react';
import { ChildProps } from 'react-apollo';
import { Container } from 'typedi';

export interface VaccineChoiceContainerProps {
  title: string;
  vaccines: VaccineWithTooltip[];
  vaccineFamily: VaccineFamily;
  onVaccineClick?: (item: Vaccine, checked: boolean) => void;
  selectedIndex: number;
}

interface VaccineListContainerState {
  key?: number;
}

export class VaccineListContainer
  extends React.Component<ChildProps<VaccineChoiceContainerProps, VaccineByFamily>, VaccineListContainerState> {
  private readonly appointmentStore?: AppointmentStore = Container.get(AppointmentStore);
  private readonly applicationStore?: ApplicationStore = Container.get(ApplicationStore);

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    return (
      <VaccineList
        key={this.state.key}
        title={this.props.title}
        vaccines={this.props.vaccines}
        vaccineInstructionsTitle={'Esquemas, recomendações e comentários'}
        showVaccineChoiceChoiceTooltip={this.applicationStore.showVaccineChoiceChoiceTooltip}
        onOnboardingTooltipClick={this.onTooltipClick}
        onVaccineClick={this.onVaccineClick}
        loading={this.props.data && this.props.data.loading}
        selectedIndex={this.props.selectedIndex}
      />
    );
  }

  private onTooltipClick = () => {
    this.applicationStore.showVaccineChoiceChoiceTooltip = false;
    this.setState({ key: Date.now() });
  };

  private onVaccineClick = (item: Vaccine) => {
    this.applicationStore.showVaccineChoiceChoiceTooltip = false;
    const alreadyChecked = !!this.appointmentStore.selectedVaccinesTuple.find(v => v.vaccine.id === item.id);
    if (this.props.onVaccineClick) {
      this.props.onVaccineClick(item, !alreadyChecked);
    }
  };

}
