import * as React from 'react';
import { Loading, LoadingProps } from '@components/atm.loading';
import { LoadingWrapperStyled } from './loading-wrapper-container.style';

export const LoadingWrapper = (props: LoadingProps) => {
  return (
    <LoadingWrapperStyled>
      <Loading type={props.type} />
    </LoadingWrapperStyled>
  );
};
