import { PropsBase } from '@app/core/base/props.base';
import { Observer } from '@app/core/decorator/observer.decorator';
import { AgeChoiceView } from '@app/modules/appointment/age-choice.view';
import { AppointmentStore } from '@app/modules/appointment/appointment.store';
import * as React from 'react';
import { Container } from 'typedi';
import { AppointmentStep } from './appointment.store.model';

@Observer()
export class AgeChoiceData extends React.Component<PropsBase> {
  private appointmentStore?: AppointmentStore = Container.get(AppointmentStore);

  constructor(props) {
    super(props);
    this.appointmentStore.currentAppointmentStep = AppointmentStep.AgeChoice;
  }

  render() {
    return <AgeChoiceView {...this.props} />;
  }
}
