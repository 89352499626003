import { Color, FontSize, Spacing } from '@components/obj.constants';
import { faChevronDown, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const menuMaxHeight = '70vh';
interface QuickRecommendationFixedMenuWrapperStyledProps {
  y: string;
  hide: boolean;
}
export const QuickRecommendationFixedMenuWrapperStyled = styled.div<QuickRecommendationFixedMenuWrapperStyledProps>`
  position: fixed;
  z-index: 2;
  max-height: ${menuMaxHeight};
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: ${props => (props.hide ? `0` : '1')};
  transform: ${props => (props.y ? `translateY(${props.y})` : 'none')};
  transition: all 0.3s;
  @media print {
    display: none;
  }
  @media only screen and (min-width: 48em) {
    width: auto;
    left: auto;
    max-width: 50vw;
    min-width: 240px;
  }
`;

export const QuickRecommendationFixedMenuHeaderHeight = 64;
export const QuickRecommendationFixedMenuWrapperPlaceholderStyled = styled.div`
  height: ${QuickRecommendationFixedMenuHeaderHeight}px;
`;

export const QuickRecommendationFixedMenuTooltipStyled = styled.div`
  position: absolute;
  z-index: 99;
  width: 100%;
  top: -245px;
`;

const headerHeight = '60px';
const radius = '8px';
export const QuickRecommendationFixedMenuHeaderStyled = styled.div`
  padding: ${Spacing.Large} ${Spacing.Large};
  background: ${Color.Primary};
  @media only screen and (min-width: 48em) {
    border-radius: ${radius} ${radius} 0 0;
  }
`;

export const QuickRecommendationFixedMenuContentStyled = styled.div`
  overflow: auto;
  padding: ${Spacing.Medium};
  background: ${Color.White};
  max-height: calc(${menuMaxHeight} - ${headerHeight});
`;

interface QuickRecommendationFixedMenuHeaderChevronStyledProps {
  rotate?: 'true';
}
export const QuickRecommendationFixedMenuHeaderChevronStyled = styled(FontAwesomeIcon).attrs({ icon: faChevronDown })`
  transform: ${(props: QuickRecommendationFixedMenuHeaderChevronStyledProps) =>
    `rotate(${props.rotate ? `180deg` : 0})`};
  font-size: 20px;
  color: ${Color.White};
  transition: all 0.3s;
`;

export const QuickRecommendationFixedMenuItemPlusStyled = styled(FontAwesomeIcon).attrs({ icon: faPlusCircle })`
  font-size: ${FontSize.Large};
  color: ${Color.GrayXDark};
`;
