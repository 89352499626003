import { getStorage, StorageType } from './storage/storage.provider';
import CookieConsentDataSource from './cookie-consent.datasource';

const HAS_SEEN_INSTALL_PROMPT = 'HAS_SEEN_INSTALL_PROMPT';
const HAS_INSTALLED_PWA = 'HAS_INSTALEED_PWA';

class InstallPwaLogDataSource {
  private get localStorage() {
    const functionalAccepted = CookieConsentDataSource.acceptedFunctional;
    return getStorage(functionalAccepted ?
      StorageType.Local :
      StorageType.Session,
    );
  }

  get hasSeenInstallPrompt() {
    return this.localStorage.get(HAS_SEEN_INSTALL_PROMPT) || false;
  }
  set hasSeenInstallPrompt(value: boolean) {
    this.localStorage.put(HAS_SEEN_INSTALL_PROMPT, value);
  }

  get hasInstalledPWA() {
    return this.localStorage.get(HAS_INSTALLED_PWA) || false;
  }
  set hasInstalledPWA(value: boolean) {
    this.localStorage.put(HAS_INSTALLED_PWA, value);
  }
}

export default new InstallPwaLogDataSource();
