import styled from 'styled-components';
import { Color, Spacing } from '@components/obj.constants';
import { Row } from '@components/obj.grid';

export const checkoutFooterHeight = '74px';

export const CheckoutFooterPlaceholderStyled = styled.div`
  min-height: ${checkoutFooterHeight};
`;

export const CheckoutFooterStyled = styled.div`
  padding: ${Spacing.Medium};
  padding-top: 0;
  background-color: ${Color.White};
  text-align: center;
  box-shadow: 0 -1px 2px 0 rgba(0, 0, 0, 0.1);
  border-top: 1px solid ${Color.GrayXLight};
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 2;
  min-height: ${checkoutFooterHeight};
`;

export const CheckoutFooterRowStyled = styled(Row)`
  justify-content: center;
`;
