import { debounce } from '@app/core/patterns/debounce';
import { BaseSubject } from '@app/core/patterns/observer';
import { hasDocument } from '@components/utils';
import { getStorage, StorageType } from './storage/storage.provider';

const PERFORMANCE_COOKIE_CONSENT = 'PERFORMANCE_COOKIE_CONSENT';
const FUNCTIONAL_COOKIE_CONSENT = 'FUNCTIONAL_COOKIE_CONSENT';
const ADVERTISING_COOKIE_CONSENT = 'ADVERTISING_COOKIE_CONSENT';
const COOKIE_CONSENT_TIMESPAN = 'COOKIE_CONSENT_TIMESPAN';

export interface CookieConsentDataSourceObserver {
  onPerformanceCookieChanged?: (value: boolean) => void;
  onFunctionalCookieChanged?: (value: boolean) => void;
  onAdvertisingCookieChanged?: (value: boolean) => void;
}

/**
 * fetch/saves every kind of cookie consent (functional, advertising, performance)
 * @see https://br.gsk.com/pt-br/pol%C3%ADtica-de-cookies/#
 */
class CookieConsentDataSource extends BaseSubject<CookieConsentDataSourceObserver> {

  get acceptedFunctional() {
    return this.localStorage.get(FUNCTIONAL_COOKIE_CONSENT) || false;
  }
  set acceptedFunctional(value: boolean) {
    const lastValue = this.acceptedFunctional;
    this.saveTimespan();
    this.localStorage.put(FUNCTIONAL_COOKIE_CONSENT, value);
    this.observerCollection.forEach(o => {
      if (o.onFunctionalCookieChanged) { o.onFunctionalCookieChanged(value); }
    });
    if (lastValue !== value && !value) {
      this.reloadPage();
    }
  }

  get acceptedAdvertising() {
    return this.localStorage.get(ADVERTISING_COOKIE_CONSENT) || false;
  }
  set acceptedAdvertising(value: boolean) {
    this.saveTimespan();
    this.localStorage.put(ADVERTISING_COOKIE_CONSENT, value);
    this.observerCollection.forEach(o => {
      if (o.onAdvertisingCookieChanged) { o.onAdvertisingCookieChanged(value); }
    });
  }

  get acceptedPerformance() {
    return this.localStorage.get(PERFORMANCE_COOKIE_CONSENT) || false;
  }
  set acceptedPerformance(value: boolean) {
    const lastValue = this.acceptedPerformance;
    this.saveTimespan();
    this.localStorage.put(PERFORMANCE_COOKIE_CONSENT, value);
    this.observerCollection.forEach(o => {
      if (o.onPerformanceCookieChanged) { o.onPerformanceCookieChanged(value); }
    });
    if (lastValue !== value && !value) {
      this.reloadPage();
    }
  }

  get decisionTimespan(): Date {
    return new Date(this.localStorage.get(COOKIE_CONSENT_TIMESPAN));
  }
  private localStorage = getStorage(StorageType.Local);

  private reloadPage = debounce(() => window.location.reload());

  eraseCookie(name) {
    if (!hasDocument()) {
      return;
    }
    document.cookie = name + '=; Max-Age=-99999999;';
  }

  private saveTimespan = () =>
    this.localStorage.put(COOKIE_CONSENT_TIMESPAN, new Date());
}

export default new CookieConsentDataSource();
