import * as React from 'react';

const SvgShareIos = props => (
  <svg viewBox='0 0 38 53' {...props}>
    <g fill='#007AFF' fillRule='evenodd'>
      <path d='M14 17H2v34h34V17H24v-2h14v38H0V15h14v2z' />
      <path d='M19.002 0v.002l8.342 8.354-1.482 1.498L20 3.981 20 34h-2V3.983l-5.86 5.871-1.482-1.498L19 .002 19 0v.001L19.002 0z' />
    </g>
  </svg>
);

export default SvgShareIos;
