import { EventService, MapEvents } from '@app/core/service/document-event.service';
import {
  AdministrationPlaceKind, AdministrationPlacesForPosition,
  AdministrationPlacesForPosition_AdministrationPlacesForPosition,
} from '@app/resource/graphql/graphql-schema';
import { action, computed, observable } from 'mobx';
import { persist } from 'mobx-persist';
import { Container, Service } from 'typedi';

@Service()
export class AdministrationPlacesStore {
  @computed
  get selectedPublicAdministrationPlaces(): AdministrationPlacesForPosition {
    return {
      AdministrationPlacesForPosition: this.selectedAdministrationPlaces.AdministrationPlacesForPosition.filter(
        item => item.type === AdministrationPlaceKind.PUBLIC,
      ),
    };
  }

  @computed
  get selectedPrivateAdministrationPlaces(): AdministrationPlacesForPosition {
    return {
      AdministrationPlacesForPosition: this.selectedAdministrationPlaces.AdministrationPlacesForPosition.filter(
        item => item.type === AdministrationPlaceKind.PRIVATE,
      ),
    };
  }

  @persist('object')
  @observable
  selectedAdministrationPlaces: AdministrationPlacesForPosition = {
    AdministrationPlacesForPosition: [],
  };

  private eventService?: EventService = Container.get(EventService);
  public setAdministrationPlacesListener = () => {
    this.eventService.addListener(MapEvents.PlaceClicked, this.administrationPlaceClicked);
  };

  public unsetAdministrationPlacesListener = () => {
    this.eventService.removeListener(MapEvents.PlaceClicked, this.administrationPlaceClicked);
  };

  public clearPlacesListener = () => {
    this.eventService.addListener(MapEvents.ClearPlaces, this.clearSelectedAdministrationPlaces);
  };

  public removeClearPlacesListener = () => {
    this.eventService.removeListener(MapEvents.ClearPlaces, this.clearSelectedAdministrationPlaces);
  };

  private clearSelectedAdministrationPlaces = () => {
    this.selectedAdministrationPlaces = {
      AdministrationPlacesForPosition: [],
    };
  }

  @action
  private administrationPlaceClicked = (
    event: { detail: AdministrationPlacesForPosition_AdministrationPlacesForPosition },
  ) => {
    const item = event.detail;
    const indexToBeRemoved = this.selectedAdministrationPlaces.AdministrationPlacesForPosition.findIndex(
      clinic => clinic.id === item.id,
    );
    if (indexToBeRemoved < 0) {
      this.selectedAdministrationPlaces.AdministrationPlacesForPosition.push(item);
    } else {
      this.selectedAdministrationPlaces.AdministrationPlacesForPosition.splice(indexToBeRemoved, 1);
    }
  };
}
