import { PropsBase } from '@app/core/base/props.base';
import { GraphQL } from '@app/core/decorator/graphql.decorator';
import { Observer } from '@app/core/decorator/observer.decorator';
import { AppointmentStore } from '@app/modules/appointment/appointment.store';
import { AppointmentStep } from '@app/modules/appointment/appointment.store.model';
import { VaccineChoiceView } from '@app/modules/appointment/vaccine-choice-by-age.view';
import { AllVaccineFamiliesByAge } from '@app/resource/graphql/graphql-schema';
import * as React from 'react';
import { Container } from 'typedi';

export type VaccineChoiceDataProps = PropsBase<AllVaccineFamiliesByAge>;

@GraphQL('all-vaccine-families-by-age.query')
@Observer()
export class VaccineChoiceByAgeData extends React.Component<VaccineChoiceDataProps> {
  private appointmentStore?: AppointmentStore = Container.get(AppointmentStore);

  constructor(props) {
    super(props);
    this.appointmentStore.currentAppointmentStep = AppointmentStep.VaccineChoiceByAge;
  }

  componentDidUpdate() {
    this.appointmentStore.allVaccineFamiliesByAge = this.props.data as AllVaccineFamiliesByAge;
  }

  render() {
    return <VaccineChoiceView {...this.props} />;
  }
}
