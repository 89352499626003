import { getStorage, StorageType } from './storage/storage.provider';
import CookieConsentDataSource from './cookie-consent.datasource';

const CLOSED_SHARE = 'HAS_CLOSED_SHARE';

class ShareLogDataSource {
  private get localStorage() {
    const functionalAccepted = CookieConsentDataSource.acceptedFunctional;
    return getStorage(functionalAccepted ?
      StorageType.Local :
      StorageType.Session,
    );
  }

  get hasClosedShare() {
    return this.localStorage.get(CLOSED_SHARE) || false;
  }
  set hasClosedShare(value: boolean) {
    this.localStorage.put(CLOSED_SHARE, value);
  }
}

export default new ShareLogDataSource();
