import { Field, FieldPropsInterface, FieldStateInterface } from '@components/obj.form';
import * as React from 'react';
import { CheckboxField } from '.';

export interface CheckboxFieldGroupProps extends FieldPropsInterface {
  onValueChange?: (groupValue: any[], value: any, selected: boolean) => void;
  value?: any[];
  vaccineCheckbox?: boolean;
}
export interface CheckboxFieldGroupState extends FieldStateInterface {
  value: any;
}

export class CheckboxFieldGroup extends Field<CheckboxFieldGroupProps, CheckboxFieldGroupState> {
  constructor(props) {
    super(props);
    this.state = { value: props.value || [], errors: [] };
  }

  UNSAFE_componentWillReceiveProps(nextProps: CheckboxFieldGroupProps) {
    if (this.props.vaccineCheckbox) {
      this.setState({ value: nextProps.value || [] });
    }
  }

  handleValueChange = (value: any, checked: boolean) => {
    const checkedValues: any[] = this.state.value;

    const i = checkedValues.indexOf(value);

    if (checked && i < 0) {
      checkedValues.push(value);
    }

    if (!checked && i >= 0) {
      checkedValues.splice(i, 1);
    }

    this.setState({ value: [...checkedValues] });

    if (this.props.onValueChange) {
      this.props.onValueChange(checkedValues, value, checked);
    }
  };

  render() {
    const wrapped = React.Children.map(this.props.children, (child: any, i: number) => {
      if (child.type === CheckboxField) {
        const key = 'CheckboxField_' + i;
        const checked = this.state.value && this.state.value.indexOf(child.props.value) > -1;
        return React.cloneElement(child, {
          key,
          checked,
          name: this.props.name,
          onValueChange: this.handleValueChange,
        });
      } else {
        return child;
      }
    });

    return <div>{wrapped}</div>;
  }
}
