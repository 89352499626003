import * as React from 'react';
import { Route } from 'react-router';
import { SEO, SEOConfig } from '@app/core/hoc/seo.hoc';
import { ResetDoctorEmailData } from './reset-email-password.data';

export const RESET_DOCTOR_EMAIL_PATH = '/resetar-email-doutor';

const config: SEOConfig = {
  title: 'Vacina Certa - Resetar e-mail de médico',
  robots: 'noindex',
  meta: [
    {
      name: 'description',
      content: 'Altere o e-mail de médico no Vacina Certa.',
    },
  ],
};
const ResetDoctorEmail = SEO(config)(ResetDoctorEmailData);

export const ResetDoctorEmailRoute = (
  <Route path={RESET_DOCTOR_EMAIL_PATH} exact={true} component={ResetDoctorEmail} />
);
