import { Children, Component, ReactChild, ReactElement, ReactNode } from 'react';

import { Else, ElseIf, ElseIfProps } from './else.component';

export interface IfProps {
  cond: boolean;
}

// TODO: TEMPLATE
export class If extends Component<IfProps> {
  private visibleChildren: ReactChild[] | any = [];

  render() {
    this.updateVisibleChildren();
    return this.visibleChildren;
  }

  componentWillUnmount() {
    this.visibleChildren = [];
  }

  private updateVisibleChildren() {
    // clearing visible children
    this.visibleChildren = [];

    let isIncluding = this.props.cond;

    Children.forEach(this.props.children, child => {
      if (child) {
        isIncluding = this.shouldInclude(child, isIncluding);
        if (isIncluding) {
          this.visibleChildren.push(child);
        }
      }
    });
  }

  private shouldInclude(child: ReactChild | any, isIncluding: boolean): boolean {
    if (isIncluding) {
      return !isElementOf(child, ElseIf) && !isElementOf(child, Else);
    }

    if (isElementOf(child, ElseIf) && this.visibleChildren.length === 0) {
      return (child as ReactElement<ElseIfProps>).props.cond;
    }

    if (isElementOf(child, Else) && this.visibleChildren.length === 0) {
      return !this.props.cond;
    }

    return isIncluding;
  }
}

function isElementOf(child: ReactChild, elementClass: ReactNode): boolean {
  return (child as ReactElement<any>).type === elementClass;
}
