import styled from 'styled-components';
import { Color, Spacing } from '../obj.constants';

interface CarouselProps {
  key: string | number;
  active: boolean;
}

export const CarouselPagerBulletStyled = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  background-color: ${(props: CarouselProps) => (props.active ? Color.Primary : Color.Gray)};
  &:nth-child(n + 2) {
    margin-left: 4px;
  }
`;

export const CarouselPagerStyled = styled.div`
  height: 18px;
  padding-top: ${Spacing.Medium};
  text-align: center;
  background-color: transparent;
`;
