import { hasWindow } from '@components/utils';

export interface CustomWindow extends Window {
  dataLayer: any;
}
declare let window: CustomWindow;

export type CustomDataLayer = SignUpDataLayer;

interface SignUpDataLayer {
  event: 'signup_submit';
  crm: string;
}

export const sendDataLayer = (data: CustomDataLayer): void => {
  if (hasWindow() && window.dataLayer) {
    console.log('sendDataLayer -> data', data);
    const dataLayer = window.dataLayer;
    dataLayer.push(data);
  } else {
    console.error('Datalayer not available');
  }
};
