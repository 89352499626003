import { Component } from 'react';
import { Helmet } from 'react-helmet';
import * as React from 'react';

export interface SEOConfig {
  title?: string;
  meta?: MetaTag | MetaTag[];
  link?: LinkTag | LinkTag[];
  robots?: string;
}

export interface LinkTag {
  rel: string;
  href: string;
}

export type MetaTag = NameMetaTag | PropertyMetaTag;

export interface NameMetaTag extends BaseMetaTag {
  name: string;
}

export interface PropertyMetaTag extends BaseMetaTag {
  property: string;
}

export interface BaseMetaTag {
  content: string;
}

/**
 * HoC that adds the capability to configure SEO tags  - <title>, <meta> and <link> -
 * while routing to some component. It uses react-helmet (https://github.com/nfl/react-helmet)
 * in order to do so.
 *
 * @param config Configures seo title, meta and link tags.
 *
 */
export const SEO = (config: SEOConfig) => WrappedComponent => {
  return class extends Component<SEOConfig> {
    render() {
      return (
        <div>
          <Helmet>
            {this.renderTitle()}
            {this.renderMetaTags()}
            {this.renderLinkTags()}

            {/* https://developers.google.com/search/reference/robots_meta_tag?hl=pt-br#robotsmeta */}
            {config.robots && <meta name='robots' content={config.robots} />}
          </Helmet>
          <WrappedComponent {...this.props} />
        </div>
      );
    }

    private renderTitle = () => {
      if (!config.title) {
        return <title />;
      }

      return <title> {config.title} </title>;
    };

    private renderMetaTags = () => {
      if (!config.meta) {
        return <meta />;
      }

      if (config.meta instanceof Array) {
        return config.meta.map((item, index) => {
          return <meta {...item} key={index} />;
        });
      } else {
        return <meta {...config.meta} />;
      }
    };

    private renderLinkTags = () => {
      if (!config.link) {
        return <link />;
      }

      if (config.link instanceof Array) {
        return config.link.map((item, index) => {
          return <link {...item} key={index} />;
        });
      } else {
        return <link {...config.link} />;
      }
    };
  };
};
