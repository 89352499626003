import styled from 'styled-components';

const dropTranslation = 25;
export const DropBackgroundStyled = styled.img`
  position: absolute;
  bottom: -150px;
  z-index: 0;
  width: 30vw;
  min-width: 200px;
  max-width: 500px;
`;

export const LeftDropBackground = styled(DropBackgroundStyled)`
  left: -${dropTranslation}vw;
  transform: scaleX(-1);
`;

export const RightDropBackground = styled(DropBackgroundStyled)`
  right: -${dropTranslation}vw;
`;
