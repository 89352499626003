import { Button } from '@components/atm.button';
import { Body } from '@components/atm.typography';
import { Separator } from '@components/obj.grid';
import * as React from 'react';
import { PlaceholderImageStyled, PlaceholderStyled } from './placeholder-component.style';

export type PlaceholderType = 'serverError' | 'noItens';

export interface PlaceholderProps {
  type: PlaceholderType;
}

export const Placeholder = (props: PlaceholderProps) => {
  // TODO: change to icon component
  const imageSrc = '/assets/img/ic_error.png';

  const text =
    props.type === 'serverError'
      ? 'Ocorreu um erro inesperado. Por favor, tente novamente.'
      : 'Nenhum item encontrado.';

  const handleButtonClick = () => location.reload();

  return (
    <PlaceholderStyled>
      <Separator />
      <PlaceholderImageStyled src={imageSrc} />
      <Separator />
      <Body>{text}</Body>
      <Separator />
      <Button kind='primary' onClick={handleButtonClick}>
        Recarregar página
      </Button>
    </PlaceholderStyled>
  );
};
