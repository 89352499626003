import { Field, FieldPropsInterface, FieldStateInterface } from '@components/obj.form';
import * as React from 'react';
import { RadioField } from '.';

export interface RadioFieldGroupProps extends FieldPropsInterface {
  onValueChange?: (value: any) => void;
  value?: any;
}
export interface RadioFieldGroupState extends FieldStateInterface {
  value: any;
}

export class RadioFieldGroup extends Field<RadioFieldGroupProps, RadioFieldGroupState> {
  constructor(props) {
    super(props);
    this.state = { value: this.props.value, errors: [] };
  }

  UNSAFE_componentWillReceiveProps(nextProps: RadioFieldGroupProps) {
    this.setState({ value: nextProps.value });
  }

  handleValueChange = (value: any) => {
    this.setState({ value });

    if (this.props.onValueChange) {
      this.props.onValueChange(value);
    }
  };

  render() {
    const wrapped = React.Children.map(this.props.children, (child: any, i: number) => {
      if (child.type === RadioField) {
        const key = 'RadioField_' + i;
        return React.cloneElement(child, {
          key,
          name: this.props.name,
          onValueChange: this.handleValueChange,
          checked: this.state.value === child.props.value,
        });
      } else {
        return child;
      }
    });

    return <div>{wrapped}</div>;
  }
}
