import {
  isBirthDateValid,
  isCrmValid,
  isEmailValid,
  isPasswordValid,
  isPhoneValid,
  isZipCodeValid,
} from './regex-validator';

export interface Validator {
  errorName: string;
  validationFn: any;
  errorMessage: string;
}

export interface ValidationError {
  errorName: string;
  errorMessage: string;
}

export class Validators {
  static MaxLength(length: number, errorMessage: string): Validator {
    return {
      errorName: 'MaxLength',
      errorMessage,

      validationFn: (value: any) => {
        return value.length <= length;
      },
    };
  }

  static MinLength(length: number, errorMessage: string): Validator {
    return {
      errorName: 'MinLength',
      errorMessage,

      validationFn: (value: any) => {
        return value.length >= length;
      },
    };
  }

  static Required(errorMessage: string): Validator {
    return {
      errorName: 'Required',
      errorMessage,

      validationFn: (value: any) => {
        return value.length > 0;
      },
    };
  }

  static EmailRegex(errorMessage: string): Validator {
    return {
      errorName: 'E-mail regex',
      errorMessage,

      validationFn: (value: any) => {
        return !value || isEmailValid(value);
      },
    };
  }

  static PasswordRegex(errorMessage: string): Validator {
    return {
      errorName: 'Password regex',
      errorMessage,

      validationFn: (value: any) => {
        return isPasswordValid(value);
      },
    };
  }

  static CellphoneRegex(errorMessage: string): Validator {
    return {
      errorName: 'Phone regex',
      errorMessage,

      validationFn: (value: any) => {
        return !value || isPhoneValid(value);
      },
    };
  }

  static BirthDateRegex(errorMessage: string): Validator {
    return {
      errorName: 'Birth date regex',
      errorMessage,

      validationFn: (value: any) => {
        return isBirthDateValid(value);
      },
    };
  }

  static CrmRegex(errorMessage: string): Validator {
    return {
      errorName: 'CRM regex',
      errorMessage,

      validationFn: (value: any) => {
        return isCrmValid(value);
      },
    };
  }

  static ZipCodeRegex(errorMessage: string): Validator {
    return {
      errorName: 'Zip code regex',
      errorMessage,

      validationFn: (value: any) => {
        return isZipCodeValid(value);
      },
    };
  }

  static IsNotEqualToField(valueToCompare: any, errorMessage: string): Validator {
    return {
      errorName: 'Is not equal',
      errorMessage,

      validationFn: (value: any) => {
        return value === valueToCompare;
      },
    };
  }
}
