import { useClientRect } from '@components/obj.custom-hooks/client-rect.hook';
import * as React from 'react';
import { CheckFamilySwitchGroupStyled } from './check-family-switch-group.component.style';

interface ICheckFamilySwitchGroupProps {
  show?: boolean;
}

export const CheckFamilySwitchGroup: React.FunctionComponent<ICheckFamilySwitchGroupProps> = props => {
  const [rect, ref] = useClientRect();
  const [contentHeight, setContentHeight] = React.useState(0);

  React.useEffect(() => {
    if (rect) {
      setContentHeight(rect.height);
    }
  }, [rect]);

  return (
    <CheckFamilySwitchGroupStyled show={props.show} maxHeight={`${contentHeight}px`}>
      <div ref={ref}>{props.children}</div>
    </CheckFamilySwitchGroupStyled>
  );
};
