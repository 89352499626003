import * as React from 'react';
import { Route } from 'react-router';
import { SEO, SEOConfig } from '@app/core/hoc/seo.hoc';
import { ShingrixView } from './shingrix.view';

export const SHINGRIX_ROUTE = '/centros/shingrix';

const config: SEOConfig = {
  title: 'Vacina Certa - Shingrix',
  meta: [
    {
      name: 'description',
      content: 'Encontre o centro de vacinação mais próximo a você!',
    },
  ],
};
const Shingrix = SEO(config)(ShingrixView);

export const ShingrixRoute = (
  <Route path={SHINGRIX_ROUTE} exact={true} component={Shingrix} />
);
