import * as React from 'react';
import { LogoStyled } from './logo.component.style';

export interface LogoProps {
  colored?: boolean;
  mt?: boolean;
  onlyPrint?: boolean;
}

export const Logo = (props: LogoProps) => {
  return props.colored ? (
    <LogoStyled {...props} src='/assets/img/img_logo_color.png' />
  ) : (
    <LogoStyled {...props} src='/assets/img/img_logo.png' />
  );
};
