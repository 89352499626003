import { Service } from 'typedi';
import { action, observable } from 'mobx';
import { persist } from 'mobx-persist';
import { MenuItems } from '@components/mol.header/header.model';

@Service()
export class ApplicationStore {
  @observable
  currentMenuItem: MenuItems = null;

  @persist
  @observable
  showFamilyChoiceTooltip: boolean;

  @persist
  @observable
  showFamilyChoiceOnSpecialPatientTooltip: boolean;

  @persist
  @observable
  showVaccineChoiceChoiceTooltip: boolean;

  @action
  cleanStore = () => {
    this.showFamilyChoiceTooltip = undefined;
    this.showVaccineChoiceChoiceTooltip = undefined;
  }
}
