import { useCallback, useEffect, useState } from 'react';

// https://gist.github.com/stockulus/5ade186807ab6ad3ae11633a2c477daa
export const useClientRectByPassingRef = ref => {
  const getClientRect = () => {
    if (!ref || !ref.current) {
      return null;
    }

    const clientRects = ref.current.getClientRects();
    return clientRects.length > 0 ? clientRects[0] : null;
  };

  const [state, setState] = useState(getClientRect());
  const updateState = () => setState(getClientRect());

  useEffect(() => {
    updateState();

    window.addEventListener('resize', updateState);
    return () => window.removeEventListener('resize', updateState);
  }, [ref.current]);

  return state;
};

// https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
export const useClientRect = (): [ClientRect, (node: any) => void] => {
  const [rect, setRect] = useState();
  const ref = useCallback(node => {
    if (node !== null) {
      setRect(node.getBoundingClientRect());
    }
  }, []);
  return [rect, ref];
};
