import { Container, Service } from 'typedi';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { hasWindow } from '@components/utils';
import installPwaLogDataSource from '@app/resource/install-pwa-log.datasource';

export interface PwaInstallListener {
  onDeferredPromptSet: (dp: DeferredPrompt) => void;
}

export interface DeferredPrompt {
  prompt: () => void;
}

@Service()
/**
 * It:
 * - adds listeners on PWA events
 * - fires these events on analytics
 * - can be used to know if the Pwa Install Prompt can be shown
 * - can be used to get the deferredPrompt (what's that? https://web.dev/customize-install/#in-app-flow)
 */
export class PwaInstallService {
  private analyticsService?: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);
  private deferredPrompt: DeferredPrompt;

  private listener: PwaInstallListener;

  constructor() {
    this.pwaTrackingListeners();
  }

  canShowPwaInstall = (): boolean => {
    // https://petelepage.com/blog/2019/07/is-my-pwa-installed/
    return !this.isPwaInstalled() && !installPwaLogDataSource.hasSeenInstallPrompt;
  };

  /**
   * Used to show install prompt https://web.dev/customize-install/#in-app-flow
   */
  getDeferredPrompt = () => {
    return this.deferredPrompt;
  };

  /**
   * Used to save the info that the Install Prompt was seen
   */
  installPromptWasSeen = () => {
    installPwaLogDataSource.hasSeenInstallPrompt = true;
  };

  setListener = (listener: PwaInstallListener) => {
    this.listener = listener;
  };

  private isPwaInstalled = () => {
    return installPwaLogDataSource.hasInstalledPWA || window.matchMedia('(display-mode: standalone)').matches;
  };

  // tslint:disable: max-line-length
  /**
   * https://medium.com/@suncommander/how-to-show-pwa-install-banner-add-to-homescreen-for-your-website-b1fbe6ebfdb5#e67a
   */
  private pwaTrackingListeners = () => {
    if (!hasWindow()) {
      return;
    }

    const fireAddToHomeScreenImpression = event => {
      if (!this.deferredPrompt) {
        // when the popup is shown to the user
        this.fireTracking('Add to homescreen shown');
      }

      // https://web.dev/customize-install/#beforeinstallprompt
      event.preventDefault();
      this.deferredPrompt = event;
      if (this.listener) {
        this.listener.onDeferredPromptSet(event);
      }

      // will not work for chrome, untill fixed
      event.userChoice.then(choiceResult => {
        // here you can see what the person decided, install or not, the PWA
        this.fireTracking(`User clicked ${choiceResult}`);
      });
      // This is to prevent `beforeinstallprompt` event that triggers again on `Add` or `Cancel` click
      window.removeEventListener('beforeinstallprompt', fireAddToHomeScreenImpression);
    };

    window.addEventListener('beforeinstallprompt', fireAddToHomeScreenImpression);

    // Track web app install by user
    window.addEventListener('appinstalled', event => {
      installPwaLogDataSource.hasInstalledPWA = true;

      // User installed the app (whether or not they see a pop-up)
      this.fireTracking('PWA app installed by user!!! Hurray');
    });

    // Track from where your web app has been opened/browsed
    window.addEventListener('load', () => {
      let trackText;
      // https://stackoverflow.com/a/50544192/3670829
      const isInWebAppiOS = navigator && (navigator as any).standalone;
      const isInWebAppChrome = matchMedia('(display-mode: standalone)').matches;
      if (isInWebAppiOS) {
        // Every time they started and it's iOS
        trackText = 'Launched: Installed (iOS)';
      } else if (isInWebAppChrome) {
        // Every time they started PWA (and it's not iOS).
        trackText = 'Launched: Installed';
      } else {
        // Every time they started the site (and have not yet installed PWA). The same user can trigger this event several times
        trackText = 'Launched: Browser Tab';
      }
      this.fireTracking(trackText);
    });
  };

  private fireTracking = (action: string) => {
    this.analyticsService.event('pwa', action);
  };
}
