// tslint:disable:max-line-length
import * as React from 'react';
// tslint:enable:max-line-length

interface BooleanContainerStateProps {
  defaultValue?: boolean;
  willChangeValue?: (oldValue: boolean, newValue: boolean) => void;
  children: (props: BooleanContainerChildrenProps) => JSX.Element;
}

interface BooleanContainerState {
  value?: boolean;
}

export interface BooleanContainerChildrenProps {
  value?: boolean;
  setTrue: () => void;
  setFalse: () => void;
  toggle: () => void;
  setValue: (value) => void;
}

export class BooleanContainer extends React.Component<BooleanContainerStateProps, BooleanContainerState> {
  constructor(props: any) {
    super(props);
    this.state = { value: this.props.defaultValue };
  }

  render() {
    return this.props.children({
      value: this.state.value,
      setTrue: this.setTrue,
      setFalse: this.setFalse,
      setValue: this.setValue,
      toggle: this.toggle,
    });
  }

  private setValue = (value: boolean) => {
    this.triggerChangeValue(this.state.value, value);
    this.setState({ value });
  };

  private toggle = () => {
    this.triggerChangeValue(this.state.value, !this.state.value);
    this.setValue(!this.state.value);
  };

  private setTrue = () => {
    this.triggerChangeValue(this.state.value, true);
    this.setValue(true);
  };

  private setFalse = () => {
    this.triggerChangeValue(this.state.value, false);
    this.setValue(false);
  };

  private triggerChangeValue = (oldValue: boolean, newValue: boolean) => {
    if (this.props.willChangeValue) {
      this.props.willChangeValue(oldValue, newValue);
    }
  };
}
