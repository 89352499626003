/* tslint:disable:all */
import * as React from 'react';

const SvgLactente = props => (
  <svg width={69} height={69} viewBox='0 0 69 69' {...props}>
    <title>{'lactante'}</title>
    <g fill='none' fillRule='evenodd'>
      <circle cx={34.5} cy={34.5} r={34.5} fill='#FEF5E2' />
      <g transform='translate(16 16)'>
        <path
          d='M13.563 15.841c.01.003 1.023.119 1.71-.567.687-.687.568-1.7.567-1.71a.793.793 0 011.569-.218c.01.073.237 1.792-1.017 3.046-.904.906-2.052 1.039-2.647 1.039-.23 0-.379-.02-.4-.02a.79.79 0 01-.669-.89.794.794 0 01.887-.68zM12.667 0C19.77 0 38 18.23 38 25.333 38 32.317 32.317 38 25.333 38 18.231 38 0 19.77 0 12.667 0 5.683 5.683 0 12.667 0zm0 3.167c5.239 0 9.5 4.26 9.5 9.5 0 5.239-4.261 9.5-9.5 9.5-5.24 0-9.5-4.261-9.5-9.5 0-5.24 4.26-9.5 9.5-9.5z'
          fill='#000'
          fillRule='nonzero'
        />
        <path
          d='M25.924 23.775c.022-.006.043 0 .065-.003.516-3.919.921-8.331.926-12.6-5.171-5.18-11.132-9.589-14.248-9.589-6.112 0-11.084 4.972-11.084 11.084 0 2.802 3.568 7.904 8.052 12.663 10.985-.202 16.237-1.54 16.289-1.555zM23.794 36.136c.13-.554 1.128-4.903 1.965-10.698-1.536.334-6.256 1.2-14.625 1.44 4.403 4.402 9.367 8.23 12.66 9.258zM36.417 25.333c0-2.778-3.51-7.821-7.944-12.548-.25 10.826-2.758 22.069-3.12 23.632 6.103-.013 11.064-4.98 11.064-11.084z'
          fill='#DD6386'
          fillRule='nonzero'
        />
        <path
          d='M12.667 20.583c4.365 0 7.916-3.551 7.916-7.916 0-4.366-3.551-7.917-7.916-7.917-4.366 0-7.917 3.551-7.917 7.917 0 4.365 3.551 7.916 7.917 7.916z'
          fill='#FFF'
          fillRule='nonzero'
        />
        <path
          d='M7.498 7.218c.915-.53 1.624-.482 2.128.145.756.94.858 1.248.858 1.97 0 .721-.634 1.21-1.447.785-.542-.284-.632-.807-.27-1.57'
          stroke='#4F2748'
          strokeWidth={0.8}
        />
        <g transform='translate(12.448 13.103)'>
          <circle stroke='#000' strokeWidth={0.5} fill='#FCC74B' cx={2.018} cy={2.018} r={2.018} />
          <path
            d='M1.966 1.31a.655.655 0 100 1.31.655.655 0 000-1.31zm.001.921a.263.263 0 01-.264-.264c0-.146.118-.264.264-.264.147 0 .264.118.264.264a.263.263 0 01-.264.264z'
            fill='#000'
            fillRule='nonzero'
          />
        </g>
        <circle stroke='#4F2748' strokeWidth={0.8} cx={12.579} cy={13.103} r={7.862} />
      </g>
    </g>
  </svg>
);

export default SvgLactente;
