import styled from 'styled-components';
import { LogoProps } from './logo.component';
import { Spacing } from '@components/obj.constants';

export const LogoStyled = styled.img`
  height: 44px;
  margin-top: ${(props: LogoProps) => (props.mt ? Spacing.XXXLarge : 0)};
  display: ${(props: LogoProps) => (props.onlyPrint ? 'none' : 'inline-block')};

  @media print {
    display: inline-block;
    text-align: center;
  }
`;
