import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { BadgeText, StepperInactive } from '@components/atm.typography';
import { ProgressCellProps, ProgressStatus } from './progress-cell.component';
import { Color } from '@components/obj.constants';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export const ProgressCellStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & + :before {
    position: relative;
    top: 18px;
    left: -50%;
    z-index: 0;
    display: inline-block;
    width: 100%;
    height: 2px;
    background-color: ${(props: ProgressCellProps) => statusStyle(props.status).lineColor};
    content: '';
  }
`;

export const ProgressCellBadgeStyled = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 16px;
  border-width: 2px;
  border-color: ${(props: ProgressCellProps) => statusStyle(props.status).badgeBorderColor};
  background-color: ${(props: ProgressCellProps) => statusStyle(props.status).badgeBackgroundColor};
  border-style: solid;
  align-self: flex-start;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-self: center;
  z-index: 1;
  cursor: pointer;
`;

export const ProgressCellChecked = styled(FontAwesomeIcon).attrs({ icon: faCheck })<{ status: ProgressStatus }>`
  color: ${Color.White};
  display: ${props => statusStyle(props.status).checkDisplay};
  align-self: center;
  font-size: 14px;
  cursor: pointer;
`;

export const ProgressCellNumberStyled = styled(BadgeText)`
  position: relative;
  top: -1px; // adjustment due to font type
  display: ${(props: ProgressCellProps) => statusStyle(props.status).numberDisplay};
  text-align: center;
  color: ${(props: ProgressCellProps) => statusStyle(props.status).numberColor};
  font-size: 20px;
  cursor: pointer;
`;

export const ProgressCellTextStyled = styled(StepperInactive)`
  text-align: center;
  color: ${(props: ProgressCellProps) => statusStyle(props.status).textColor};
  cursor: pointer;
`;

interface ProgressStatusStyle {
  lineColor: string;
  badgeBorderColor: string;
  badgeBackgroundColor: string;
  checkDisplay: string;
  numberDisplay: string;
  numberColor: string;
  textColor: string;
}

function statusStyle(status: ProgressStatus): ProgressStatusStyle {
  let lineColor: string;
  let badgeBorderColor: string;
  let badgeBackgroundColor: string;
  let checkDisplay: string;
  let numberDisplay: string;
  let numberColor: string;
  let textColor: string;

  switch (status) {
    case 'past':
      lineColor = Color.Success;
      badgeBorderColor = Color.Success;
      badgeBackgroundColor = Color.Success;
      checkDisplay = 'block';
      numberDisplay = 'none';
      numberColor = Color.White;
      textColor = Color.Gray;
      break;
    case 'current':
      lineColor = Color.GrayLight;
      badgeBorderColor = Color.Primary;
      badgeBackgroundColor = Color.Primary;
      checkDisplay = 'none';
      numberDisplay = 'block';
      numberColor = Color.White;
      textColor = Color.Secondary;
      break;
    case 'future':
      lineColor = Color.GrayLight;
      badgeBorderColor = Color.Primary;
      badgeBackgroundColor = Color.White;
      checkDisplay = 'none';
      numberDisplay = 'block';
      numberColor = Color.Primary;
      textColor = Color.Gray;
      break;
  }

  return {
    lineColor,
    badgeBorderColor,
    badgeBackgroundColor,
    checkDisplay,
    numberDisplay,
    numberColor,
    textColor,
  };
}
