import * as React from 'react';
import { Field, FieldPropsInterface, FieldStateInterface } from '@components/obj.form';

import {
  CheckboxCheckedStyled,
  CheckboxFieldBulletStyled,
  CheckboxFieldStyled,
  CheckboxFieldTextStyled,
  CheckboxUncheckedStyled,
  DefaultCheckboxCheckedStyled,
  DefaultCheckboxUncheckedStyled,
} from './checkbox-field.component.style';
import { Else, If } from '../obj.if';

export interface CheckboxFieldSelectProps {
  checked: boolean;
}

export interface CheckboxFieldProps extends FieldPropsInterface {
  value: any;
  checked?: boolean;
  disabled?: boolean;
  onValueChange?: (value: any, checked: boolean) => void;
  isDefaultStyle?: boolean;
}

// tslint:disable-next-line: no-empty-interface
interface CheckboxFieldState extends FieldStateInterface { }

export class CheckboxField extends Field<CheckboxFieldProps, CheckboxFieldState> {
  constructor(props: CheckboxFieldProps) {
    super(props);
    this.state = {
      errors: [],
    };
  }

  render() {
    return (
      <CheckboxFieldStyled>
        <input type='hidden' name={this.props.name} value={1} />
        <CheckboxFieldBulletStyled
          type='checkbox'
          id={this.props.name + '_' + this.props.value}
          name={this.props.name + '_'}
          checked={this.props.checked}
          onChange={this.onPress}
          value={this.props.value}
        />
        <If cond={this.props.isDefaultStyle}>
          <DefaultCheckboxCheckedStyled onClick={this.onPress} />
          <DefaultCheckboxUncheckedStyled onClick={this.onPress} />
          <Else />
          <CheckboxCheckedStyled onClick={this.onPress} />
          <CheckboxUncheckedStyled onClick={this.onPress} />
        </If>

        <CheckboxFieldTextStyled htmlFor={this.props.name + '_' + this.props.value}>
          {this.props.children}
        </CheckboxFieldTextStyled>
      </CheckboxFieldStyled>
    );
  }

  private onPress = () => {
    if (this.props.onValueChange) {
      this.props.onValueChange(this.props.value, !this.props.checked);
    }
  };
}
