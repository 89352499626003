// tslint:disable: max-line-length
import BrazilStateSelect from '@app/components/brazil-state-select.component';
import { CookieConsentContainer } from '@app/components/cookie-consent';
import { PropsBase } from '@app/core/base/props.base';
import { GraphQL } from '@app/core/decorator/graphql.decorator';
import { Observer } from '@app/core/decorator/observer.decorator';
import { Router } from '@app/core/route/router';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { MobileService } from '@app/core/service/mobile-service';
import { AccountStore } from '@app/modules/account/account.store';
import { APPOINTMENT_PATH } from '@app/modules/appointment/appointment.route';
import { AppointmentStore } from '@app/modules/appointment/appointment.store';
import { AuthenticationStore, DELETE_USER_MAP_KEY, SIGN_UP_MAP_KEY } from '@app/modules/authentication/authentication.store';
import { LOGIN_PATH } from '@app/modules/authentication/login.route';
import { UserCreate } from '@app/resource/graphql/graphql-schema';
import { Button, LinkButton } from '@components/atm.button';
import { Carousel } from '@components/atm.carousel';
import { CheckboxField, CheckboxFieldGroup } from '@components/atm.checkbox';
import { Notification } from '@components/atm.notification';
import { TextField } from '@components/atm.text-field';
import { Body, FeaturedH2, InputLabel } from '@components/atm.typography';
import { PasswordLength } from '@components/obj.constants';
import { FieldValidator, Form, Validators } from '@components/obj.form';
import { Col, Grid, Row, Separator } from '@components/obj.grid';
import { If } from '@components/obj.if';
import { OverflowXContainer } from '@components/obj.overflow-container';
import {
  HomeHeader, HomeSignUpBackgroundStyled,
  HomeSignUpStyled,
  HowItWorksOverflowSection,
  HowItWorksRow,
  TestimonialListContainerStyled,
  TestimonialRow,
  TranslatedGridTopStyled,
} from '@components/pag.home';
import { FooterContainerView } from '@components/pag.home/obj.footer/footer.container';
import { reaction } from 'mobx';
import * as React from 'react';
import * as Scroll from 'react-scroll';
import { Container } from 'typedi';
import { TERMS_PATH } from '../terms/terms.route';
// tslint:enable: max-line-length

export const HowItWorksId = 'como-funciona';

export type HomeViewProps = PropsBase<UserCreate>;

// Hotjar
declare var hj: any;

@GraphQL('user-create.mutation')
@Observer()
export class HomeView extends React.Component<HomeViewProps, any> {
  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);
  private router?: Router = Container.get(Router);
  private accountStore?: AccountStore = Container.get(AccountStore);
  private analyticsService?: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);
  private appointmentStore?: AppointmentStore = Container.get(AppointmentStore);
  private mobileService?: MobileService = Container.get(MobileService);

  private scroll = Scroll.scroller;
  private reactionDisposers = [];
  private termsFormRef;
  private newsletterFormRef;
  private smsFormRef;

  private videoPlayTracked: boolean = false;

  constructor(props) {
    super(props);

    this.reactionDisposers.push(reaction(() => this.authenticationStore.loggedIn, this.onLogin));
    this.reactionDisposers.push(
      reaction(() => this.authenticationStore.errorMap.get(SIGN_UP_MAP_KEY), this.onErrorMapChanged),
    );
    this.state = { signUpErrorMsg: '' };
    this.appointmentStore.reset = true;
  }

  componentDidMount() {
    if (this.accountStore.scrollToSignUpForm) {
      this.accountStore.scrollToSignUpForm = false;
      this.scrollToSignUpForm();
    }

    if (this.accountStore.showChangePasswordSuccessMessage) {
      console.log('Change password success');
      this.accountStore.showChangePasswordSuccessMessage = false;
      // TODO - FRONT - Render change password success notification
    }

    this.analyticsService.pageView('home');
  }

  componentWillUnmount() {
    this.reactionDisposers.map(disposer => disposer());
    this.reactionDisposers = [];
    this.authenticationStore.resetErrorState();
  }

  render() {
    const successMessage = this.authenticationStore.successMessageMap.get(DELETE_USER_MAP_KEY);

    return (
      <div>
        <CookieConsentContainer />
        {successMessage &&
          <Notification
            type='success'
            message={successMessage}
          />
        }
        <OverflowXContainer>{this.renderHeader()}</OverflowXContainer>
        <OverflowXContainer>
          {this.renderHowItWorks()}
          {this.renderTestimonial()}
          <If cond={!this.authenticationStore.loggedIn}>{this.renderForm()}</If>
          <FooterContainerView />
        </OverflowXContainer >
      </div >
    );
  }

  onLogin = () => {
    if (this.authenticationStore.loggedIn) {
      this.router.push(APPOINTMENT_PATH);
    }
  };

  onErrorMapChanged = () => {
    if (this.authenticationStore.errorMap.get(SIGN_UP_MAP_KEY)) {
      this.scrollToSignUpForm();
    }
  };

  private onSubmit = formData => {
    this.analyticsService.event('home_cadastrar_form_button', 'click', true);

    this.setState({ signUpErrorMsg: '' });
    this.authenticationStore.resetMapItem(SIGN_UP_MAP_KEY);
    const areTermsAccepted = this.termsFormRef.props.checked;
    if (formData.errors.length === 0 && areTermsAccepted) {
      this.authenticationStore.signUp(
        {
          email: formData.data.email,
          password: formData.data.password,
          phone: formData.data.phone || undefined,
          crm: formData.data.crm,
          uf: formData.data.state,
          newsletterOptIn: this.newsletterFormRef.props.checked,
          smsOptIn: this.smsFormRef.props.checked,
        },
        this.props.mutate,
      );
    } else {
      if (typeof hj !== 'undefined') {
        hj('formSubmitFailed');
      }
      this.scrollToSignUpForm();
      if (!areTermsAccepted) {
        this.setState({ signUpErrorMsg: 'Por favor, aceite os termos para prosseguir.' });
      }
    }
  };

  private onLoginClick = () => {
    this.router.push(LOGIN_PATH);
    this.analyticsService.event('home_entrar_form_button', 'click');
  };

  private onTopSignUpClick = () => {
    this.scrollToSignUpForm();
    this.analyticsService.event('home_cadastrar_cta', 'click', true);
  };

  private onBottomSignUpClick = () => {
    this.scrollToSignUpForm();
    this.analyticsService.event('home_cadastrar_inferior_cta', 'click', true);
  };

  private scrollToSignUpForm() {
    this.scroll.scrollTo('signup', { smooth: false });
  }

  private onStartAppointmentClick = () => {
    this.router.push(APPOINTMENT_PATH);
    this.analyticsService.event('menu_iniciar_consulta', 'click');
  };

  private renderHeader = () => {
    return (
      <HomeHeader
        loggedIn={this.authenticationStore.loggedIn}
        onSignUpClick={this.onTopSignUpClick}
        onStartAppointmentClick={this.onStartAppointmentClick}
        onMobile={this.mobileService.onMobile}
      />
    );
  };

  private renderHowItWorks = () => {
    return (
      <HowItWorksOverflowSection>
        <FeaturedH2 id={HowItWorksId}>Conheça a ferramenta</FeaturedH2>

        <Grid>
          <HowItWorksRow
            title='Calendário SBP'
            description={`Escolha a idade do paciente e recomende as vacinas
                de acordo com o calendário oficial da SBP.`}
            img='assets/img/img_landing_age_3.jpg'
          />
          {this.authenticationStore.loggedIn ||
            <Row center={'xs'} mb={true}>
              <Col xs={12} sm={8} md={6} lg={4}>
                <Button kind='callToAction' onClick={this.onBottomSignUpClick} expanded={true}>
                  Iniciar
                </Button>
                <Separator />
              </Col>
            </Row>
          }
        </Grid>
      </HowItWorksOverflowSection>
    );
  };

  private renderTestimonial = () => {
    // tslint:disable: max-line-length
    const testimonials = [
      {
        img: 'assets/img/img_doctor_luiz_marcos.jpg',
        message:
          'O Vacina Certa me permite orientar e prescrever vacinas com segurança, seguindo as recomendações da SBP. Isso agrega valor à minha consulta, visto que o assunto vacinas é de grande importância para saúde pública. Médico e Paciente, seguros e satisfeitos.',
        name: 'Dr. Luiz Marcos - CRM MT 4170',
      },
      {
        img: 'assets/img/img_doctor_maira_canedo.jpg',
        message:
          'Adoro o programa Vacina Certa, pois além de nos ajudar a lembrar de todas as vacinas necessárias para as idades, nos mostra as opções de vacinas na rede pública e privada! Programa de fácil acesso, gratuito e de acordo com as recomendações da Sociedade Brasileira de Pediatria.',
        name: 'Dra. Maíra Canedo - CRM MG 63061',
      },
      // {
      //  img: 'assets/img/img-doctor-sample-circular.png',
      //  message: 'Eu tenho uma televisão que eu projeto para os pais, de forma visual as deiferenças das vacinas (qual é acelular, qual tem mais picadas) e assim os pais assimilam melhor. Ganha tempo na consulta e os pais saem mais satisfeitos, vale a pena!',
      //  name: 'Dr. José Vicente Prandini - Pediatra CRM ',
      // },
      {
        img: 'assets/img/img_doctor_ethel_mariam.jpg',
        message:
          'O Vacina Certa ajuda a lembrar das vacinas nas diferentes faixas etárias, principalmente quando há mudança no calendário vacinal, agilizando a consulta.',
        name: 'Dra. Ethel Mariam Voloch - CRM RJ 356597',
      },
      {
        img: 'assets/img/img_doctor_andre.jpg',
        message:
          'O site Vacina Certa nos proporciona mais agilidade e clareza na informação das vacinas para os pacientes, dessa forma os pais ficam cientes de suas diferenças, indicações e períodos de aplicação. A adesão à realização das vacinas com certeza é muito maior!',
        name: 'Dr. André Sérgio Malysz – CRM RS 25853',
      },
      {
        img: 'assets/img/img_doctor_julio.jpg',
        message:
          'Acho o Vacina Certa muito útil, bem visual, bom para o uso durante as consultas para melhor entendimento dos pais.',
        name: 'Dr. Júlio Carlos Reinbrecht - CRM RS 18231',
      },
    ];
    // tslint:enable: max-line-length

    return (
      <TestimonialListContainerStyled>
        <Grid>
          <FeaturedH2>Depoimentos</FeaturedH2>

          <Row>
            <Col xs={12}>
              <Carousel
                loop={true}
                auto={true}
                showNavigationButton={true}
                interval={14000}
                duration={300}
                width={1}
                scale={1}
                borderItemsXTranslation={0}
              >
                {testimonials.map((item, index) => (
                  <TestimonialRow
                    key={index}
                    img={item.img}
                    message={item.message}
                    name={item.name}
                    reverse={false}
                    hideDrop={true}
                  />
                ))}
              </Carousel>
            </Col>
          </Row>
        </Grid>
        <TranslatedGridTopStyled />
      </TestimonialListContainerStyled>
    );
  };
  private renderForm = () => {
    const Element = Scroll.Element;

    const emailCheckboxId = 1;
    const smsCheckboxId = 2;
    const termsCheckboxId = 3;

    return (
      <section>
        <Element name='signup' />
        <Form onSubmit={this.onSubmit}>
          <HomeSignUpBackgroundStyled>
            <Grid>
              <Row id='signup'>
                <Col xs={12} sm={8} md={6} smOffset={2} mdOffset={3}>
                  <HomeSignUpStyled>
                    <FeaturedH2> Cadastre-se, é de graça </FeaturedH2>
                    <If
                      cond={this.state.signUpErrorMsg !== '' || this.authenticationStore.errorMap.get(SIGN_UP_MAP_KEY)}
                    >
                      <Row mb={true}>
                        <Col xs={12}>
                          <Notification
                            type='error'
                            message={
                              this.authenticationStore.errorMap.get(SIGN_UP_MAP_KEY)
                                ? this.authenticationStore.errorMessageMap.get(SIGN_UP_MAP_KEY)
                                : this.state.signUpErrorMsg
                            }
                          />
                        </Col>
                      </Row>
                    </If>
                    {/* E-mail */}
                    <Row mb={true}>
                      <Col xs={12}>
                        <InputLabel>E-mail</InputLabel>
                        <FieldValidator
                          name='email'
                          validators={[
                            Validators.Required('Campo obrigatório'),
                            Validators.EmailRegex('O e-mail digitado não é válido'),
                          ]}
                        >
                          <TextField type='email' placeholder='' />
                        </FieldValidator>
                      </Col>
                    </Row>

                    <Row mb={true}>
                      <Col xs={12}>
                        <InputLabel>Celular com DDD (opcional)</InputLabel>
                        <FieldValidator
                          name='phone'
                          validators={[Validators.CellphoneRegex('O celular preenchido não é válido')]}
                        >
                          <TextField type={'cel-phone'} />
                        </FieldValidator>
                      </Col>
                    </Row>

                    {/* Senha */}
                    <Row mb={true}>
                      <Col xs={12}>
                        <InputLabel>Senha</InputLabel>
                        <FieldValidator
                          name='password'
                          validators={[
                            Validators.Required('Campo é obrigatório'),
                            Validators.MaxLength(
                              PasswordLength.Max,
                              `A senha deve ter menos de ${PasswordLength.Max} caracteres`,
                            ),
                            Validators.MinLength(
                              PasswordLength.Min,
                              `A senha deve ter pelo menos ${PasswordLength.Min} caracteres`,
                            ),
                            Validators.PasswordRegex(`A senha deve ter pelo menos uma letra e um número.`),
                          ]}
                          validatorPlaceholder={`A senha deve ter entre ${PasswordLength.Min} e
                            ${PasswordLength.Max} caracteres e pelo menos uma letra e um número.`}
                        >
                          <TextField
                            type='password'
                            maxLength={PasswordLength.Max}
                            placeholder='Sua senha pessoal de acesso'
                          />
                        </FieldValidator>
                      </Col>
                    </Row>

                    {/* CRM */}
                    <Row mb={true}>
                      <Col xs={12}>
                        <InputLabel>CRM</InputLabel>
                        <FieldValidator
                          name='crm'
                          validators={[
                            Validators.Required('Campo obrigatório'),
                            Validators.CrmRegex(`O CRM deve ter somente números.`),
                          ]}
                        >
                          <TextField type={'custom'} options={{ mask: '9999999' }} />
                        </FieldValidator>
                      </Col>
                    </Row>

                    <Row mb={true}>
                      <Col xs={12}>
                        <InputLabel>Estado</InputLabel>
                        <FieldValidator name='state' validators={[Validators.Required('Campo obrigatório')]}>
                          <BrazilStateSelect />
                        </FieldValidator>
                      </Col>
                    </Row>

                    {/* contact Opt-in */}
                    <Row mb={true}>
                      <Col xs={12} >
                        <CheckboxFieldGroup value={[smsCheckboxId, termsCheckboxId]}>
                          <CheckboxField
                            isDefaultStyle={true}
                            value={2}
                            ref={check => (this.newsletterFormRef = check)}
                          >
                            Desejo receber comunicações da GSK no <strong>e-mail</strong> cadastrado
                          </CheckboxField>
                          <CheckboxField
                            isDefaultStyle={true}
                            value={3}
                            ref={check => (this.smsFormRef = check)}
                          >
                            Desejo receber comunicações da GSK no <strong>celular</strong> cadastrado
                          </CheckboxField>
                        </CheckboxFieldGroup>
                        <Separator />
                        <CheckboxFieldGroup>
                          <CheckboxField
                            isDefaultStyle={true}
                            value={emailCheckboxId}
                            ref={check => (this.termsFormRef = check)}
                          >
                            Li e concordo com os&nbsp;
                            <LinkButton onClick={this.onTermsClick}>termos de uso</LinkButton>
                            &nbsp;e as&nbsp;
                            <LinkButton onClick={this.onPrivacyPolicyClick}>políticas de privacidade</LinkButton>
                          </CheckboxField>
                        </CheckboxFieldGroup>
                      </Col>
                    </Row>

                    <Row center='xs' mb={true}>
                      <Col>
                        <Button
                          kind='callToAction'
                          type='submit'
                          loading={this.authenticationStore.loadingMap.get(SIGN_UP_MAP_KEY)}
                        >
                          Cadastrar
                        </Button>
                      </Col>
                    </Row>

                    <Row center='xs'>
                      <Col>
                        <Body>
                          Já tem uma conta? <LinkButton onClick={this.onLoginClick}>Entrar</LinkButton>
                        </Body>
                      </Col>
                    </Row>
                  </HomeSignUpStyled>
                </Col>
              </Row>
            </Grid>
          </HomeSignUpBackgroundStyled>
        </Form>
      </section>
    );
  };

  private onTermsClick = e => {
    this.analyticsService.event('footer_termos_de_uso', 'click');
    window.open(TERMS_PATH, '_blank');
  };

  private onPrivacyPolicyClick = e => {
    this.analyticsService.event('footer_politicas_de_privacidade', 'click');
    e.preventDefault();
    // https://www.notion.so/taqtile/LGPD-termos-15ec7aa798784ea8be073498de9e5d07#2383f9183ab945108c21f5087c697675
    window.open('https://privacy.gsk.com/pt-br/privacy-notice/healthcare-professional/', '_blank');
  };

}
