import { PropsBase } from '@app/core/base/props.base';
import { GraphQL } from '@app/core/decorator/graphql.decorator';
import { Observer } from '@app/core/decorator/observer.decorator';
import { AuthenticationStore } from '@app/modules/authentication/authentication.store';
import { UpdateUserView } from '@app/modules/authentication/update-user.view';
import { UpdateDoctorInput, UserUpdate } from '@app/resource/graphql/graphql-schema';
import * as React from 'react';
import { Container } from 'typedi';

export type UpdateUserDataProps = PropsBase<UserUpdate>;

@GraphQL('user-update.mutation')
@Observer()
export class UpdateUserDataMutation extends React.Component<UpdateUserDataProps, any> {
  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);

  render() {
    return <UpdateUserView onSubmit={this.callMutation} />;
  }

  private callMutation = (input: UpdateDoctorInput) => {
    this.authenticationStore.updateUser(input, this.props.mutate);
  };
}
