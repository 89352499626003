import { Service } from 'typedi';
import * as https from 'https';
import * as queryString from 'query-string';

@Service()
export class ServerGoogleAnalyticsService {
  constructor(private trackingId: string) {
    this.trackingId = trackingId;
  }

  /**
   *
   * @param category A top level category for these events. E.g. 'User', 'Navigation', 'App Editing', etc.
   * @param action A description of the behaviour. E.g. 'Clicked Delete', 'Added a component', 'Deleted account', etc.
   */
  event(category: string, action: string, label?: string) {
    const query = {
      v: '1',
      tid: this.trackingId,
      cid: '555',
      t: 'event',
      ec: category,
      ea: action,
      el: label,
    };
    return https.get({ host: 'www.google-analytics.com', path: `/collect?${queryString.stringify(query)}` });
  }
}
