import styled from 'styled-components';
import { Color, Spacing } from '@components/obj.constants';
import { H3 } from '@components/atm.typography';

export const checkFamilyGroupPadding = Spacing.XLarge;

export const CheckFamilyGroupStyled = styled.div`
  padding: ${checkFamilyGroupPadding};
  border-top: 1px solid ${Color.GrayXLight};
`;

export const CheckFamilyH3Styled = styled(H3)`
  margin-bottom: ${Spacing.Large};
  @media all and (min-width: 48em) {
    margin-bottom: 0;
    margin-top: 0;
  }
`;
