import { DocumentNode } from 'graphql';
import { Service } from 'typedi';

// TODO: Move this class to a package folder. Something related to extensions
@Service()
export class GraphQLDocsService {
  private graphQLDocs: { [key: string]: any };

  constructor() {
    this.graphQLDocs = require('../../resource/graphql/graphql-documents.json');
  }

  getDocument(documentName: string): DocumentNode {
    if (documentName.indexOf('.query') !== -1) {
      return this.getQuery(documentName);
    } else if (documentName.indexOf('.mutation') !== -1) {
      return this.getMutation(documentName);
    }

    throw new Error('GraphQL document not found. Verify document name');
  }

  private getQuery(queryName: string): DocumentNode {
    const key = `src/app/resource/graphql/query/${queryName}.graphql`;
    return this.graphQLDocs[key];
  }

  private getMutation(mutationName: string): DocumentNode {
    const key = `src/app/resource/graphql/mutation/${mutationName}.graphql`;
    return this.graphQLDocs[key];
  }
}
