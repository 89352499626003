import { hasWindow } from '@components/utils';

/**
 * Service to handle cosmobots
 * https://cosmobots.io/
 */
export class CosmobotsService {
  constructor(readonly id: string) {
    this.id = id;
    this.insertRuleToHideCosmobotPop();
  }

  drawChatIfNeeded() {
    setTimeout(() => {

      // POG_ALERT: this code rule is highly coupled with a fragile assumption.
      // If the cosmobots team decides to change the widget implementation (more
      // precisely, the css classes ".cosmobots-pop" and ".cosmobots-chat"), then
      // this code might fail
      const isCosmobotChatNotDrawn = !document.querySelector('.cosmobots-pop') || !document.querySelector('.cosmobots-chat');
      if (isCosmobotChatNotDrawn) {
        try {
          // the window.onload will draw the chat (@see https://cosmobots.io/js/cosmo-plugin.js)
          window.onload(null);
        } catch (error) {
          console.error('ERROR: cosmobots.service.ts ~ line 20 ~ CosmobotsService ~ drawChatIfNeeded ~ error', error);
        }
      }

      // POG_ALERT: this code rule is highly coupled with a fragile assumption.
      // If the cosmobots team decides to change the widget implementation (more
      // precisely, the css classes ".cosmobots-iframe-chat-outer"), then
      // this code might fail
      // WHY this? The color of the "X" symbol is the same as the "bot name"
      // (Color Padrão) color. We want the "bot name" to be pink, therefore the
      // "X" becomes invisible in the header. The following code "draws" a white
      // "X" symbol to act as a placeholder for the real one
      const closeButtonClass = 'cosmobots-custom-close';
      const isCloseButtonNotDrawn = !document.querySelector(`.${closeButtonClass}`);
      if (isCloseButtonNotDrawn) {
        const cosmobotsChatWrapper = document.querySelector('.cosmobots-iframe-chat-outer');
        const div = document.createElement('div');
        div.innerHTML = `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" class="svg-inline--fa fa-times fa-w-11 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="white" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>`;
        div.className = closeButtonClass;
        cosmobotsChatWrapper.appendChild(div);
        if (hasWindow() && window.document.styleSheets) {
          window.document.styleSheets[0].insertRule(`
        .${closeButtonClass} {
          position: absolute;
          top: 21px;
          right: 16px;
          pointer-events: none;
        }
        `);
        }
      }
    }, 1000);
  }

  showChat() {
    // POG_ALERT: this css rule is highly coupled with a fragile assumption.
    // If the cosmobots team decides to change the widget implementation, then
    // this code might fail
    const chat = document.querySelector<HTMLElement>('.cosmobots-chat');
    if (!chat) { return; }

    if (!chat.style.display) {
      chat.style.display = 'none';
    }
    if (!chat.style.display || chat.style.display === 'block') {
      chat.setAttribute('style', 'display : none');
    } else {
      chat.setAttribute('style', 'display : block');
    }
  }

  hideChat() {
    // POG_ALERT: this css rule is highly coupled with a fragile assumption.
    // If the cosmobots team decides to change the widget implementation, then
    // this code might fail
    const chat = document.querySelector<HTMLElement>('.cosmobots-chat');
    if (!chat) { return; }

    chat.setAttribute('style', 'display : none');
  }

  // Why this method? We won't use the cosmobots "Pop" component, we use our own
  // "POP" To understand what's the "POP" component, you can comment this method
  // implementation and the you will see the "POP" on the screen
  private insertRuleToHideCosmobotPop() {
    if (hasWindow() && window.document.styleSheets) {

      // POG_ALERT: this css rule is highly coupled with a fragile assumption.
      // If the cosmobots team decides to change the widget implementation, then
      // this code might fail
      window.document.styleSheets[0].insertRule(`
        .cosmobots-pop {
          display: none !important;
        }
      `);
      window.document.styleSheets[0].insertRule(`
        @media print {
          .cosmobots-chat {
              display: none !important;
          }
        }
      `);
    }
  }
}
