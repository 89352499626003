/* tslint:disable:all */
import * as React from 'react';

const SvgIdoso = props => (
  <svg width={69} height={69} {...props}>
    <defs>
      <path id='Idoso_svg__a' d='M0 0h28.462v43.125H0z' />
      <path id='Idoso_svg__c' d='M0 43.125h28.462V0H0z' />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <circle cx={34.5} cy={34.5} r={34.5} fill='#FEF5E2' />
      <g transform='translate(20.7 12.938)'>
        <mask id='Idoso_svg__b' fill='#fff'>
          <use xlinkHref='#Idoso_svg__a' />
        </mask>
        <path
          d='M23.719 30.998l-4.744-.001v-2.45c3.605-1.754 6.1-5.428 6.1-9.68v-2.021a.7.7 0 0 0 .444-.174c.015-.013.03-.023.044-.037a.686.686 0 0 0 .128-.183c.001-.002 0-.006.002-.01.017-.038.02-.083.03-.126.01-.045.026-.09.027-.135l.002-.01v-2.02c0-2.717-.961-5.213-2.556-7.18-.015-.018-.025-.041-.043-.056a11.561 11.561 0 0 0-1.467-1.483V4.38C21.686 1.924 18.56 0 14.57 0c-3.99 0-7.116 1.925-7.116 4.38v.527c-2.868 2.086-4.743 5.446-4.743 9.243v2.022l.002.009c0 .046.017.09.027.136.01.042.013.087.03.125.001.004 0 .007.002.01.032.07.077.129.127.183.014.014.03.024.044.037a.636.636 0 0 0 .212.127.696.696 0 0 0 .232.047h.001v2.022c0 4.252 2.495 7.926 6.1 9.68v2.45l-4.743-.001C2.13 30.997 0 33.113 0 35.715v6.062c0 .744.609 1.348 1.356 1.348h25.751c.748 0 1.355-.604 1.355-1.348v-6.063c0-2.6-2.128-4.716-4.743-4.716'
          fill='#4F2748'
          mask='url(#Idoso_svg__b)'
        />
        <path
          d='M24.396 15.124l-8.532-3.87 6.607-2.998a9.999 9.999 0 0 1 1.925 5.895v.973zM14.57 1.348c3.122 0 5.76 1.389 5.76 3.032v.07a11.49 11.49 0 0 0-11.476-.422c.345-1.486 2.82-2.68 5.716-2.68'
          fill='#4F2748'
        />
        <path
          d='M14.231 4.043c2.888 0 5.491 1.209 7.344 3.137L4.066 15.124v-.973c0-5.574 4.56-10.108 10.165-10.108'
          fill='#4F2748'
        />
        <path
          d='M4.744 18.868v-2.57l9.487-4.304 9.488 4.304v2.57c0 5.202-4.256 9.434-9.488 9.434-5.231 0-9.487-4.232-9.487-9.434M14.231 29.65a10.82 10.82 0 0 0 3.389-.548v2.569c0 .372.303.674.677.674h3.627c-.89 1.43-3.136 4.043-7.693 4.043-4.597 0-6.825-2.61-7.704-4.043h3.638a.675.675 0 0 0 .678-.674v-2.569a10.82 10.82 0 0 0 3.388.547'
          fill='#FFF'
        />
        <path
          d='M4.745 32.345h.245c.682 1.342 3.233 5.39 9.241 5.39 6.008 0 8.559-4.046 9.241-5.39h.247c1.868 0 3.388 1.512 3.388 3.37v6.062H1.356v-6.062c0-1.858 1.52-3.37 3.39-3.37'
          fill='#DD6386'
        />
        <path
          d='M12.218 33.692a.676.676 0 0 0 .678-.674.676.676 0 0 0-.678-.674h-.006a.672.672 0 0 0-.674.674c0 .372.306.674.68.674M14.251 34.365a.676.676 0 0 0 .678-.673.676.676 0 0 0-.678-.674h-.006a.672.672 0 0 0-.674.674c0 .372.306.673.68.673M16.284 33.692a.676.676 0 0 0 .678-.674.676.676 0 0 0-.678-.674h-.006a.672.672 0 0 0-.674.674c0 .372.306.674.68.674'
          fill='#FCC74B'
        />
        <mask id='Idoso_svg__d' fill='#fff'>
          <use xlinkHref='#Idoso_svg__c' />
        </mask>
        <path
          d='M8.132 18.193h4.066v-1.347H8.132v1.347zm8.132 0h4.066v-1.347h-4.066v1.347zm-1.355 0v.674c0 .373.303.674.678.674h5.421a.675.675 0 0 0 .678-.674v-2.695a.675.675 0 0 0-.678-.674h-5.421a.675.675 0 0 0-.678.674v.674h-1.355v-.674a.675.675 0 0 0-.678-.674H7.454a.675.675 0 0 0-.677.674v2.695c0 .373.303.674.677.674h5.422a.675.675 0 0 0 .678-.674v-.674h1.355z'
          fill='#4F2748'
          mask='url(#Idoso_svg__d)'
        />
      </g>
    </g>
  </svg>
);

export default SvgIdoso;
