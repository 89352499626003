import * as React from 'react';
import { Field, FieldPropsInterface, FieldStateInterface } from '@components/obj.form';
import { TextFieldMaskedStyled, TextFieldStyled } from './text-field.component.style';

export interface TextFieldMaskOptionsProps {
  format?: string;
  mask?: string;
}

export interface TextFieldProps extends FieldPropsInterface, React.InputHTMLAttributes<HTMLInputElement> {
  options?: TextFieldMaskOptionsProps;
}

export class TextField extends Field<TextFieldProps, FieldStateInterface> {
  private innerRef;

  constructor(props: TextFieldProps) {
    super(props);
    this.state = { value: '', errors: [] };
  }

  focus() {
    this.innerRef.focus();
  }

  componentDidMount() {
    if (this.props.defaultValue) {
      this.setState({ value: this.props.defaultValue as string });
      if (this.props.onValueChange) {
        this.props.onValueChange(this.props.defaultValue);
      }
    }
  }

  componentDidUpdate(prevProps: TextFieldProps) {
    if (prevProps.value !== this.props.value && this.props.value !== this.state.value) {
      if (this.props.onValueChange) {
        this.props.onValueChange(this.props.value);
      }

      this.setState({ value: this.props.value as string });
    }
  }

  render() {
    const {
      attachToGroup,
      attachToForm,
      onValueChange,
      onFocusChange,
      type,
      onChange,
      value,
      options,
      defaultValue,
      ...other
    } = this.props;

    // TODO: TEMPLATE - adicionar novos tipos nativos
    if (type === 'text' || type === 'password' || type === 'email' || type === 'number') {
      return (
        <TextFieldStyled
          type={type}
          value={this.state.value || ''}
          onChange={this.changeText}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onInput={this.handleInput}
          ref={ref => (this.innerRef = ref)}
          {...other}
        />
      );
    } else {
      return (
        <TextFieldMaskedStyled
          kind={type}
          options={options}
          value={this.state.value || ''}
          onChangeText={this.changeText}
          ref={ref => (this.innerRef = ref)}
          {...other}
        />
      );
    }
  }

  private handleFocus = () => {
    if (!this.props.onFocusChange) {
      return;
    }
    this.props.onFocusChange(true, this.state.value);
  };

  private handleBlur = () => {
    if (!this.props.onFocusChange) {
      return;
    }
    this.props.onFocusChange(false, this.state.value);
  };

  private handleInput = event => {
    const nextValue = event.currentTarget.value;
    const currentValue = this.state.value;

    // HACK: "not fired by keyboard" means that who is calling onInput
    // is not the user by typing letters on the keyboard.
    // For instance, it can be fired by pasting some value or
    // by using form auto-complete.
    // Why is this necessary? auto-complete doesn't fire onChange event
    // but it fires onInput.
    // If you don't handle onInput, some bugs may appear if you use
    // auto-complete on Chrome iOS
    const notFiredByKeyboardTyping = (nextValue.length || 0) - (currentValue.length || 0) > 1;

    if (notFiredByKeyboardTyping) {
      event.preventDefault();
      this.changeText(nextValue);
    }
  };

  private changeText = (eventOrText: string | React.FormEvent<HTMLInputElement>) => {
    let value: string;

    if (typeof eventOrText === 'string') {
      value = eventOrText;
    } else {
      value = (eventOrText as React.FormEvent<HTMLInputElement>).currentTarget.value;
    }

    if (this.state && value === this.state.value) {
      return;
    }

    this.setState({ value });

    if (this.props.onValueChange) {
      this.props.onValueChange(value);
    }
  };
}
