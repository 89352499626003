import { LinkButton } from '@components/atm.button';
import { InputCaption } from '@components/atm.typography';
import { Color, Spacing } from '@components/obj.constants';
import styled from 'styled-components';

export const FooterStyled = styled.footer`
  padding: ${Spacing.XXXLarge} 0 0 0;
  background-color: ${Color.White};
`;

export const FooterLinkStyled = styled(LinkButton)`
  margin-left: ${Spacing.XXLarge};
  float: right;
`;

export const FooterPartenersStyled = styled.img`
  display: inline-block;
  height: 48px;
  vertical-align: bottom;
  margin-top: ${Spacing.Small};
  margin-left: ${Spacing.Small};
`;

export const FooterTaqtileSectionStyled = styled.section`
  overflow: hidden;
  margin-top: ${Spacing.Small};
  background-color: ${Color.LightPink};
  padding: ${Spacing.Small} 0 0 0;
`;

export const FooterPoweredFontStyled = styled(InputCaption)`
  color: ${Color.Primary};
  text-align: center;
  display: block;
  margin-top: 0;
  margin-right: ${Spacing.Small};
`;
