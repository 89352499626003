/**
 * The values of this enum should be the as the ones in the backend
 * @see br-gsk-vacinas-server/app.api/src/api/graphql/graphql-schema/vaccine/type/vaccine.type.ts
 */
export enum VaccinePriority {
  Other = 1,
  Special = 2,
}

export const mapVaccinePriorityToString = (priority: VaccinePriority) => {
  const dict: Record<VaccinePriority, string> = {
    [VaccinePriority.Special]: 'Vacinas especialmente recomendadas',
    [VaccinePriority.Other]: 'Outras vacinas recomendadas',
  };
  return dict[priority];
};
