import styled from 'styled-components';

export const PlaceholderStyled = styled.div`
  display: block;
  text-align: center;
`;

export const PlaceholderImageStyled = styled.img`
  width: 140px;
  height: 140px;
`;
