/**
 * Service to handle GSK Map
 */
export class GskMapsService {
  constructor(readonly url: string) {
    this.url = url;
  }
}

export enum MapEvents {
  /** when administration places is clicked on the map (former: "mapa_item") */
  ItemMap = 'gsk_map-map_marker_click',

  /** when user clicks on filter "All" (on the segmented control) (former: "filtro_todos") */
  AllFilter = 'gsk_map-all_filter_click',

  /**
   * when user clicks on filter "Public" (a.k.a SUS) (on the segmented control)
   * (former: "filtro_postos")
   */
  PublicFilter = 'gsk_map-public_filter_click',

  /** when user clicks on filter "Private" (a.k.a clínicas) (on the segmented control) (former: "filtro_clinicas") */
  ClinicFilter = 'gsk_map-private_filter_click',

  /**
   * when user clicks on filter "Home Vaccination" (a.k.a "a domicílio") (on the
   * segmented control) (former: "filtro_domicilio")
   */
  HomeVaccinationFilter = 'gsk_map-home_vaccination_filter_click',

  /** when user performs a search (former: "pesquisar_cep") */
  SearchCep = 'gsk_map-search',

  /** when the component is shown (former: "pageView") */
  PageView = 'gsk_map-component_did_mount',

  /** when user clicks on "send email" (former: "centros_enviar_lista_email") */
  SendEmailList = 'gsk_map-send_email_click',

  /** when user clicks on filter "Shingrix" (on the segmented control) */
  ShingrixFilter = 'gsk_map-shingrix_filter_click',

  /** when administration places are cleared (former: "clear_places") */
  ClearPlaces = 'gsk_map-clear_places',

  /**
   * when administration places is selected (ou de-selected) on the list and/or map (former: "place_clicked")
   */
  PlaceClicked = 'gsk_map-place_selection_toggle',

  /**
   * when administration places is de-selected (user clicks on "CHECK" icon) on
   * the MAP (former: "mapa_item_remover")
   */
  RemoveItemMap = 'gsk_map-place_deselected_on_map',

  /**
   * when administration places is selected (user clicks on "PLUS" icon) on the
   * MAP (former: "item_adicionar")
   */
  AddItemMap = 'gsk_map-place_selected_on_map',

  /**
   * when administration places is de-selected (user clicks on "CHECK" icon) on
   * the LIST (former: "lista_item_remover")
   */
  RemoveListItem = 'gsk_map-place_deselected_on_list',

  /**
   * when administration places is selected (user clicks on "PLUS" icon) on the
   * LIST (former: "lista_item_adicionar")
   */
  AddListItem = 'gsk_map-place_selected_on_list',

}

export type MapEventsDetail = AddItemMapEventDetails | AddListItemEventDetails |
  RemoveItemMapEventDetails | SearchCepEventDetails |
  SendSendEmailListEventDetails | ItemMapEventDetails | PlaceClickedEventDetails;

export interface SearchCepEventDetails {
  searchAddress: string;
}

export interface RemoveItemMapEventDetails {
  id: string;
}

export interface AddItemMapEventDetails {
  id: string;
}

export interface AddListItemEventDetails {
  id: string;
}

export interface SendSendEmailListEventDetails {
  body: string;
}

interface AdministrationPlaceDetails {
  id: string;
  name: string;
  email: string | null;
  latitude: number;
  longitude: number;
  phoneNumber: string | null;
  type: 'PUBLIC' | 'PRIVATE';
  street: string | null;
  complement: string | null;
  neighborhood: string | null;
  zipCode: string | null;
  homeVaccination: boolean | null;
  city: string | null;
  state: string | null;
}

export type ItemMapEventDetails = AdministrationPlaceDetails;
export type PlaceClickedEventDetails = AdministrationPlaceDetails;
