import guard from '@app/modules/authentication/guard.hoc';
import { HeaderSmart } from '@app/components//header.smart-component';
import { Observer } from '@app/core/decorator/observer.decorator';
import { ApplicationStore } from '@app/modules/app/application.store';
import { APPOINTMENT_PATH, AppointmentRoute } from '@app/modules/appointment/appointment.route';
import { AuthenticationStore } from '@app/modules/authentication/authentication.store';
import { ChangePasswordRoute } from '@app/modules/authentication/change-password.route';
import { Guarded } from '@app/core/decorator/guarded.decorator';
import * as React from 'react';
import { Redirect, Switch } from 'react-router';
import { Container } from 'typedi';
import { UpdateUserRoute } from '../authentication/update-user.route';
import { ResetDoctorPasswordRoute } from './reset-doctor-password.route';
import { ResetDoctorEmailRoute } from './reset-email-password.route';
import { FetchUserDataQuery } from './fetch-user.data.query';
import { OptOutUserRoute } from './opt-out-user.route';

@Observer()
@Guarded(guard)
export class GuardContainerView extends React.Component {
  private applicationStore?: ApplicationStore = Container.get(ApplicationStore);
  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);

  render() {
    return (
      <div>
        {this.renderHeader()}
        <FetchUserDataQuery>
          <Switch>
            {ChangePasswordRoute}
            {ResetDoctorPasswordRoute}
            {ResetDoctorEmailRoute}
            {OptOutUserRoute}
            {UpdateUserRoute}
            {AppointmentRoute}
            <Redirect from='*' to={APPOINTMENT_PATH} />
          </Switch>
        </FetchUserDataQuery>
      </div>
    );
  }

  private renderHeader = () => {
    return (
      <HeaderSmart
        selectedItem={this.applicationStore.currentMenuItem}
        home={false}
        logged={this.authenticationStore.loggedIn}
      />
    );
  };
}
