import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Color, Spacing } from '@components/obj.constants';
import { InputValue } from '@components/atm.typography';
import { faCircle as faSolidCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

const radioSize: number = 24;

export const RadioFieldStyled = styled.span`
  display: flex;
  position: relative;
  direction: row;
  margin: ${Spacing.Small} 0;
`;

export const RadioFieldTextStyled = styled(InputValue)`
  padding-left: 32px;
  line-height: ${radioSize}px;
  z-index: 2;
`;

export const RadioCheckedStyled = styled(FontAwesomeIcon).attrs({ icon: faSolidCircle })`
  position: absolute;
  font-size: 14px;
  color: ${Color.Accessory};
  z-index: 1;
  opacity: 0;
  left: 5px;
  top: 5px;
`;

export const RadioUncheckedStyled = styled(FontAwesomeIcon).attrs({ icon: faCircle })`
  position: absolute;
  font-size: ${radioSize}px;
  z-index: 0;
  color: ${Color.GrayXDark};
`;

export const RadioFieldBulletStyled = styled.input`
  display: none;

  &:checked + ${RadioCheckedStyled} {
    opacity: 1;
  }
`;
