import styled from 'styled-components';
import { TooltipTextStyled } from '@components/mol.tooltip/tooltip.component.style';
import { Grid, Row } from '@components/obj.grid';
import { Color, FontFamily, FontWeight, Spacing } from '@components/obj.constants';

export const TooltipStyled = styled(TooltipTextStyled)`
  top: -14%;
  left: 2.5%;
`;

export const RecommendationVaccineGrid = styled(Grid)`
  padding-left: 0;
  padding-right: 0;
  @media all and (min-width: 48em) {
    padding-left: ${Spacing.XXLarge};
    padding-right: ${Spacing.XXLarge};
  }

  @media print {
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
  }
`;

export const RecommendationNotesAreaRow = styled(Row)`
  @media print {
    display: block;
  }
  page-break-inside: avoid;
`;

export const RecommendationNotesArea = styled.div`
  margin-top: ${Spacing.Medium};
  border-radius: 12px;
  border: 2px solid ${Color.GrayLight};
  height: 200px;
  width: 100%;
  display: table;
  @media print and (max-width: 148mm) {
    height: 100px;
  }
`;

export const RecommendationNotesPlaceholderText = styled.p`
  display: table-cell;
  margin: 0;
  padding: ${Spacing.Large};
  color: ${Color.GrayLight};
  font-size: 2vw;
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Bold};
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;

  @media print {
    display: none;
  }
`;
