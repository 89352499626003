import { Progress } from '@components/atm.progress';
import * as React from 'react';
import { CheckoutStepperStyled } from './checkout-stepper.component.style';

export interface CheckoutStepperProps {
  step?: number;
  onMobile?: boolean;
  onClick?: (currentStep: number) => void;
}

export const CheckoutStepper = (props: CheckoutStepperProps) => {
  return (
    <CheckoutStepperStyled>
      <Progress {...props} />
    </CheckoutStepperStyled>
  );
};
