import styled from 'styled-components';
import { Color, Spacing } from '@components/obj.constants';

export const ListContainerStyled = styled.div`
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  background-color: ${Color.White};
  border-radius: ${Spacing.Small};
  margin-top: ${Spacing.XXLarge};
`;
