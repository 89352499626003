import styled from 'styled-components';
import { Color, Spacing } from '@components/obj.constants';

export const LineSeparatorStyled = styled.div`
  border: 1px solid ${Color.GrayLight};
  position: relative;
  margin-top: ${Spacing.XLarge};
  margin-bottom: ${Spacing.XLarge};
  height: 1px;
  width: 100%;
  background-color: ${Color.GrayLight};
  @media print and (max-width: 148mm) {
    border: 0;
    height: 0;
    margin-top: ${Spacing.XLarge};
    margin-bottom: 0;
  }
`;
