import * as React from 'react';

const SvgIcon = props => (
  <svg viewBox='0 0 32 32' {...props}>
    <defs>
      <path id='favicon_svg__a' d='M0 .403h14.872v16.774H0z' />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <rect fill='#BA54A1' width={32} height={32} rx={8} />
      <g transform='translate(6 8.429)'>
        <mask id='favicon_svg__b' fill='#fff'>
          <use xlinkHref='#favicon_svg__a' />
        </mask>
        <path
          d='M12.842 16.237c-2.217 1.604-5.325 1.103-6.918-1.115L.918 8.153a4.882 4.882 0 0 1 1.112-6.81C4.248-.261 7.356.24 8.95 2.458l5.005 6.969a4.882 4.882 0 0 1-1.112 6.81'
          fillOpacity={0.4}
          fill='#FFF'
          mask='url(#favicon_svg__b)'
        />
      </g>
      <path
        d='M13.034 24.672c2.217 1.604 5.325 1.103 6.918-1.115l7.007-9.807a4.882 4.882 0 0 0-1.113-6.81c-2.217-1.604-5.324-1.103-6.918 1.114l-7.007 9.807a4.882 4.882 0 0 0 1.113 6.81'
        fillOpacity={0.6}
        fill='#FFF'
      />
      <path
        d='M19.694 23.929c-.053.057-.103.116-.155.172a6.09 6.09 0 0 1-.44.409 4.787 4.787 0 0 1-.538.381.204.204 0 0 1-.07.047c-.103.06-.21.116-.314.172a6.495 6.495 0 0 1-.323.149.29.29 0 0 1-.089.032c-.093.038-.182.07-.276.098a.09.09 0 0 1-.056.019c-.112.037-.23.07-.346.097a8.65 8.65 0 0 1-.37.075 5.9 5.9 0 0 1-.38.042 4.915 4.915 0 0 1-3.29-.921c-.023-.014-.042-.033-.06-.051-.076-.06-.155-.121-.23-.182a4.92 4.92 0 0 1-.45-.432c-.098-.112-.187-.228-.276-.345l-.046-.055a.186.186 0 0 1-.047-.056l-.113-.154c-1.08-1.665-1.095-3.85.113-5.535.805-1.122 2.14-2.993 4.007-5.614l4.025 5.605a4.878 4.878 0 0 1 0 5.702c-.089.121-.178.238-.276.345z'
        fill='#FFF'
      />
      <path
        d='M13.723 22.22s.699 1.1 2.295 1.1 2.095-1.1 2.095-1.1'
        stroke='#AF53A6'
        strokeWidth={0.846}
        strokeLinecap='round'
      />
    </g>
  </svg>
);

export default SvgIcon;
