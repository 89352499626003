import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Color, FontFamily, FontSize, FontWeight, Spacing } from '@components/obj.constants';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const radioSize: number = 150;

export const CustomRadioFieldStyled = styled.span`
  position: relative;

  @media all and (min-width: 48em) {
    display: inline-block;
    border-bottom: 0;
    background-color: transparent;
    padding: 0;
    position: relative;
    margin-right: ${Spacing.XXLarge};
    margin-bottom: ${Spacing.XLarge};
    align-items: center;
  }
`;

export const CustomRadioFieldTextStyled = styled.label`
  appearance: none;
  cursor: pointer;
  background-color: ${Color.White};
  position: relative;

  display: block;
  padding: ${Spacing.Large};
  margin-bottom: ${Spacing.Small};
  border-bottom: 1px solid ${Color.GrayLight};

  @media all and (min-width: 48em) {
    display: inline-block;
    padding: 0;
    margin-bottom: 0;
    border: 1px solid ${Color.Gray};

    width: ${radioSize}px;
    height: ${radioSize}px;
    border-radius: ${radioSize / 2}px;
  }
`;

export enum CustomRadioSpanKind {
  Text,
  Age,
  Range,
}
const getMarginTopForCustomRadioSpanKind = (props: { kind: CustomRadioSpanKind }): string => {
  switch (props.kind) {
    case CustomRadioSpanKind.Age:
      return '28px';
    case CustomRadioSpanKind.Text:
      return '23px';
    case CustomRadioSpanKind.Range:
      return '36px';
  }
};
const getFontSizeForCustomRadioSpanKind = (props: { kind: CustomRadioSpanKind }): string => {
  switch (props.kind) {
    case CustomRadioSpanKind.Age:
      return FontSize.XXLarge;
    case CustomRadioSpanKind.Text:
      return '41px';
    case CustomRadioSpanKind.Range:
      return '46px';
  }
};
const getLineHeightForCustomRadioSpanKind = (props: { kind: CustomRadioSpanKind }): number => {
  switch (props.kind) {
    case CustomRadioSpanKind.Text:
      return 1.5;
    default:
      return 1;
  }
};

export const CustomRadioSpanStyled = styled.span<{ kind: CustomRadioSpanKind }>`
  color: ${Color.Secondary};

  font-family: ${FontFamily.Secondary};
  font-size: ${FontSize.Large};

  @media all and (min-width: 48em) {
    text-align: center;
    width: 100%;
    align-self: center;
    font-weight: ${FontWeight.Bold};
    display: block;
    margin-top: ${getMarginTopForCustomRadioSpanKind};
    font-size: ${getFontSizeForCustomRadioSpanKind};
    line-height: ${getLineHeightForCustomRadioSpanKind};
  }
`;

export const CustomRadioMonthSpanStyled = styled.span`
  font-family: ${FontFamily.Secondary};
  font-weight: ${FontWeight.Normal};
  font-size: ${FontSize.Large};
  color: ${Color.Secondary};

  margin-left: ${Spacing.XSmall};

  @media all and (min-width: 48em) {
    margin-left: 0;
    text-align: center;
    width: 100%;
    align-self: center;
    font-size: ${FontSize.XLarge};
    display: inline-block;
    line-height: 1;
  }
`;

export const CustomRadioCheckedStyled = styled(FontAwesomeIcon).attrs({ icon: faCheck })`
  display: none;
  @media all and (min-width: 48em) {
    display: block;
    position: absolute;
    font-size: 35px;
    color: ${Color.White};
    z-index: 1;
    opacity: 0;
    right: 0;
    top: 5px;
    border: 1px solid ${Color.Success};
    border-radius: 25px;
    background-color: ${Color.Success};
    padding: 7px;
  }
`;

export const CustomRadioFieldBulletStyled = styled.input`
  display: none;

  @media all and (min-width: 48em) {
    &:checked + ${CustomRadioFieldTextStyled} {
      border-width: 0;
      color: ${Color.White};
      background-color: ${Color.Secondary};

      ${CustomRadioCheckedStyled} {
        opacity: 1;
      }

      ${CustomRadioMonthSpanStyled} {
        color: ${Color.White};
      }

      ${CustomRadioSpanStyled} {
        color: ${Color.White};
      }
    }
  }
`;

export const CustomRadioFieldChevronStyled = styled.i`
  display: none;
  @media all and (max-width: 48em) {
    display: block;
    /* https://stackoverflow.com/a/24651693/3670829 */
    position: absolute;
    right: 28px;
    top: 24px;
    display: block;
    height: 50px; /*height should be double border*/
    ::before,
    ::after {
      position: absolute;
      display: block;
      content: '';
      border: 8px solid transparent; /*adjust size*/
    }
    ::before {
      left: 0;
      border-left-color: ${Color.Gray}; /*Chevron Color*/
    }
    ::after {
      left: -2px; /*adjust thickness*/
      border-left-color: #fff; /*Match background colour*/
    }
  }
`;
