import { Age } from '@app/model/age.model';
import { VaccineFamily } from '@app/model/vaccine-family.model';
import { Vaccine } from '@app/model/vaccine.model';
import { VaccineInfoStyled, VaccineTooltipStyled } from '@app/modules/appointment/vaccine-choice-by-age.style';
import { LinkButton } from '@components/atm.button';
import { Body, Display, H1, H2 } from '@components/atm.typography';
import { LoadingWrapper } from '@components/mol.loading-wrapper';
import { Tooltip } from '@components/mol.tooltip';
import { VaccineListRootStyled } from '@components/mol.vaccine-list/vaccine-list.component.style';
import { VacineItem, VacineItemGroup } from '@components/mol.vacine-item';
import { Col, Grid, Row } from '@components/obj.grid';
import { Else, If } from '@components/obj.if';
import { ListContainer } from '@components/obj.list-container';
import { Modal } from '@components/obj.modal';
import { RelativeContainer } from '@components/obj.relative-container';
import * as React from 'react';

export interface VaccineWithTooltip extends Vaccine {
  tooltip?: JSX.Element;
}

export interface VaccineChoiceProps {
  title: string;
  vaccines: VaccineWithTooltip[];
  loading: boolean;
  // the title of the "more instructions" button
  vaccineInstructionsTitle: string;
  showVaccineChoiceChoiceTooltip: boolean;
  selectedIndex: number;
  onVaccineClick?: (item: Vaccine, index?: number) => void;
  onOnboardingTooltipClick?: () => void;
}

export interface VaccineChoiceState {
  showModal: boolean;
}

export class VaccineList extends React.Component<VaccineChoiceProps, VaccineChoiceState> {
  constructor(props: VaccineChoiceProps) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  render() {
    const vaccines = this.props.vaccines;

    const showOnboardingTooltip = this.showOnboardingTooltip();
    return (
      <>
        <VaccineListRootStyled>
          <Grid fluid={true}>
            <Row>
              <Col xs={12}>
                <RelativeContainer>
                  <H1>
                    {this.props.title}
                    <LinkButton onClick={this.showModal}> {this.props.vaccineInstructionsTitle}</LinkButton>
                  </H1>
                  <If cond={!this.props.loading}>
                    <If cond={vaccines.length > 0}>

                      <Body> Selecione a vacina recomendada </Body>
                      <ListContainer>
                        <VaccineTooltipStyled>
                          <Tooltip
                            opened={showOnboardingTooltip}
                            onClose={this.handleOnboardingTooltipCloseClick}
                            message={'Selecione a vacina recomendada.'}
                          />
                        </VaccineTooltipStyled>
                        <VacineItemGroup
                          name='myGroup'
                          onValueChange={this.handleVaccineCheck}
                          value={this.props.selectedIndex}
                        >
                          {vaccines.map((vaccine, index) => {
                            return (
                              <VacineItem
                                key={vaccine.id}
                                name={vaccine.id}
                                value={index}
                                title={vaccine.name}
                                description={vaccine.description}
                                mouthDropCount={vaccine.mouthDropCount}
                                injectionCount={vaccine.injectionCount}
                                hasPublic={vaccine.isAdministeredOnPublicClinics}
                                hasPrivate={vaccine.isAdministeredOnPrivateClinics}
                                brand={vaccine.brand}
                                laboratory={vaccine.laboratory}
                                comment={vaccine.comment}
                                tooltip={vaccine.tooltip}
                              />
                            );
                          })}
                        </VacineItemGroup>
                      </ListContainer>
                      <Else />
                      <H2>Não há vacinas a serem tomadas para esta família!</H2>
                    </If>
                    <Else />
                    <Row center='xs'>
                      <Col>
                        <LoadingWrapper type='boy-gif-clean' />
                      </Col>
                    </Row>
                  </If>
                </RelativeContainer>
              </Col>
            </Row>
          </Grid>
        </VaccineListRootStyled>
        {vaccines.length > 0 && (
          <Modal opened={this.state.showModal} onClose={this.closeModal} maxHeight='60vh'>
            <Grid fluid={true}>
              <Row mb={true} align='center'>
                <Col xs={12}>
                  <Display>Observações importantes</Display>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <VaccineInfoStyled>
                    {/* https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: vaccines[0].comment,
                      }}
                    />
                  </VaccineInfoStyled>
                </Col>
              </Row>
            </Grid>
          </Modal>
        )}
      </>
    );
  }

  private showModal = () => {
    this.setState({ showModal: true });
  };

  private closeModal = () => {
    this.setState({ showModal: false });
  };

  private showOnboardingTooltip = () => {
    return typeof this.props.showVaccineChoiceChoiceTooltip === 'undefined'
      ? true
      : this.props.showVaccineChoiceChoiceTooltip;
  }

  private handleVaccineCheck = index => {
    if (this.props.onVaccineClick) {
      this.props.onVaccineClick(this.props.vaccines[index], index);
    }
  };

  private handleOnboardingTooltipCloseClick = () => {
    if (this.props.onOnboardingTooltipClick) {
      this.props.onOnboardingTooltipClick();
    }
  };

}
