import { Body, H3 } from '@components/atm.typography';
import { Separator } from '@components/obj.grid';
import * as React from 'react';
import { HowItWorksCellStyled } from './how-it-works-cell.style';

// tslint:disable-next-line: no-empty-interface
export interface HowItWorksCellProps {
  img: JSX.Element;
  title: string;
  description: string;
}

export class HowItWorksCell extends React.Component<HowItWorksCellProps, any> {
  constructor(props: HowItWorksCellProps) {
    super(props);
  }

  render() {
    return (
      <HowItWorksCellStyled>
        {this.props.img}
        <Separator />
        <Separator />
        <H3 center={true}>{this.props.title}</H3>
        <Body center={true}>{this.props.description}</Body>
      </HowItWorksCellStyled>
    );
  }
}
