import styled from 'styled-components';
import { BodyStyle, H2Style } from '@components/atm.typography';
import { Color, FontFamily, FontSize, FontWeight, Spacing } from '@components/obj.constants';

export const VaccineTooltipStyled = styled.div`
  top: -175px;
  left: 2.5%;
  position: absolute;
  width: 100%;
`;

export const FamilyTooltipStyled = styled.div`
  top: -250px;
  left: 10%;
  position: absolute;
  width: 90%;
`;

export const VaccineInfoStyled = styled.div`
  word-break: break-word;

  p {
    ${BodyStyle}
  }

  p + p {
    margin-top: ${Spacing.Large};
  }

  h2 {
    ${H2Style}
  }

  a {
    font-family: ${FontFamily.Primary};
    font-weight: ${FontWeight.Bold};
    font-size: ${FontSize.Medium};
    cursor: pointer;
    color: ${Color.Accessory};
    text-decoration: underline;

    &:hover {
      color: ${Color.Secondary};
    }
  }
`;
