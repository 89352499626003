import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Color, Spacing } from '../obj.constants';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

interface CarouselStyledProps {
  showNavigationButton?: boolean;
  disabled?: boolean;
  minHeight?: number;
}

export const CarouselStyled = styled.div`
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  min-height: ${(props: CarouselStyledProps) => `${props.minHeight}px` || 0};
`;

export const CarouselWrapperStyled = styled.div`
  overflow-x: hidden;
  overflow-y: hidden;
  ${(props: CarouselStyledProps) =>
    props.showNavigationButton &&
    `
    position: relative;
  `}
`;

interface CarouselNavigationButtonStyledProps {
  additionalarrowtranslation: number;
  additionalpadding: number;
  disabled: boolean;
  onClick?: () => void;
  name?: string;
}

export const CarouselNavigationButtonStyled = styled(FontAwesomeIcon).attrs({
  icon: faAngleDown,
  size: '3x',
})<CarouselNavigationButtonStyledProps>`
  box-sizing: content-box;
  position: absolute;
  padding: ${props => (props.additionalpadding || 0) + parseInt(Spacing.XLarge, 0)}px;
  top: 50%;
  z-index: 80;
  color: ${props => (props.disabled ? Color.GrayLight : Color.White)};
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.3));

  &:first-child {
    left: ${-parseInt(Spacing.XLarge, 0)}px;
    transform: ${props => `translateY(${(props.additionalarrowtranslation || 0) - 50}%)`} rotate(90deg) scale(0.8);
    @media all and (min-width: 48em) {
      transform: ${props => `translateY(${(props.additionalarrowtranslation || 0) - 50}%)`} rotate(90deg);
    }
  }

  &:last-child {
    right: ${-parseInt(Spacing.XLarge, 0)}px;
    transform: ${props => `translateY(${(props.additionalarrowtranslation || 0) - 50}%)`} rotate(-90deg) scale(0.8);
    @media all and (min-width: 48em) {
      transform: ${props => `translateY(${(props.additionalarrowtranslation || 0) - 50}%)`} rotate(-90deg);
    }
  }
`;
