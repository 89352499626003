import * as React from 'react';
import { LeftDropBackground, RightDropBackground } from './drop.style';

export interface DropProps {
  reverse: boolean;
}

export class Drop extends React.Component<DropProps, any> {
  constructor(props: DropProps) {
    super(props);
  }

  render() {
    const reverse = this.props.reverse;
    const img = 'assets/img/img_gota.svg';
    return reverse ? <RightDropBackground src={img} /> : <LeftDropBackground src={img} />;
  }
}
