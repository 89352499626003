import * as React from 'react';
import { Route } from 'react-router';
import { ChangePasswordData } from '@app/modules/authentication/change-password.data';
import { SEO, SEOConfig } from '@app/core/hoc/seo.hoc';

export const CHANGE_PASSWORD_PATH = '/alterar-senha';

const config: SEOConfig = {
  title: 'Vacina Certa - Alterar senha',
  robots: 'noindex',
  meta: [
    {
      name: 'description',
      content: 'Altere sua senha no Vacina Certa.',
    },
  ],
};
const ChangePassword = SEO(config)(ChangePasswordData);

export const ChangePasswordRoute = <Route path={CHANGE_PASSWORD_PATH} exact={true} component={ChangePassword} />;
