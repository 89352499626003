import { PropsBase } from '@app/core/base/props.base';
import { GraphQL } from '@app/core/decorator/graphql.decorator';
import { Observer } from '@app/core/decorator/observer.decorator';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { Button } from '@components/atm.button';
import { Body, H3 } from '@components/atm.typography';
import { BooleanContainer } from '@components/obj.abstract-container';
import { Col, Grid, Row, Separator } from '@components/obj.grid';
import { Modal } from '@components/obj.modal';
import * as React from 'react';
import { Container } from 'typedi';
import { AuthenticationStore, DELETE_USER_MAP_KEY } from './authentication.store';

@GraphQL('delete-user-data.mutation')
@Observer()
export class DeleteUserDataMutation extends React.Component<Partial<PropsBase>> {

  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);
  private analyticsService: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);

  constructor(props) {
    super(props);
  }

  render() {
    const loading = this.authenticationStore.loadingMap.get(DELETE_USER_MAP_KEY);
    return (
      <BooleanContainer defaultValue={false}>
        {modalBool => {
          const hideModal = modalBool.setFalse;
          const openModal = modalBool.setTrue;
          return (
            <>
              <Button
                kind='alert'
                expanded={true}
                loading={loading}
                onClick={openModal}
              >
                Deletar conta
              </Button>
              <ConfirmModal
                open={modalBool.value}
                loading={loading}
                onClose={hideModal}
                onConfirm={this.callMutation}
              />
            </>
          );
        }}
      </BooleanContainer>
    );
  }

  private callMutation = () => {
    this.analyticsService.event('deletar_usuario', 'click', true);
    return this.authenticationStore.deleteUserData((this.props).mutate);
  };
}

////////////////////////////////////////////////////////////////////////////////////////////////////

interface ConfirmModalProps {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmModal: React.FunctionComponent<ConfirmModalProps> = props => {
  return (
    <Modal noGutter={true} small={true} opened={props.open} onClose={props.onClose}>
      <Grid fluid={true}>
        <Row mb={true}>
          <Col xs={12}>
            <Separator />
            <H3>Você tem certeza?</H3>
            <Body>Você tem certeza que deseja deletar seus dados? Essa ação é irreversível.</Body>
          </Col>
        </Row>
        <Row mb={true}>
          <Col xs={12}>
            <Button
              expanded={true}
              onClick={props.onConfirm}
              kind='alert'
              loading={props.loading}
            >
              Deletar
            </Button>
          </Col>
        </Row>
        <Row mb={true}>
          <Col xs={12}>
            <Button expanded={true} onClick={props.onClose} kind='secondary'>
              Cancelar
            </Button>
          </Col>
        </Row>
      </Grid>
    </Modal>
  );
};
