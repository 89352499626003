import { AppIcon } from '@components/atm.app-icon';
import { Else, ElseIf, If } from '@components/obj.if';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import * as React from 'react';
import { LoadingStyled } from './loading.component.style';

export interface LoadingProps {
  type: IconDefinition | 'boy-gif' | 'boy-gif-clean';
  size?: number;
}

export const Loading = (props: LoadingProps) => {
  return (
    <If cond={props.type !== 'boy-gif' && props.type !== 'boy-gif-clean'}>
      <LoadingStyled fontSize={props.size} icon={props.type as IconDefinition} spin={true} />
      <ElseIf cond={props.type === 'boy-gif-clean'} />
      <AppIcon.LoadingCleanGif />
      <Else />
      <AppIcon.LoadingGif {...props} />
    </If>
  );
};
