export enum BrandColor {
  DarkPurple = '#4F2748',
  Purple = '#BA54A1',
  Yellow = '#FCC74B',
  Red = '#DA4453',
  Pink = '#DD6386',
  Green = '#00C889',
  Beige = '#FEF5E2',
  LightPurple = '#FBF6FA',
}

// We used class rather than enums since enums don't support computed values
export class Color {
  public static readonly Primary = BrandColor.Purple;
  public static readonly Secondary = BrandColor.DarkPurple;
  public static readonly CallToAction = BrandColor.Yellow;
  public static readonly Alert = BrandColor.Red;
  public static readonly Warning = BrandColor.Yellow;
  public static readonly Success = '#00C889';
  public static readonly Accessory = BrandColor.Pink;
  public static readonly Info = BrandColor.Pink;
  public static readonly Neutral = 'gray';

  public static readonly Black = 'black';
  public static readonly White = 'white';

  public static readonly GrayXLight = '#F2F2F2';
  public static readonly GrayLight = '#E5E5E5';
  public static readonly Gray = '#B3B3B3';
  public static readonly GrayDark = '#686868';
  public static readonly GrayXDark = '#333333';

  public static readonly LightPink = '#FBF6FA';
}

export enum FontFamily {
  Primary = 'Linotte-SemiBold, "Helvetica Neue", Helvetica, sans-serif',
  Secondary = 'Lato, "Helvetica Neue", Helvetica, sans-serif',
}

export enum FontWeight {
  Bolder = 'bolder',
  Bold = 'bold',
  Normal = 'normal',
  Lighter = 'lighter',
}

export enum FontSize {
  XSmall = '12px',
  Small = '16px',
  Medium = '20px',
  Large = '24px',
  XLarge = '32px',
  XXLarge = '56px',
}

export enum Spacing {
  XSmall = '4px',
  Small = '8px',
  Medium = '12px',
  Large = '16px',
  XLarge = '24px',
  XXLarge = '32px',
  XXXLarge = '56px',
}

export const Navbar = {
  a: '1',
};

export const PasswordLength = {
  Min: 7,
  Max: 18,
};

export const Border = {
  radius: '22px',
};
