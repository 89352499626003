import { GraphQLDocsService } from '@app/core/service/graphql-docs.service';
import { Component, ComponentState } from 'react';
import { graphql, OperationOption } from 'react-apollo';
import { Container } from 'typedi';

export type MyComponentClass<P = {}> = new (props: P, context: any, ...injectionFields) => Component<P, ComponentState>;

export function GraphQL(documentNodeName: string, operationOptions?: OperationOption<any, any>) {
  const documentNode = Container.get(GraphQLDocsService).getDocument(documentNodeName);
  return <TargetType extends MyComponentClass>(targetClass: TargetType) => {
    return graphql(documentNode, operationOptions)(targetClass) as any;
  };
}
