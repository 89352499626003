import { ProgressCell, ProgressStatus } from '@components/atm.progress';
import * as React from 'react';
import { ProgressStyled } from './progress.component.style';

export interface ProgressProps {
  step?: number;
  onMobile?: boolean;
  onClick?: (step: number) => void;
}

export const Progress = (props: ProgressProps) => {
  function status(step: number, currentStep: number): ProgressStatus {
    if (step < currentStep) {
      return 'past';
    } else if (step === currentStep) {
      return 'current';
    } else {
      return 'future';
    }
  }

  function onClick(param: number, e: Event) {
    e.stopPropagation();
    props.onClick(param);
  }

  return (
    <ProgressStyled>
      <ProgressCell number='1' text='CALENDÁRIO' status={status(1, props.step)} onClick={onClick.bind(this, 1)} />
      <ProgressCell number='2' text='VACINAS' status={status(2, props.step)} onClick={onClick.bind(this, 2)} />
      <ProgressCell number='3' text='RECOMENDAÇÃO' status={status(3, props.step)} onClick={onClick.bind(this, 3)} />
    </ProgressStyled>
  );
};
