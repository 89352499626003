/* tslint:disable:all */
import * as React from 'react';

const SvgAdolescente = props => (
  <svg width={69} height={69} {...props}>
    <g fill='none' fillRule='evenodd'>
      <circle cx={34.5} cy={34.5} r={34.5} fill='#FEF5E2' />
      <path
        d='M44.418 43.063h-4.744v-2.495a11.03 11.03 0 0 0 5.663-6.769 15.806 15.806 0 0 1 1.114 5.835.68.68 0 0 0 .678.684.68.68 0 0 0 .677-.684v-13.69c0-7.172-5.775-13.007-12.875-13.007-2.597 0-5.013.787-7.038 2.128-.009.004-.015.011-.024.014a13.034 13.034 0 0 0-5.814 10.864v6.16a.68.68 0 0 0 .677.685l.006-.001c.546-.001 1.053-.168 1.478-.45.528 3.63 2.82 6.68 5.971 8.237v2.489h-4.742c-2.616 0-4.745 2.15-4.745 4.793v6.158c0 .755.608 1.37 1.355 1.37h25.751c.748 0 1.356-.615 1.356-1.37v-6.159c0-2.642-2.129-4.792-4.744-4.792'
        fill='#4F2748'
      />
      <path
        d='M46.451 25.944v6.987a17.26 17.26 0 0 0-.774-1.59.689.689 0 0 0-.105-.185c-2.922-5.153-8.418-8.635-14.707-8.635H28.83v-6.432a11.353 11.353 0 0 1 6.1-1.782c6.352 0 11.52 5.22 11.52 11.637M23.41 31.23v-5.287c0-3.545 1.582-6.72 4.066-8.856v5.495a4.1 4.1 0 0 0-3.388 4.046v3.436c-.005.499-.277.93-.678 1.165'
        fill='#4F2748'
      />
      <path
        d='M25.443 30.742v-.678l.001-.014v-3.422c0-1.51 1.215-2.738 2.71-2.738h2.71c5.759 0 10.795 3.173 13.492 7.877-.51 4.815-4.604 8.558-9.425 8.558-5.232 0-9.488-4.299-9.488-9.583M34.93 41.694c1.18 0 2.318-.206 3.389-.567v2.464c-.195.353-1.021 1.526-3.388 1.526-2.366 0-3.193-1.173-3.389-1.526V41.14c1.068.356 2.205.555 3.389.555'
        fill='#FFF'
      />
      <path
        d='M25.444 44.432h5.023c.463.716 1.697 2.054 4.464 2.054 2.767 0 4-1.338 4.463-2.054h5.024c1.868 0 3.388 1.536 3.388 3.423v6.16h-25.75l-.001-6.159c0-1.887 1.52-3.424 3.39-3.424'
        fill='#DD6386'
      />
    </g>
  </svg>
);

export default SvgAdolescente;
