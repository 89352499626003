import { action, observable, reaction } from 'mobx';
import { Container, Service } from 'typedi';
import { AppointmentStore } from '@app/modules/appointment/appointment.store';
import { persist } from 'mobx-persist';
import { VaccineFamily } from '@app/model/vaccine-family.model';

@Service()
export class VaccineChoiceByAgeViewState {
  @persist
  @observable
  showMore: boolean = false;

  @observable
  vaccineModalOpened: boolean = false;

  @observable
  selectedVaccineFamily: VaccineFamily = null;

  @observable
  age: string;

  private reactionDisposers = [];

  private readonly appointmentStore: AppointmentStore = Container.get(AppointmentStore);

  @action
  showMoreTapped = () => {
    this.showMore = !this.showMore;
  };

  setupReaction() {
    this.reactionDisposers = [
      reaction(() => this.appointmentStore.reset, this.resetState),
      reaction(
        () => this.appointmentStore.selectedVaccinesTuple.map(v => v.vaccine.id),
        this.handleSelectedVaccinesByAgeChange,
      ),
    ];
  }

  dispose() {
    this.vaccineModalOpened = false;
    this.reactionDisposers.map(disposer => disposer());
    this.reactionDisposers = [];
  }

  private handleSelectedVaccinesByAgeChange = _data => {
    const agesOfSelectedVaccines = this.appointmentStore.selectedVaccinesTuple
      ? this.appointmentStore.selectedVaccinesTuple.map(item => item.age.value[0])
      : [];
    const hasSelectedVaccineWithAgeBelowSelectedAge =
      this.appointmentStore.selectedAge.value[0] > Math.min(...agesOfSelectedVaccines);
    this.showMore = hasSelectedVaccineWithAgeBelowSelectedAge;
  };

  private resetState = () => {
    if (this.appointmentStore.reset) {
      this.showMore = false;
    }
  };
}
