import { Color, Spacing } from '@components/obj.constants';
import styled from 'styled-components';

const triangleSize = 60;
export const CookieConsentBkgStyled = styled.div`
  position: fixed;
  z-index: 97;
  left: 0;
  bottom: 0;
	border-bottom: ${triangleSize}px solid ${Color.Primary};
	border-right: ${triangleSize}px solid transparent;
`;
export const CookieConsentButtonStyled = styled.div`
  display: flex;
  align-items: center;

  position: fixed;
  z-index: 98;
  left: 0;
  bottom: 0;

  cursor: pointer;
  padding: ${Spacing.Small};

  color: ${Color.White};
  border-radius: ${Spacing.Small} 0 0 ${Spacing.Small};
  text-transform: uppercase;
  writing-mode: vertical-lr;
  text-orientation: sideways;
`;

export const CookieConsentOptionDescriptionWrapperStyled = styled.div`
	margin-left: 50px;
`;
