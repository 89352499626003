import { Component } from 'react';

// TODO: find a way to declare returning type different from any
type Guard = (WrappedComponent: new (...args) => Component) => any;

export function Guarded(guard: Guard) {
  return (targetClass: new (...args) => Component) => {
    return guard(targetClass);
  };
}
