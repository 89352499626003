import { Router } from '@app/core/route/router';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { CONTACT_PATH } from '@app/modules/contact/contact.route';
import { TERMS_PATH } from '@app/modules/terms/terms.route';
import { Footer } from '@components/pag.home';
import * as React from 'react';
import { Container, Service } from 'typedi';

@Service()
export class FooterContainerView extends React.Component<{}> {
  private analyticsService?: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);
  private router?: Router = Container.get(Router);

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Footer
        onTermsClick={this.onTermsClick}
        onContactClick={this.onContactClick}
        onPrivacyPolicyClick={this.onPrivacyPolicyClick}
      />
    );
  }

  private onTermsClick = e => {
    this.analyticsService.event('footer_termos_de_uso', 'click');
    this.router.push(TERMS_PATH);
  };

  private onPrivacyPolicyClick = e => {
    this.analyticsService.event('footer_politicas_de_privacidade', 'click');
    e.preventDefault();
    // https://www.notion.so/taqtile/LGPD-termos-15ec7aa798784ea8be073498de9e5d07#2383f9183ab945108c21f5087c697675
    window.open('https://privacy.gsk.com/pt-br/privacy-notice/healthcare-professional/', '_blank');
  };

  private onContactClick = () => {
    this.analyticsService.event('footer_contato', 'click');
    this.router.push(CONTACT_PATH);
  };

}
