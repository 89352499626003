/* tslint:disable:all */
import * as React from 'react';

const SvgAdulto = props => (
  <svg width={69} height={69} {...props}>
    <defs>
      <path id='adulto_svg__a' d='M0 .654h28v40.69H0z' />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <circle cx={34.5} cy={34.5} r={34.5} fill='#FEF5E2' />
      <g transform='translate(21 14.003)'>
        <mask id='adulto_svg__b' fill='#fff'>
          <use xlinkHref='#adulto_svg__a' />
        </mask>
        <path
          d='M23.333 29.533l-4.666-.001v-1.313h6c1.102 0 2-.883 2-1.968V13.122C26.667 6.247 20.985.654 14 .654 7.015.654 1.333 6.247 1.333 13.122v13.129c0 1.085.898 1.968 2 1.968h6v1.314l-4.665-.001C2.094 29.532 0 31.593 0 34.127v5.904c0 .724.599 1.313 1.334 1.313h25.333c.735 0 1.333-.589 1.333-1.313v-5.905c0-2.532-2.094-4.593-4.667-4.593'
          fill='#4F2748'
          mask='url(#adulto_svg__b)'
        />
      </g>
      <path
        d='M24.333 40.91a.662.662 0 0 1-.666-.657V29.717c.226-.022.448-.056.666-.099v2.104c0 3.951 2.23 7.397 5.514 9.188h-5.514z'
        fill='#4F2748'
      />
      <path
        d='M29.117 25.79a.661.661 0 0 0 .3-.133c2.272 2.487 7.916 4.306 14.916 4.678v1.387c0 5.066-4.186 9.187-9.333 9.187s-9.333-4.12-9.333-9.187v-2.524a6.65 6.65 0 0 0 3.45-3.409M31.667 41.69c1.05.341 2.168.532 3.333.532s2.283-.19 3.333-.533v2.368c-.157.303-.913 1.447-3.333 1.447-2.425 0-3.171-1.13-3.333-1.449V41.69z'
        fill='#FFF'
      />
      <path
        d='M45.667 40.91h-5.514c3.284-1.791 5.514-5.237 5.514-9.188v-1.335c.221.006.443.01.666.013V40.254a.662.662 0 0 1-.666.656M46.333 27.125v1.962a57.957 57.957 0 0 1-1.084-.024.663.663 0 0 0-.249-.05c-.059 0-.111.02-.166.034-9.16-.39-15.167-3.21-15.167-5.86v-5.9A11.398 11.398 0 0 1 35 15.969c6.25 0 11.333 5.004 11.333 11.156M28.333 18.123v5.065c0 2.672-2.04 4.879-4.666 5.204v-1.267c0-3.698 1.844-6.97 4.666-9.002'
        fill='#4F2748'
      />
      <path
        d='M25.668 44.847h4.927c.438.7 1.615 1.97 4.405 1.97 2.791 0 3.968-1.27 4.405-1.97h4.928c1.838 0 3.334 1.473 3.334 3.282v5.905h-7.055l-5.14-5.06a.673.673 0 0 0-.943 0l-5.141 5.06h-7.054V48.13c0-1.81 1.495-3.283 3.334-3.283'
        fill='#DD6386'
      />
      <path fill='#FCC74B' d='M38.727 54.034h-7.454L35 50.365z' />
    </g>
  </svg>
);

export default SvgAdulto;
