import * as React from 'react';
import { Field, FieldPropsInterface, FieldStateInterface } from '@components/obj.form';

import {
  RadioCheckedStyled,
  RadioFieldBulletStyled,
  RadioFieldStyled,
  RadioFieldTextStyled,
  RadioUncheckedStyled,
} from './radio-field.component.style';

export interface RadioFieldSelectProps {
  selected: boolean;
}

export interface RadioFieldProps extends FieldPropsInterface {
  name?: string;
  value: any;
  checked?: boolean;
  disabled?: boolean;
}

interface RadioFieldState extends FieldStateInterface {
  checked: boolean;
}

export class RadioField extends Field<RadioFieldProps, RadioFieldState> {
  constructor(props: RadioFieldProps) {
    super(props);
    this.state = { checked: !!this.props.checked, errors: [] };
  }

  UNSAFE_componentWillReceiveProps(nextProps: RadioFieldProps) {
    if (nextProps.checked !== this.state.checked) {
      this.setState({ checked: nextProps.checked });
    }
  }

  onPress = () => {
    const state = !this.state.checked;
    this.setState({ checked: state });

    if (this.props.onValueChange) {
      this.props.onValueChange(this.props.value);
    }
  };

  render() {
    return (
      <RadioFieldStyled>
        <RadioFieldBulletStyled
          type='radio'
          name={this.props.name}
          id={this.props.name + '_' + this.props.value}
          checked={this.state.checked}
          onChange={this.onPress}
          value={this.props.value}
        />
        <RadioCheckedStyled />
        <RadioUncheckedStyled />
        <RadioFieldTextStyled htmlFor={this.props.name + '_' + this.props.value}>
          {this.props.children}
        </RadioFieldTextStyled>
      </RadioFieldStyled>
    );
  }
}
