import { AdministrationPlacesRoute } from '@app/modules/administration-places/administration-places.route';
import { ApplicationStore } from '@app/modules/app/application.store';
import { GuardContainerView } from '@app/modules/authentication';
import { AuthenticationStore } from '@app/modules/authentication/authentication.store';
import { LoginRoute } from '@app/modules/authentication/login.route';
import { UpdatePasswordRoute } from '@app/modules/authentication/update-password.route';
import { ContactRoute } from '@app/modules/contact/contact.route';
import { HomeRoute } from '@app/modules/home/home.route';
import CookieConsentDatasource, { CookieConsentDataSourceObserver } from '@app/resource/cookie-consent.datasource';
import { FlashWrapper } from '@components/obj.flash-wrapper/flash-wrapper.component';
import { GlobalStyled } from '@components/obj.globals';
import { GridProps } from '@components/obj.grid';
import { ApolloClient } from 'apollo-client';
import { create } from 'mobx-persist';
import * as React from 'react';
import { ApolloProvider } from 'react-apollo';
import { Switch } from 'react-router';
import { ThemeProvider } from 'styled-components';
import { Container } from 'typedi';
import { ShingrixRoute } from '../administration-places/shingrix/shingrix.route';
import { TermsRoute } from '../terms/terms.route';
import { MaintenanceRoute } from '../maintenance/maintenance.route';
import { GtmLoad } from './components/gtm-load.component';
import { HotjarLoad } from './components/hotjar-load.component';
import { OptOutUserRoute } from '../authentication/opt-out-user.route';

export class App extends React.Component implements CookieConsentDataSourceObserver {
  private apolloClient?: ApolloClient<any> = Container.get(ApolloClient);
  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);
  private applicationStore?: ApplicationStore = Container.get(ApplicationStore);

  private isStoreHydrated = false;

  componentDidMount() {
    CookieConsentDatasource.subscribeObserver(this);
    this.setupStoreHydration();
  }

  componentWillUnmount() {
    CookieConsentDatasource.unsubscribeObserver(this);
  }

  render() {
    return (
      <>
        <ThemeProvider theme={GridProps}>
          <ApolloProvider client={this.apolloClient as any}>
            <>
              <GlobalStyled />

              {/* PS: there are two ways of showing a flash message. You can use
            this flash wrapper, which is a gloabl flash message. You can also
            show a flash message by adding a "Notification" component in the
            context you want to use it */}
              <FlashWrapper />

              <Switch>
                {MaintenanceRoute}
                {HomeRoute}
                {LoginRoute}
                {ContactRoute}
                {AdministrationPlacesRoute}
                {ShingrixRoute}
                {UpdatePasswordRoute}
                {TermsRoute}
                <GuardContainerView />
              </Switch>
            </>
          </ApolloProvider>
        </ThemeProvider>
        <GtmLoad />
        <HotjarLoad />
      </>
    );
  }

  onFunctionalCookieChanged = () => {
    this.setupStoreHydration();
  }

  private setupStoreHydration = () => {
    if (CookieConsentDatasource.acceptedFunctional) {
      const hydrate = create();
      hydrate('authenticationStore', this.authenticationStore).then(() => {
        this.isStoreHydrated = true;
        console.log('authentication store hydrated');
      });
      hydrate('applicationStore', this.applicationStore).then(() => {
        this.isStoreHydrated = true;
        console.log('application store hydrated');
      });
    } else if (this.isStoreHydrated === true) {
      this.authenticationStore.logOut();
      this.applicationStore.cleanStore();
    }
  }
}
