/**
 * the valur of this enum must be the same as the value of the enum in the backend
 * @see br-gsk-vacinas-server/app.api/src/data/db/entity/special-need.entity.ts
 */
export enum ImmunocompromisedComorbidities {
  Diabetes = 'DIABETES',
  CardiovascularPulmonaryDisease = 'CARDIOVASCULAR_PULMONARY_DISEASE',
  ChronicHepatopathy = 'CHRONIC_HEPATOPATHY',
  ChronicKidneyDisease = 'CHRONIC_KIDNEY_DISEASE',
  SplenicDysfunctions = 'SPLENIC_DYSFUNCTIONS',
  RheumatoAndAutoimmuneDiseases = 'RHEUMATO_AND_AUTOIMMUNE_DISEASES',
  ChildAndAdolescentHiv = 'CHILD_AND_ADOLESCENT_HIV',
  AdultAndElderlyLivingWithHiv = 'ADULT_AND_ELDERLY_LIVING_WITH_HIV',
  PrimaryImmunodeficiency = 'PRIMARY_IMMUNODEFICIENCY',
  NeoplasmsOrImmunosuppressants = 'NEOPLASMS_OR_IMMUNOSUPPRESSANTS',
  TransplantationOfSolidOrgans = 'TRANSPLANTATION_OF_SOLID_ORGANS',
  Hsct = 'HSCT',
}

export const mapImmunocompromisedComorbiditiesToString = (comorbidity: ImmunocompromisedComorbidities) => {
  const dict: Record<ImmunocompromisedComorbidities, string> = {
    [ImmunocompromisedComorbidities.Diabetes]: 'Diabetes',
    [ImmunocompromisedComorbidities.CardiovascularPulmonaryDisease]: 'Cardio-pneumopatias crônicas',
    [ImmunocompromisedComorbidities.ChronicHepatopathy]: 'Hepatopatia crônica',
    [ImmunocompromisedComorbidities.ChronicKidneyDisease]: 'Doença renal crônica',
    [ImmunocompromisedComorbidities.SplenicDysfunctions]: 'Disfunções esplênicas',
    [ImmunocompromisedComorbidities.RheumatoAndAutoimmuneDiseases]: 'Doenças reumato e autoimunes',
    [ImmunocompromisedComorbidities.ChildAndAdolescentHiv]: 'Criança e adolescente HIV',
    [ImmunocompromisedComorbidities.AdultAndElderlyLivingWithHiv]: 'Adulto e idoso vivendo com HIV',
    [ImmunocompromisedComorbidities.PrimaryImmunodeficiency]: 'Imunodeficiência primária',
    [ImmunocompromisedComorbidities.NeoplasmsOrImmunosuppressants]: 'Neoplasias ou imunossupressores',
    [ImmunocompromisedComorbidities.TransplantationOfSolidOrgans]: 'Transplante de órgãos sólidos',
    [ImmunocompromisedComorbidities.Hsct]: 'TCTH',
  };
  return dict[comorbidity];
};
