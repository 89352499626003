import SvgIcon from '@app/components/svg/icon';
import SvgShareIos from '@app/components/svg/share-ios';
import { MobileService } from '@app/core/service/mobile-service';
import { PwaInstallListener, PwaInstallService } from '@app/modules/app/pwa-install.service';
import { Body, H3 } from '@components/atm.typography';
import { Hbox, Separator } from '@components/obj.box';
import { Modal } from '@components/obj.modal';
import * as React from 'react';
import { Container } from 'typedi';
import { Button } from '@components/atm.button';

const PwaInstall: React.FunctionComponent = () => {
  const mobileService: MobileService = Container.get(MobileService);
  const pwaInstallService: PwaInstallService = Container.get(PwaInstallService);
  const isIos = mobileService.isIos;

  const [showModal, setShowModal] = React.useState(false);

  React.useEffect(() => {
    if (showModal) {
      pwaInstallService.installPromptWasSeen();
    }
  }, [showModal]);

  React.useEffect(() => {
    const deferredPrompt = pwaInstallService.getDeferredPrompt();
    if (pwaInstallService.canShowPwaInstall() && (isIos || (deferredPrompt && deferredPrompt.prompt))) {
      setShowModal(true);
    }
  }, [pwaInstallService, setShowModal, isIos]);

  React.useEffect(() => {
    const listener: PwaInstallListener = {
      onDeferredPromptSet: dp => {
        if (dp && pwaInstallService.canShowPwaInstall()) {
          setShowModal(true);
        }
      },
    };
    pwaInstallService.setListener(listener);
    return () => pwaInstallService.setListener(null);
  }, [pwaInstallService, setShowModal]);

  const closeModal = () => setShowModal(false);
  const showPrompt = () => {
    const deferredPrompt = pwaInstallService.getDeferredPrompt();
    deferredPrompt.prompt();
    closeModal();
  };

  return (
    <Modal small={true} opened={showModal} onClose={closeModal}>
      <Hbox hAlign='center'>
        <SvgIcon width={64} />
      </Hbox>
      <Separator />
      <H3>Instalar o Vacina Certa</H3>
      <Body>Instale este aplicativo na tela inicial para acesso rápido e fácil! </Body>
      <Separator />
      {isIos ? (
        <>
          <H3>Passo a passo</H3>
          <Body>
            <ol>
              <li>
                {' '}
                Tocar em &nbsp; <SvgShareIos height={24} /> &nbsp; (abaixo){' '}
              </li>
              <li> Depois tocar "Adicionar à Tela Inicial" </li>
            </ol>
          </Body>
        </>
      ) : (
        <>
          <div>
            <Button kind='primary' onClick={showPrompt} expanded={true}>
              Instalar
            </Button>
          </div>
          <Separator />
          <div>
            <Button kind='primary' outlined={true} onClick={closeModal} expanded={true}>
              Cancelar
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default PwaInstall;
