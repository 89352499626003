import * as React from 'react';
import { NofificationCloseStyled, NofificationStyled, NotificationMessageStyled } from './notification.component.style';

export type NotificationType = 'success' | 'error' | 'info' | 'warnings';

export interface NotificationProps {
  type: NotificationType;
  message?: string;
  hidden?: boolean;
  onClose?: () => void;
}

interface NotificationState {
  hidden: boolean;
}

export class Notification extends React.Component<NotificationProps, NotificationState> {
  constructor(props: NotificationProps) {
    super(props);
    this.state = { hidden: !!this.props.hidden };
  }

  show = () => {
    this.setState({ hidden: false });
  };

  hide = () => {
    this.setState({ hidden: true });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    return (
      <NofificationStyled hidden={this.state.hidden} type={this.props.type}>
        <NotificationMessageStyled {...this.props}>
          {this.props.message}
          {this.props.children}
        </NotificationMessageStyled>
        <NofificationCloseStyled {...this.props} onClick={this.hide} />
      </NofificationStyled>
    );
  }
}
