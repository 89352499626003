import styled from 'styled-components';
import { Grid } from '@components/obj.grid';
import { Spacing } from '@components/obj.constants';

export const GridYTranslation = Spacing.XXXLarge;

export const TranslatedGridStyled = styled(Grid)`
  position: relative;
  top: 0;
  @media only screen and (min-width: 48em) {
    top: -${GridYTranslation};
  }
`;

// Use this on the container above the `TranslatedGridStyled`
export const TranslatedGridTopStyled = styled.div`
  height: 0;
  @media only screen and (min-width: 48em) {
    height: ${GridYTranslation};
  }
`;

// Use this if testmonial section is not present
export const GridCenterStyled = styled(Grid)`
  position: relative;
  padding-top: ${Spacing.XXXLarge};
  padding-bottom: ${Spacing.XXXLarge};
`;
