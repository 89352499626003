import { BrandColor, Color, FontSize, Spacing } from '@components/obj.constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

// value measured empirically
const desktopToMobileMenuBreakpoint = `1300px`;

export const HeaderStyled = styled.div<{ home: boolean }>`
  min-height: 90px;
  background: ${props => (props.home ? 'transparent' : 'linear-gradient(to right, #BA54A1, #DD6386)')};
  padding-right: 96px;
  padding-left: 96px;
  display: flex;
  padding-top: 30px;
  justify-content: space-between;

  @media (max-width: 1200px) {
    padding-right: ${Spacing.Large};
    padding-left: ${Spacing.Large};
  }

  @media only screen and (max-width: ${desktopToMobileMenuBreakpoint}) {
    padding-left: ${Spacing.XLarge};
    padding-right: ${Spacing.XLarge};
  }

  @media print {
    display: none;
  }
`;

export const HeaderLogoLinkStyled = styled.a`
  cursor: pointer;
  padding-left: ${Spacing.XSmall};
  padding-right: ${Spacing.XSmall};
`;

export const HeaderLogoStyled = styled.img`
  height: 32px;
`;

export const HeaderLinkContainerStyled = styled.div`
  height: 40px;

  @media only screen and (max-width: ${desktopToMobileMenuBreakpoint}) {
    display: none;
  }
`;

export const HeaderHamburguerContainerStyled = styled.div`
  display: none;

  @media only screen and (max-width: ${desktopToMobileMenuBreakpoint}) {
    display: block;
    margin-right: -${Spacing.XLarge};
  }
`;

export const HeaderHamburguerIconStyled = styled(FontAwesomeIcon)`
  font-size: ${FontSize.Medium};
  padding-right: 0;
`;

export const HeaderSelectedLineStyled = styled.div`
  width: 30px;
  height: 4px;
  background-color: ${BrandColor.Yellow};
  margin: auto;
  border-radius: 16px;
`;

export const HeaderSelectedStyled = styled.div`
  width: auto;
  display: inline-block;
  margin-left: ${Spacing.XLarge};
  position: relative;
`;

export const HeaderSelectedLineWrapperStyled = styled.div`
  margin-left: ${Spacing.XXLarge};
`;

const menuWidth = 220;

export const HeaderDropdownStyled = styled.div`
  position: absolute;
  z-index: 99;
  vertical-align: bottom;
  min-width: 0;
  max-height: 0;
  width: ${menuWidth}px;
  right: 0;
  vertical-align: bottom;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
  transition: max-height 0.4s ease-in-out;
  opacity: 1;
`;

export const HeaderDropdownListStyled = styled.a`
  display: inline-block;
  min-height: 44px;
  padding: ${Spacing.Large};
  cursor: pointer;
  text-decoration: none;
  min-width: 250px;

  &:hover {
    background-color: ${Color.GrayXLight};
  }
`;

export const HeaderProfileStyled = styled.div`
  &:hover ${HeaderDropdownStyled} {
    max-height: 800px !important;
  }
`;

export const HeaderHideableMenuItemText = styled.div`
  display: initial;

  @media (max-width: 1400px) {
    display: none;
  }
`;

export const HeaderHamburguerDropdownStyled = styled.div<{ hamburguerMenuOpened: boolean }>`
  position: absolute;
  z-index: 8;
  vertical-align: bottom;
  min-width: 0;
  max-height: ${props => (props.hamburguerMenuOpened ? '800px' : '0')};
  width: ${menuWidth}px;
  right: 0;
  vertical-align: bottom;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
  transition: max-height ${props => (props.hamburguerMenuOpened ? '0.4s' : '0')} ease-in-out;
  opacity: 1;
`;

export const HeaderHamburguerDropdownListStyled = styled.a`
  display: inline-block;
  min-height: 44px;
  padding: ${Spacing.Large};
  cursor: pointer;
  text-decoration: none;
  min-width: 250px;
`;

export const HeaderHamburguerOpacityStyled = styled.div<{ hamburguerMenuOpened: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 7;
  background-color: #000;
  opacity: ${props => (props.hamburguerMenuOpened ? '0.5' : '0')};
  transition: all 0.4s ease-out;
  visibility: ${props => (props.hamburguerMenuOpened ? 'visible' : 'hidden')};
`;
