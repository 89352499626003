import * as React from 'react';
import { Loading } from '@components/atm.loading';

import { LoadingFrameOverlayStyled, LoadingFrameStyled } from './loading-frame.component.style';

export interface LoadingFrameProps {
  showLoading: boolean;
}

// TODO: TEMPLATE
export class LoadingFrame extends React.Component<LoadingFrameProps, any> {
  constructor(props: LoadingFrameProps) {
    super(props);
  }

  render() {
    return (
      <div>
        <LoadingFrameOverlayStyled {...this.props} />
        <LoadingFrameStyled {...this.props}>
          <Loading type='boy-gif' />
        </LoadingFrameStyled>
      </div>
    );
  }
}
