import styled from 'styled-components';

export const FlashWrapperStyled = styled.div`
  z-index: 100;

  position: fixed;
  display: flex;
  flex-direction: column;

  width: 100%;
  top: 0;
`;
