import styled from 'styled-components';
import { Color, Spacing } from '@components/obj.constants';

export const SignatureStyled = styled.div`
  display: block;
  margin-top: ${Spacing.XXXLarge};
  width: 100%;
  text-align: center;
  margin-bottom: ${Spacing.XLarge};

  @media print {
    margin-top: ${Spacing.XLarge};
  }
`;

export const SignatureTextStyled = styled.div`
  border-top: 1px solid ${Color.Black};
  text-align: center;
  margin: auto;
  width: 100%;
  @media all and (min-width: 48em) {
    width: 50%;
  }
`;
