import * as React from 'react';
import { CheckoutFooterPlaceholderStyled, CheckoutFooterStyled } from './checkout-footer.component.style';

export class CheckoutFooter extends React.Component<any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div>
        <CheckoutFooterPlaceholderStyled />
        <CheckoutFooterStyled>{this.props.children}</CheckoutFooterStyled>
      </div>
    );
  }
}
