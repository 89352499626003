import styled from 'styled-components';
import { Color } from '@components/obj.constants';

interface CheckFamilySwitchGroupProps {
  show: boolean;
  maxHeight: string;
}

export const CheckFamilySwitchGroupStyled = styled.div`
  transition: max-height 0.3s ease-in-out, opacity 0.4s ease-in;
  background-color: ${Color.White};
  opacity: ${(props: CheckFamilySwitchGroupProps) => (props.show ? 1 : 0)};
  max-height: ${(props: CheckFamilySwitchGroupProps) => (props.show ? props.maxHeight : 0)};
  overflow: hidden;
`;
