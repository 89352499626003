import { PropsBase } from '@app/core/base/props.base';
import { Observer } from '@app/core/decorator/observer.decorator';
import { Router } from '@app/core/route/router';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { MobileService } from '@app/core/service/mobile-service';
import { Age } from '@app/model/age.model';
import { APPOINTMENT_PATH } from '@app/modules/appointment/appointment.route';
import { AppointmentStore } from '@app/modules/appointment/appointment.store';
import { Button } from '@components/atm.button';
import { CustomRadioField, CustomRadioFieldGroup } from '@components/atm.custom-radio';
import { Body, H1, H2 } from '@components/atm.typography';
import { CheckoutFooter } from '@components/mol.checkout-footer';
import QuickRecommendationFixedMenu from '@components/mol.quick-recommendation-sticky-menu/quick-recommendation-fixed-menu.component';
import { QuickRecommendationFixedMenuContainer } from '@components/mol.quick-recommendation-sticky-menu/quick-recommendation-fixed-menu.container';
import { Col, Grid, Row, Separator } from '@components/obj.grid';
import * as React from 'react';
import { Container } from 'typedi';
import { allProfilesMap, AppointmentStep } from './appointment.store.model';
import { OverdueDosesChatbotContainer } from './components/overdue-doses-chatbot.container.component';

@Observer()
export class AgeChoiceView extends React.Component<PropsBase> {
  private appointmentStore?: AppointmentStore = Container.get(AppointmentStore);
  private analyticsService?: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);
  private mobileService?: MobileService = Container.get(MobileService);
  private router?: Router = Container.get(Router);

  componentDidMount() {
    window.scrollTo(0, 0);
    this.analyticsService.pageView('consulta_escolha_idade');
  }

  render() {
    const age = this.appointmentStore.selectedAge;
    const selectedIndex =
      age &&
      this.appointmentStore.availableAgesForSelectedProfile.findIndex(
        item => item.minAge === age.minAge && item.maxAge === age.maxAge && item.type === age.type,
      );

    return (
      <div>
        <Grid>
          <Row>
            <Col xs={12}>
              <H1>1. Escolha de idade</H1>
              <Body> Selecione a idade atual da criança. </Body>
              <CustomRadioFieldGroup name='myGroup' onValueChange={this.handleButtonOnClick} value={selectedIndex}>
                {this.appointmentStore.availableMonthAgesForSelectedProfile.length > 0 && <H2>MESES</H2>}
                {this.buildAgeRadioFields(this.appointmentStore.availableMonthAgesForSelectedProfile)}

                {this.appointmentStore.availableYearAgesForSelectedProfile.length > 0 && <H2>ANOS</H2>}
                {this.buildAgeRadioFields(
                  this.appointmentStore.availableYearAgesForSelectedProfile,
                  this.appointmentStore.availableMonthAgesForSelectedProfile.length,
                )}
              </CustomRadioFieldGroup>
            </Col>
          </Row>

          {this.mobileService.onMobile || (
            <QuickRecommendationFixedMenuContainer
              quickRecommendationContext='profile'
            >
              {childProps => (
                <QuickRecommendationFixedMenu
                  recommendations={childProps.recommendations}
                  onItemClick={childProps.handleItemClick}
                  age={allProfilesMap.get(this.appointmentStore.selectedProfileId).age}
                  bottom={74}
                />
              )}
            </QuickRecommendationFixedMenuContainer>
          )}
        </Grid>

        <OverdueDosesChatbotContainer
          initialClosed={true}
          additionalBottomMargin={64}
        />

        <CheckoutFooter>
          <Grid>
            <Row center={'xs'}>
              <Col xs={12} sm={4}>
                <Separator />
                <Button kind='primary' outlined={true} expanded={true} onClick={this.goBack}>
                  Voltar
                </Button>
              </Col>
            </Row>
          </Grid>
        </CheckoutFooter>
      </div>
    );
  }

  private buildAgeRadioFields = (ages: Age[], offset: number = 0) => {
    return ages.map((age, index) => (
      <CustomRadioField key={index} value={offset + index} onClick={this.handleAlreadySelectedAgeClick}>
        {age.getAgeText()}
      </CustomRadioField>
    ));
  };

  private handleButtonOnClick = (index: number) => {
    const ages = this.appointmentStore.availableAgesForSelectedProfile;
    const selectedAge: Age = ages[index];
    this.appointmentStore.reset = true;
    this.appointmentStore.selectedAgeChanged(selectedAge);
    this.goToFamilyChoice();
    this.analyticsService.event(
      'consulta_escolha_idade',
      'click',
      false,
      this.appointmentStore.selectedAge.getAgeText(),
    );
  };

  private handleAlreadySelectedAgeClick = () => {
    this.goToFamilyChoice();
  };

  private goToFamilyChoice() {
    this.router.push(`${APPOINTMENT_PATH}/${AppointmentStep.VaccineChoiceByAge}`);
  }

  private goBack = () => {
    this.analyticsService.event('consulta_escolha_idade_back', 'click');
    this.props.history.goBack();
  };
}
