import * as React from 'react';
import { SignatureStyled, SignatureTextStyled } from './recomendation-signature-component.style';

export const Signature = props => {
  return (
    <SignatureStyled>
      <SignatureTextStyled data-hj-suppress=''>{props.children}</SignatureTextStyled>
    </SignatureStyled>
  );
};
