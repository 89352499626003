import { Color, Spacing } from '@components/obj.constants';
import styled from 'styled-components';
import { GridYTranslation } from './translate-grid.style';

export const HomeSignUpBackgroundStyled = styled.div`
  @media only screen and (min-width: 48em) {
    background-color: ${Color.Primary};
  }

  @media only screen and (max-width: 48em) {
    display: block;
  }
`;

export const HomeSignUpStyled = styled.div`
  transform: translateY(-${GridYTranslation});

  padding: ${Spacing.XXLarge};
  margin-bottom: ${Spacing.XXXLarge};

  background-color: ${Color.White};
  border-color: ${Color.GrayLight};
  border-radius: ${Spacing.Small};
  border-width: 1px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
`;
