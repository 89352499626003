import * as ColorFunc from 'color';
import styled from 'styled-components';
import { ButtonProps } from './button.component';
import { Color, Spacing } from '@components/obj.constants';
import { FontFamily, FontSize, FontWeight } from '@components/obj.constants';

const buttonColors = {
  primary: Color.Primary,
  secondary: Color.Secondary,
  neutral: Color.Neutral,
  callToAction: Color.CallToAction,
  accessory: Color.Accessory,
  alert: Color.Alert,
  light: Color.White,
};

export const ButtonStyled = styled.button`
  background-color: ${(props: ButtonProps) => (props.outlined ? 'transparent' : buttonColors[props.kind])};
  border-color: ${(props: ButtonProps) => (!props.outlined ? 'transparent' : buttonColors[props.kind])};
  border-style: solid;
  border-radius: 25px;
  border-width: 2px;
  width: ${(props: ButtonProps) => (props.expanded ? '100%' : props.width || 'auto')};
  min-height: ${(props: ButtonProps) => (props.small ? '30px' : '50px')};
  opacity: ${(props: ButtonProps) => (props.disabled || props.loading ? 0.5 : 1)};
  /* // this asymetrical padding is necessary due to the assymetry of the font */
  padding: ${(props: ButtonProps) => (props.width ? '10px' : `10px 16px 8px`)};
  color: ${(props: ButtonProps) =>
    props.outlined ? buttonColors[props.kind] : props.kind === 'callToAction' ? Color.Secondary : Color.White};
  font-family: ${FontFamily.Primary};
  font-weight: ${FontWeight.Bold};
  font-size: ${(props: ButtonProps) => (props.small ? FontSize.Small : FontSize.Medium)};
  cursor: pointer;
  text-transform: uppercase;
  position: relative;
  letter-spacing: 1px;

  &:focus {
    outline: 0;
  }

  &:active {
    background-color: ${(props: ButtonProps) =>
      props.outlined ? 'transparent' : ColorFunc(buttonColors[props.kind]).darken(0.2).hsl().string()};
  }

  &:hover {
    background-color: ${(props: ButtonProps) =>
      !props.outlined
        ? props.kind === 'secondary'
          ? ColorFunc(Color.Secondary).darken(0.2).hsl().string()
          : ColorFunc(buttonColors[props.kind]).darken(0.2).hsl().string()
        : ColorFunc(buttonColors[props.kind]).darken(0.2).alpha(0.05).hsl().string()};
  }

  & + & {
    margin-left: ${Spacing.Small};
  }
`;

export const ButtonContentStyled = styled.div`
  // display:  ${(props: { loading: boolean }) => (props.loading ? 'none' : 'block')};
  visibility: ${(props: { loading: boolean }) => (props.loading ? 'hidden' : 'visible')};

  & .fa {
    padding-right: ${Spacing.Small};
  }
`;

export const ButtonSpinnerStyled = styled.span`
  display: ${(props: { loading: boolean }) => (props.loading ? 'inline-block' : 'none')} !important;
  position: absolute;
  right: calc(50% - 10px);
  top: calc(50% - 10px);
`;
