// tslint:disable: max-line-length
import { HeaderSmart } from '@app/components//header.smart-component';
import { HOME_PATH } from '@app/modules/home/home.path';
import { HowItWorksId } from '@app/modules/home/home.view';
import { DisplayWhite, H4 } from '@components/atm.typography';
import { ForcedFade } from '@components/obj.animation/animation.component.style';
import { Hbox } from '@components/obj.box';
import { Col, Grid, Row, Separator } from '@components/obj.grid';
import * as React from 'react';
import {
  HeroButtonStyled,
  HeroImageStyled,
  HeroStyled, SeeMoreIconStyled,
} from './home-header.style';
// tslint:enable: max-line-length

export interface HomeHeaderProps {
  loggedIn: boolean;
  onSignUpClick?: () => void;
  onStartAppointmentClick?: () => void;
  onMobile?: boolean;
}

export class HomeHeader extends React.Component<HomeHeaderProps, any> {
  render() {
    return (
      <header>
        <HeroStyled>
          <HeroImageStyled>
            <HeaderSmart home={true} logged={this.props.loggedIn} />
            <Grid>
              <Row>
                <Separator />
              </Row>
              <Row middle='sm' center='xs'>
                <Col xs={12} sm={12} md={11} lg={9} >
                  <DisplayWhite>
                    Uma ferramenta prática para auxiliá-lo na orientação sobre vacinação para todas as idades
                  </DisplayWhite>
                  <Hbox hAlign='center'>
                    <HeroButtonStyled
                      kind='callToAction'
                      onClick={this.props.loggedIn ? this.props.onStartAppointmentClick : this.props.onSignUpClick}
                      expanded={true}
                    >
                      Iniciar
                    </HeroButtonStyled>
                  </Hbox>
                  <Separator />
                </Col>
              </Row>
              <ForcedFade show={true} duration={'0.5s'}>
                <Row center={'xs'}>
                  <Col xs={12} sm={4} />
                </Row>
                <Row center='xs' align='center'>
                  <Col>
                    <Separator />
                    <a href={`${HOME_PATH}#${HowItWorksId}`}>
                      <H4 light={true}> Saiba mais </H4>
                      <div>
                        {' '}
                        <SeeMoreIconStyled />{' '}
                      </div>
                    </a>
                  </Col>
                </Row>
              </ForcedFade>
            </Grid>
          </HeroImageStyled>
        </HeroStyled>
      </header >
    );
  }
}
