import * as React from 'react';
import { Loading } from '@components/atm.loading';

import { ButtonContentStyled, ButtonSpinnerStyled, ButtonStyled } from './button.component.style';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export type ButtonType = 'primary' | 'secondary' | 'neutral' | 'callToAction' | 'light' | 'alert';

export interface ButtonProps {
  disabled?: boolean;
  expanded?: boolean;
  loading?: boolean;
  onClick?: (ev?: React.MouseEvent<HTMLElement>) => any;
  outlined?: boolean;
  kind?: ButtonType;
  small?: boolean;
  width?: string;
  /**
   * why is it necessary? https://github.com/indigotech/eok-weekly/issues/114
   * https://www.w3schools.com/tags/att_button_type.asp
   */
  type?: 'button' | 'submit' | 'reset'; // default-value: "button"
}

export class Button extends React.Component<ButtonProps, any> {
  static defaultProps = {
    type: 'button',
  };

  constructor(props: ButtonProps) {
    super(props);
  }

  render() {
    const { loading, onClick, disabled, ...props } = this.props;
    const loadingValue: any = loading ? 'true' : undefined;
    return (
      <ButtonStyled onClick={onClick ? onClick : null} disabled={loading || disabled} {...props}>
        <ButtonContentStyled loading={loadingValue}>{this.props.children}</ButtonContentStyled>
        <ButtonSpinnerStyled loading={loadingValue}>
          <Loading type={faSpinner} />
        </ButtonSpinnerStyled>
      </ButtonStyled>
    );
  }
}
