// tslint:disable: max-classes-per-file
// tslint:disable: max-line-length
import { NotPrintableStyled, PrintableStyled } from '@app/components/printable.component.style';
import { PropsBase } from '@app/core/base/props.base';
import { GraphQL } from '@app/core/decorator/graphql.decorator';
import { Observer } from '@app/core/decorator/observer.decorator';
import { Router } from '@app/core/route/router';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { GraphQLDocsService } from '@app/core/service/graphql-docs.service';
import { MobileService } from '@app/core/service/mobile-service';
import { APPOINTMENT_PATH } from '@app/modules/appointment/appointment.route';
import { AppointmentStore } from '@app/modules/appointment/appointment.store';
import { RecommendationViewState } from '@app/modules/appointment/recommendation.view-state';
import { AuthenticationStore } from '@app/modules/authentication/authentication.store';
import {
  RecommendationAdministrationPlaceInput,
  RecommendationCreate,
  RecommendationCreateVariables,
  RecommendationInput,
  RecommendationUpdateInput,
  RecommendationUpdateVariables,
  RecommendationVaccineInput,
} from '@app/resource/graphql/graphql-schema';
import { Button } from '@components/atm.button';
import { CheckboxField } from '@components/atm.checkbox';
import { TextField } from '@components/atm.text-field';
import { Body, H1, H3, InputLabel } from '@components/atm.typography';
import { CheckoutFooter } from '@components/mol.checkout-footer';
import { Hbox } from '@components/obj.box';
import { ButtonsContainer } from '@components/obj.buttons-container';
import { Color } from '@components/obj.constants';
import { AutoMargin, Col, Grid, Row, Separator } from '@components/obj.grid';
import { Modal } from '@components/obj.modal';
import { hasDocument, hasWindow } from '@components/utils';
import { faDownload, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Action, Location, UnregisterCallback } from 'history';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as React from 'react';
import * as Scroll from 'react-scroll';
import ReactTooltip from 'react-tooltip';
import { Container } from 'typedi';
import { AdministrationPlacesStore } from '../administration-places/administration-places.store';
import { AppointmentStep } from './appointment.store.model';
import { OverdueDosesChatbotContainer } from './components/overdue-doses-chatbot.container.component';
import { ShareVacinaCerta } from './components/share.component';
import { RecommendationWriter } from './helper/recommendation-writer';
// tslint:enable: max-line-length

export interface RecommendationViewProps extends PropsBase<RecommendationCreate> {
  create: any;
  update: any;
}

const didnPrintMessage = 'Você não imprimiu ou baixou o pdf da recomendação. Deseja mesmo finalizar a consulta?';

const documentNode = Container.get(GraphQLDocsService).getDocument('all-recommendations.query');
@GraphQL('recommendation-create.mutation', {
  name: 'create',
  options: { refetchQueries: [{ query: documentNode }] },
})
@GraphQL('recommendation-update.mutation', {
  name: 'update',
  options: { refetchQueries: [{ query: documentNode }] },
})
@Observer()
export class RecommendationView extends React.Component<RecommendationViewProps> {
  private appointmentStore?: AppointmentStore = Container.get(AppointmentStore);
  private administrationStore?: AdministrationPlacesStore = Container.get(AdministrationPlacesStore);
  private recommendationViewState?: RecommendationViewState = Container.get(RecommendationViewState);
  private analyticsService?: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);
  private recommendationWriter?: RecommendationWriter = Container.get(RecommendationWriter);
  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);
  private router?: Router = Container.get(Router);
  private mobileService?: MobileService = Container.get(MobileService);

  private blockNavigation: BlockNavigation;
  private recommendationId: string;

  private handleNavigate: () => void;

  constructor(props) {
    super(props);
    this.appointmentStore.currentAppointmentStep = AppointmentStep.Recommendation;
    this.blockNavigation = new BlockNavigation(didnPrintMessage, (unblock, location, action) => {
      this.showNavigationModal(unblock, location, action);
    });
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.analyticsService.pageView('consulta_recomendacao');
    if (
      this.appointmentStore.selectedVaccinesForSelectedAge &&
      this.appointmentStore.selectedVaccinesForSelectedAge.length > 0
    ) {
      this.sendVaccineInformationAnalytics(this.appointmentStore.selectedVaccinesForSelectedAge);
    }
    if (
      this.appointmentStore.selectedVaccinesForPreviousAges &&
      this.appointmentStore.selectedVaccinesForPreviousAges.length > 0
    ) {
      this.sendVaccineInformationAnalytics(
        this.appointmentStore.selectedVaccinesForPreviousAges.map(vaccineAge => vaccineAge.vaccine),
      );
    }

    await this.callCreateMutation({
      age: this.appointmentStore.selectedAge.getAgeText(),
      crm: this.authenticationStore.crm,
      uf: this.authenticationStore.uf,
      repTerritory: this.authenticationStore.repTerritory,
      administrationPlacesIds: this.buildSelectedAdministrationPlacesIds(),
      vaccinesIds: this.buildVaccinesIds(),
    });
  }

  componentWillUnmount() {
    this.blockNavigation.flush();
  }

  render() {
    const recommendationComponent = this.recommendationWriter.writeAsJSX({
      onAddAdministrationPlacesClick: this.handleAddAdministrationPlacesClick,
    });

    const Element = Scroll.Element;

    return (
      <div>
        <NotPrintableStyled>
          <Grid fluid={true}>
            <Row mb={true} center='xs'>
              <Col xs={12} sm={10}>
                <ShareVacinaCerta />
                <Row>
                  <Col xs={12}>
                    <H1>3. Recomendação médica</H1>
                  </Col>
                </Row>
                <Row end={'xs'}>
                  <Col>{this.buildButtons()}</Col>
                </Row>
                <Body> Imprima ou baixe o PDF da recomendação. </Body>
                <Body>Este documento tem valor como prescrição apenas com a assinatura médica.</Body>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={5} smOffset={1}>
                <Element name='childName' />
                <InputLabel>Nome do paciente</InputLabel>
                <TextField type='text' onValueChange={this.onNameChanged} />
              </Col>
            </Row>
            <Row center='xs'>
              <Col xs={12} sm={10}>
                {recommendationComponent}
              </Col>
            </Row>
            <Separator />
            <Row>
              <Col xs={12} sm={10} smOffset={1} md={4}>
                <RecommendationConfig
                  showBrandName={this.recommendationViewState.showBrandName}
                  onShowBrandNameChange={this.handleBrandClick}
                  showRecommendationDate={this.recommendationViewState.showRecommendationDate}
                  onShowRecommendationDateChange={this.handleShowRecommendationDateClick}
                  showPatientAge={this.recommendationViewState.showPatientAge}
                  onShowPatientAgeChange={this.handleShowPatientAgeClick}
                />
              </Col>
              <Col xs={12} sm={10} smOffset={1} mdOffset={0} md={6}>
                {this.buildButtons()}
                <Separator />
              </Col>
            </Row>
          </Grid>
          <Footer onFinishClick={this.handleFinishClick} onGoBackClick={this.goBack} />
          <BlockNavigationModal
            open={this.recommendationViewState.showUnblockNavigationModal}
            onClose={this.handleCloseNavigationModalHOF()}
            onDownloadPdfClick={this.handleCloseNavigationModalHOF(this.handleDownloadPDFClick)}
            onPrintClick={this.handleCloseNavigationModalHOF(this.handlePrintClick)}
            onNavigateClick={this.handleNavigate}
          />
        </NotPrintableStyled>

        <PrintableStyled>{recommendationComponent}</PrintableStyled>

        <OverdueDosesChatbotContainer
          initialClosed={true}
          additionalBottomMargin={64}
          recommendationsCount={this.appointmentStore.getAllRecommendationsForCurrentContext('none').length}
        />

      </div>
    );
  }
  private showNavigationModal = (unblock: UnregisterCallback, location: Location, action: Action) => {
    this.handleNavigate = () => {
      unblock();
      switch (action) {
        case 'PUSH':
          this.router.push(`${location.pathname}`);
          break;
        case 'POP':
          this.router.goBack();
          break;
        case 'REPLACE':
          this.router.replace(`${location.pathname}`);
          break;
      }
      this.recommendationViewState.showUnblockNavigationModal = false;
    };
    this.recommendationViewState.showUnblockNavigationModal = true;
  };

  private handleCloseNavigationModalHOF = (onClose?: () => void) => () => {
    this.recommendationViewState.showUnblockNavigationModal = false;
    if (onClose) {
      onClose();
    }
  };

  private goBack = () => {
    this.analyticsService.event('consulta_recomendacao_back', 'click');
    this.props.history.goBack();
  };

  private handleAddAdministrationPlacesClick = () => {
    this.analyticsService.event('consulta_recomendacao_recomendar_centros', 'click');
    this.blockNavigation.unblock();
    this.router.push(`${APPOINTMENT_PATH}/${AppointmentStep.AdministrationPlaces}`);
  };

  private onNameChanged = (value: string) => {
    this.recommendationViewState.patientName = value;
  };

  private buildButtons = () => {
    return (
      <>
        <Hbox>
          <AutoMargin />
          <Hbox.Item noGrow={true}>
            <Button
              kind='primary'
              outlined={true}
              width='50px'
              data-tip={this.mobileService.onMobile ? undefined : 'Imprimir'}
              onClick={this.handlePrintClick}
            >
              <FontAwesomeIcon fixedWidth={true} icon={faPrint} />
            </Button>
          </Hbox.Item>
          <>
            <Hbox.Separator />
            <Hbox.Separator />
            <Hbox.Item noGrow={true}>
              <Button
                kind='primary'
                outlined={true}
                width='50px'
                loading={this.recommendationViewState.generatePdfLoading}
                onClick={this.handleDownloadPDFClick}
                data-tip={this.mobileService.onMobile ? undefined : 'Baixar PDF'}
              >
                <FontAwesomeIcon fixedWidth={true} icon={faDownload} />
              </Button>
            </Hbox.Item>
          </>
        </Hbox>
        <ReactTooltip
          className={'react-tooltip'}
          backgroundColor={Color.White}
          border={true}
          borderColor={Color.Black}
          textColor={Color.Black}
        />
      </>
    );
  };

  private handleDownloadPDFClick = () => {
    this.analyticsService.event('consulta_recomendacao_baixar_pdf', 'click');
    /** https://stackoverflow.com/a/55789392/3670829 */
    if (!hasDocument() || !hasWindow()) {
      return;
    }
    this.recommendationViewState.generatePdfLoading = true;
    const docDefinition = this.recommendationWriter.writeAsPdfMake();
    try {
      // https://pdfmake.github.io/docs/fonts/custom-fonts-client-side/url/
      (pdfMake as any).fonts = {
        Lato: {
          normal: `${window.location.origin}/assets/fonts/Lato-Regular.woff`,
          bold: `${window.location.origin}/assets/fonts/Lato-Semibold.woff`,
        },
      };
      const patientName = this.recommendationViewState.patientName || 'paciente';
      pdfMake.createPdf(docDefinition).download(`${patientName.replace(/ /g, '')}-${Date.now()}`);
    } catch (e) {
      console.log(`RecommendationView -> privatehandleDownloadPDFClick -> e)`, e);
    }
    this.recommendationViewState.generatePdfLoading = false;
    this.blockNavigation.unblock();
  };

  private handlePrintClick = () => {
    this.analyticsService.event('consulta_recomendacao_imprimir', 'click');
    this.callUpdateMutation({ id: this.recommendationId, print: true });
    this.blockNavigation.unblock();
    window.print();
  };

  private handleFinishClick = () => {
    this.props.history.push(APPOINTMENT_PATH);
    this.analyticsService.event('consulta_recomendacao_finalizar', 'click');
    if (!this.blockNavigation.isBlock()) {
      this.appointmentStore.reset = true;
    }
  };

  private buildSelectedAdministrationPlacesIds(): RecommendationAdministrationPlaceInput[] {
    const places = [];

    if (
      this.administrationStore.selectedAdministrationPlaces.AdministrationPlacesForPosition &&
      this.administrationStore.selectedAdministrationPlaces.AdministrationPlacesForPosition.length > 0
    ) {
      this.administrationStore.selectedAdministrationPlaces.AdministrationPlacesForPosition.map(item =>
        places.push({ administrationPlaceId: item.id }),
      );
    }

    return places;
  }

  private buildVaccinesIds(): RecommendationVaccineInput[] {
    const vaccines = [];

    if (
      this.appointmentStore.selectedVaccinesForSelectedAge &&
      this.appointmentStore.selectedVaccinesForSelectedAge.length > 0
    ) {
      this.appointmentStore.selectedVaccinesForSelectedAge.map(vaccine => vaccines.push({ vaccineId: vaccine.id }));
    }

    if (
      this.appointmentStore.selectedVaccinesForPreviousAges &&
      this.appointmentStore.selectedVaccinesForPreviousAges.length > 0
    ) {
      this.appointmentStore.selectedVaccinesForPreviousAges.map(vaccineAge =>
        vaccines.push({ vaccineId: vaccineAge.vaccine.id }),
      );
    }

    return vaccines;
  }

  private handleBrandClick = (_value: any, checked: boolean) => {
    this.callUpdateMutation({
      id: this.recommendationId,
      vaccineBrand: checked,
    });

    if (checked) {
      this.analyticsService.event('consulta_recomendacao_marca_adicionar', 'click', true);
    } else {
      this.analyticsService.event('consulta_recomendacao_marca_remover', 'click', true);
    }

    this.recommendationViewState.showBrandName = checked;
  };

  private handleShowRecommendationDateClick = (_value: any, checked: boolean) => {
    if (checked) {
      this.analyticsService.event('consulta_recomendacao_data_adicionar', 'click', true);
    } else {
      this.analyticsService.event('consulta_recomendacao_data_remover', 'click', true);
    }
    this.recommendationViewState.showRecommendationDate = checked;
  };

  private handleShowPatientAgeClick = (_value: any, checked: boolean) => {
    if (checked) {
      this.analyticsService.event('consulta_recomendacao_idade_adicionar', 'click', true);
    } else {
      this.analyticsService.event('consulta_recomendacao_idade_remover', 'click', true);
    }
    this.recommendationViewState.showPatientAge = checked;
  };

  private sendVaccineInformationAnalytics(vaccines: Array<{ brand?: string; laboratory: string; name: string }>) {
    vaccines.map(vaccine => {
      let eventLabel;

      if (vaccine.brand && vaccine.brand !== '' && vaccine.brand !== '-') {
        eventLabel = `${vaccine.brand} - ${vaccine.laboratory}`;
      } else if (vaccine.laboratory && vaccine.laboratory !== '' && vaccine.laboratory !== '-') {
        eventLabel = `${vaccine.laboratory}`;
      }

      this.analyticsService.event('consulta_recomendacao_vacinas', vaccine.name, true, eventLabel, null, false);
    });
  }

  private async callCreateMutation(input: RecommendationInput): Promise<any> {
    const vars: RecommendationCreateVariables = {
      data: input,
    };
    return this.props
      .create({ variables: vars })
      .then(result => {
        this.recommendationId = result.data.RecommendationCreate.id;
      })
      .catch(error => {
        console.log(error);
      });
  }

  private async callUpdateMutation(input: RecommendationUpdateInput): Promise<any> {
    const vars: RecommendationUpdateVariables = {
      data: input,
    };
    return this.props
      .update({ variables: vars })
      .then(result => {
        console.log(result.data.RecommendationUpdate);
      })
      .catch(error => {
        console.log(error);
      });
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////
interface BlockNavigationModalProps {
  open: boolean;
  onClose: () => void;
  onPrintClick: () => void;
  onDownloadPdfClick: () => void;
  onNavigateClick: () => void;
}

const BlockNavigationModal: React.FunctionComponent<BlockNavigationModalProps> = props => {
  return (
    <Modal noGutter={true} small={true} opened={props.open} onClose={props.onClose}>
      <Grid fluid={true}>
        <Row mb={true}>
          <Col xs={12}>
            <Separator />
            <H3>{didnPrintMessage}</H3>
          </Col>
        </Row>
        <Row mb={true}>
          <Col xs={12}>
            <Button expanded={true} onClick={props.onPrintClick} kind='primary'>
              Imprimir
            </Button>
          </Col>
        </Row>
        <Row mb={true}>
          <Col xs={12}>
            <Button expanded={true} onClick={props.onDownloadPdfClick} kind='primary'>
              Baixar PDF
            </Button>
          </Col>
        </Row>
        <Row mb={true}>
          <Col xs={12}>
            <Button expanded={true} onClick={props.onNavigateClick} kind={'secondary'}>
              Continuar
            </Button>
          </Col>
        </Row>
      </Grid>
    </Modal>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * This class blocks user navigation on the current screen when constructed.
 * Calll "unblock" to remove this.
 * It is important to call "flush" when this object will not be used anymore (ex: componentWillUnmount)
 */
class BlockNavigation {
  private router?: Router = Container.get(Router);

  private unblockNavigation: UnregisterCallback;
  private blocked: boolean = true;

  constructor(
    blockMessage: string,
    onBlockCallback: (unblock: UnregisterCallback, location: Location, action: Action) => void,
  ) {
    const unblock = this.router.block(onBlockCallback);
    this.unblockNavigation = () => {
      unblock();
      this.blocked = false;
    };

    // https://stackoverflow.com/a/2923258
    if (hasWindow()) {
      window.onbeforeunload = evt => {
        if (typeof evt === 'undefined') {
          evt = window.event;
        }
        if (evt) {
          evt.returnValue = blockMessage;
        }
        return blockMessage;
      };
    }
  }

  unblock = () => {
    this.unblockNavigation();
  };

  flush = () => {
    window.onbeforeunload = null;
    this.unblockNavigation();
  };

  isBlock = (): boolean => {
    return this.blocked;
  };
}

////////////////////////////////////////////////////////////////////////////////////////////////////

enum RecommendationCheckboxValue {
  ShowBrandName = 0,
  ShowRecommendationDate = 1,
  ShowPatientAge = 2,
}

interface IRecommendationConfigProps {
  showBrandName: boolean;
  onShowBrandNameChange: (value: unknown, checked: boolean) => void;
  showRecommendationDate: boolean;
  onShowRecommendationDateChange: (value: unknown, checked: boolean) => void;
  showPatientAge: boolean;
  onShowPatientAgeChange: (value: unknown, checked: boolean) => void;
}

const RecommendationConfig: React.FunctionComponent<IRecommendationConfigProps> = props => {
  return (
    <>
      <CheckboxField
        value={RecommendationCheckboxValue.ShowBrandName}
        checked={props.showBrandName}
        onValueChange={props.onShowBrandNameChange}
      >
        Mostrar marcas das vacinas
      </CheckboxField>
      <CheckboxField
        value={RecommendationCheckboxValue.ShowRecommendationDate}
        checked={props.showRecommendationDate}
        onValueChange={props.onShowRecommendationDateChange}
      >
        Mostrar data da recomendação
      </CheckboxField>
      <CheckboxField
        value={RecommendationCheckboxValue.ShowPatientAge}
        checked={props.showPatientAge}
        onValueChange={props.onShowPatientAgeChange}
      >
        Mostrar idade do paciente
      </CheckboxField>
      <Separator />
    </>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////

interface IFooterProps {
  onGoBackClick: () => void;
  onFinishClick: () => void;
}

const Footer: React.FunctionComponent<IFooterProps> = props => {
  return (
    <CheckoutFooter>
      <ButtonsContainer>
        <Button kind='primary' outlined={true} expanded={true} onClick={props.onGoBackClick} >
          Voltar
        </Button>
        <Button kind='callToAction' expanded={true} onClick={props.onFinishClick}>
          Finalizar consulta
        </Button>
      </ButtonsContainer>
    </CheckoutFooter>

  );
};
