import { CookieConsentContainer } from '@app/components/cookie-consent';
import { PropsBase } from '@app/core/base/props.base';
import { Observer } from '@app/core/decorator/observer.decorator';
import { Router } from '@app/core/route/router';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { MobileService } from '@app/core/service/mobile-service';
import { ApplicationStore } from '@app/modules/app/application.store';
import { AgeChoiceData } from '@app/modules/appointment/age-choice.data';
import { APPOINTMENT_PATH } from '@app/modules/appointment/appointment.route';
import { AppointmentStore } from '@app/modules/appointment/appointment.store';
import { AppointmentStep } from '@app/modules/appointment/appointment.store.model';
import { RecommendationView } from '@app/modules/appointment/recommendation.view';
import { VaccineChoiceByAgeData as VaccineChoiceDataByAge } from '@app/modules/appointment/vaccine-choice-by-age.data';
import { CheckoutStepper } from '@components/mol.checkout-stepper';
import { MenuItems } from '@components/mol.header/header.model';
import { MatchMedia } from '@components/obj.match-media';
import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { Container } from 'typedi';
import { AdministrationPlacesView } from '../administration-places/administration-places.view';
import { ImmunocompromisedChoiceView } from './immunocompromised-choice.view';
import { PriorityChoiceView } from './priority-choice.view';
import { ProfileChoiceData } from './profile-choice.data';
import { VaccineChoiceBySpecialNeedData } from './vaccine-choice-by-special-need.data';

export type AppointmentViewProps = PropsBase;

export interface NestedRoute {
  subPath: AppointmentStep;
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  render?: (props: RouteComponentProps<any>) => React.ReactNode;
}

@Observer()
export class AppointmentView extends React.Component<AppointmentViewProps> {
  private appointmentRoutes: NestedRoute[] = [
    {
      subPath: AppointmentStep.ProfileChoice,
      component: ProfileChoiceData,
    },
    {
      subPath: AppointmentStep.ImmunocompromisedChoice,
      component: ImmunocompromisedChoiceView,
    },
    {
      subPath: AppointmentStep.PriorityChoice,
      component: PriorityChoiceView,
    },
    {
      subPath: AppointmentStep.AgeChoice,
      component: AgeChoiceData,
    },
    {
      subPath: AppointmentStep.VaccineChoiceByAge,
      component: VaccineChoiceDataByAge,
    },
    {
      subPath: AppointmentStep.VaccineChoiceBySpecialNeed,
      component: VaccineChoiceBySpecialNeedData,
    },
    {
      subPath: AppointmentStep.Recommendation,
      component: RecommendationView,
    },
    {
      subPath: AppointmentStep.AdministrationPlaces,
      component: AdministrationPlacesView,
    },
  ];

  private analyticsService?: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);
  private appointmentStore?: AppointmentStore = Container.get(AppointmentStore);
  private applicationStore?: ApplicationStore = Container.get(ApplicationStore);
  private mobileService?: MobileService = Container.get(MobileService);
  private router?: Router = Container.get(Router);

  constructor(props) {
    super(props);
    this.applicationStore.currentMenuItem = MenuItems.consulta;
  }

  render() {
    return (
      <div>
        <MatchMedia defaultMinWidth={'48em'} >
          {match => match ? null : <CookieConsentContainer />}
        </MatchMedia>
        <CheckoutStepper
          step={this.appointmentStore.currentAppointmentStepNumber}
          onMobile={this.mobileService.onMobile}
          onClick={this.onCheckoutStepperClick}
        />
        <Switch>
          {this.appointmentRoutes.map((route, index) => {
            return (
              <Route
                key={index}
                path={`${APPOINTMENT_PATH}/${route.subPath}`}
                component={route.component}
                exact={true}
              />
            );
          })}
          <Redirect from={APPOINTMENT_PATH} to={`${APPOINTMENT_PATH}/${this.appointmentRoutes[0].subPath}`} />
        </Switch>
      </div>
    );
  }

  private onCheckoutStepperClick = (step: number) => {
    this.analyticsService.event(`step_${step}_click`, 'click');
    if (step > this.appointmentStore.currentAppointmentStepNumber) {
      this.routeToNextStep();
    } else if (step < this.appointmentStore.currentAppointmentStepNumber) {
      this.routeToPreviousStep();
    }
  };

  private routeToNextStep() {
    switch (this.appointmentStore.currentAppointmentStepNumber) {
      case 1:
        if (this.appointmentStore.selectedAge) {
          this.router.push(`${APPOINTMENT_PATH}/${AppointmentStep.VaccineChoiceByAge}`);
        } else {
          alert('Escolha uma idade antes de avançar.');
        }
        break;
      case 2:
        if (!this.mobileService.onMobile) {
          this.router.push(`${APPOINTMENT_PATH}/${AppointmentStep.Recommendation}`);
        }
        break;
      default:
        break;
    }
  }

  private routeToPreviousStep() {
    switch (this.appointmentStore.currentAppointmentStepNumber) {
      case 2:
        this.router.push(`${APPOINTMENT_PATH}/${AppointmentStep.ProfileChoice}`);
        break;
      case 3:
        this.router.push(`${APPOINTMENT_PATH}/${AppointmentStep.VaccineChoiceByAge}`);
        break;
      default:
        break;
    }
  }
}
