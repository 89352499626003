/* tslint:disable:all */
import * as React from 'react';

const SvgManutencao = props => (
  <svg width={28} height={28} {...props}>
    <g fill='none' fillRule='evenodd'>
      <path
        d='M14 0c2.683 0 5.132.661 7.206 1.841a13.166 13.166 0 0 1 4.953 4.953C27.339 8.868 28 11.317 28 14c0 2.683-.661 5.132-1.841 7.206a13.166 13.166 0 0 1-4.953 4.953C19.132 27.339 16.683 28 14 28c-2.683 0-5.132-.661-7.206-1.841a13.166 13.166 0 0 1-4.953-4.953C.661 19.132 0 16.683 0 14c0-2.683.661-5.132 1.841-7.206A13.166 13.166 0 0 1 6.794 1.84C8.868.661 11.317 0 14 0z'
        fill='#BA54A1'
      />
      <g fill='#FFF'>
        <path d='M16.988 21.276a.664.664 0 0 1-.478-.212.704.704 0 0 1-.194-.499c.006-.378.318-.694.682-.69h.011c.372.01.67.329.665.71a.701.701 0 0 1-.686.691m.931-1.805c-.378-.399-2.969-3.222-3.002-3.258l-1.953 2.103c.692.731 2.512 2.733 2.974 3.218.278.292.59.436.955.44h.002c.1.001.203-.009.308-.03.554-.108.918-.44 1.082-.989.167-.554.044-1.054-.366-1.484M12.104 13.228c-.28-.294-1.01-1.132-1.283-1.419-.048-.05.097-.58.097-.58v-.024l.005-.128c.01-.258.02-.525-.026-.788a3.086 3.086 0 0 0-.851-1.674 2.992 2.992 0 0 0-2.032-.848c-.39-.004-.667-.02-1.052.158l-.198.092.13.178c.037.05.08.078.113.098.008.005.019.012.02.014.584.607 1.057 1.102 1.49 1.559.207.22.204.49-.01.708l-.098.1c-.186.19-.576.586-.768.775a.45.45 0 0 1-.322.14.462.462 0 0 1-.321-.144c-.054-.054-.106-.11-.162-.168a10.536 10.536 0 0 0-.073-.078L5.248 9.607l-.107.228c-.191.412-.163 1.017-.063 1.54.276 1.45 1.464 2.475 2.89 2.49.075 0 .152-.002.228-.007h.024c.03 0 .05.004.073.021 0 0 .48-.085.56-.001.272.287.991 1.116 1.254 1.392l1.997-2.042zM9.608 20.685c1.348-1.54 4.187-4.588 5.54-6.124l1.38-1.57-.688-.706-.677-.717-1.54 1.405c-1.506 1.375-4.497 4.266-6.005 5.637a1.81 1.81 0 0 0-.606 1.307c-.02.836.515 1.386 1.32 1.377a1.714 1.714 0 0 0 1.276-.61M22.869 12.162l-.51-.53c-.191-.197-.382-.397-.57-.594a.285.285 0 0 0-.222-.098l-.604.005h-.037c-.435-.007-.64-.225-.643-.684l-.002-.115a3.486 3.486 0 0 1 .001-.303c.009-.134-.036-.238-.148-.36a164.067 164.067 0 0 1-1.93-2.104 3.533 3.533 0 0 0-1.022-.786c-.891-.47-1.756-.638-2.644-.52a3.949 3.949 0 0 0-1.259.387c-.22.11-.165.452.078.478.467.05.884.174 1.274.374.904.465 1.415 1.336 1.364 2.329-.024.456-.155.9-.389 1.317.255.268.515.543.77.814.219.23.43.456.628.665.269-.252.33-.3.382-.332.318-.185.678-.202.968-.047.094.051.185.123.27.212.382.395.446.904.174 1.373l.266.269c.355.362.725.736 1.096 1.087.054.05.1.08.13.081.016 0 .064-.008.162-.083l1.92-1.927.507-.544c.103-.112.137-.21-.01-.364' />
      </g>
    </g>
  </svg>
);

export default SvgManutencao;
