import { HeaderSmart } from '@app/components//header.smart-component';
import { CookieConsentContainer } from '@app/components/cookie-consent';
import { PropsBase } from '@app/core/base/props.base';
import { Observer } from '@app/core/decorator/observer.decorator';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { ApplicationStore } from '@app/modules/app/application.store';
import { AuthenticationStore } from '@app/modules/authentication/authentication.store';
import { LinkButton } from '@components/atm.button';
import { Body, Display, H2 } from '@components/atm.typography';
import { Col, Grid, Row, Separator } from '@components/obj.grid';
import { Footer } from '@components/pag.home';
import { FooterContainerView } from '@components/pag.home/obj.footer/footer.container';
import * as React from 'react';
import { Container, Service } from 'typedi';

export type TermsViewProps = PropsBase<any>;

@Observer()
@Service()
export class TermsView extends React.Component<TermsViewProps> {
  private authenticationStore?: AuthenticationStore = Container.get(AuthenticationStore);
  private applicationStore?: ApplicationStore = Container.get(ApplicationStore);
  private analyticsService?: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.analyticsService.pageView('termos-de-uso');
  }

  render() {
    return (
      <div>
        <CookieConsentContainer />
        <HeaderSmart
          home={false}
          logged={this.authenticationStore.loggedIn}
        />
        <Grid>
          <Row center='xs'>
            <Col xs={12} sm={10} md={9} >
              <Row mb={true}>
                <Col xs={12}>
                  <Display>Termos de Uso do Site da GSK</Display>
                  <Body>
                    <b>Atualizado: 01 de Abril 2022</b>
                  </Body>
                  <Body>
                    Este Site é propriedade da GSK ("GlaxoSmithKline"). Ao usar
                    este Site, você concorda com estes termos de uso. Caso não
                    concorde, não use este Site. <br /> A GSK reserva-se o direito, a
                    seu critério, de alterar, modificar, adicionar ou remover
                    partes desses Termos de Uso a qualquer momento. É sua
                    responsabilidade verificar mudanças periodicamente nesses
                    Termos de Uso. Você pode acessar os Termos de Uso por meio do
                    hiperlink na parte inferior da página. O seu uso contínuo do
                    Site após a atualização dos Termos de Uso significará que você
                    concorda em estar vinculado por tais revisões. <br /> As informações
                    fornecidas neste Site são apenas para informações gerais e
                    fins educacionais. O Site pode conter informações sobre
                    condições médicas e seu tratamento. Se você é um profissional
                    de saúde a informação contida neste Site não se destina a
                    servir como substituto do seu próprio julgamento médico.
                  </Body>
                  <H2>
                    Usuários Permitidos
                  </H2>
                  <Body>
                    Este Site destina-se apenas a profissionais de saúde. Ao usar
                    esta plataforma, você certifica que possui uma licença para
                    operar como profissional de saúde no país para o qual este
                    Site se destina.  "Profissional de saúde" refere-se a um
                    indivíduo que, no curso de suas atividades profissionais, pode
                    prescrever, fornecer ou administrar uma vacina ou dispositivos
                    médicos.
                  </Body>
                  <Body>
                    SOMENTE PARA OS PROFISSIONAIS DE SAÚDE: Por favor,
                    note que as informações de prescrição aprovadas devem orientar
                    o uso adequado de todos as vacinas. Antes de prescrever
                    qualquer vacina, os profissionais de saúde devem consultar as
                    informações de prescrição aprovadas para essa vacina em seu
                    país.
                  </Body>
                  <H2>Contas, Senhas, Segurança</H2>
                  <Body>Se você optar por abrir uma conta (incluindo a
                    configuração de um ID e senha), você é inteiramente responsável
                    por manter a confidencialidade das informações que possui para
                    sua conta e para toda a atividade que ocorre em sua
                    conta.</Body>
                  <H2>Disponibilidade da Informação</H2>
                  <Body>A GSK envolve esforços razoáveis para atualizar este Site,
                    mas algumas informações podem ficar desatualizadas ao longo do
                    tempo. As informações contidas neste Site são fornecidas tal
                    como estão.</Body>
                  <H2>Uso e Acesso</H2>
                  <Body>Você pode navegar livremente neste Site, mas só pode
                    acessar ou fazer o download de material neste Site para seu uso
                    pessoal e não comercial. Todos os avisos de direitos autorais e
                    outros avisos de propriedade contidos nos materiais baixados
                    devem ser preservados e mantidos. Nenhum outro uso de material
                    neste Site é permitido sem a autorização expressa por escrito da
                    GSK.  O acesso a este Site é permitido temporariamente e a GSK
                    reserva-se o direito de retirar ou alterar o serviço prestado
                    neste Site sem aviso prévio. A GSK não será responsável se, por
                    qualquer motivo, o Site não estiver disponível a qualquer
                    momento ou por qualquer período. Em caso de violação dos termos
                    de uso, a GSK se reserva o direito de suspender ou encerrar
                    qualquer conta que você tenha criado neste webSite.</Body>
                  <H2>Marcas Comerciais/Direitos de propriedade</H2>
                  <Body>Você deve considerar que todas as marcas registradas que
                    aparecem neste Site, sejam ou não impressas em grande escala ou
                    com o símbolo da marca registrada, são marcas registradas da
                    GSK, suas afiliadas, empresas relacionadas ou seus licenciadores
                    ou parceiros de empreendimento conjunto (joint venture), a menos
                    que seja indicado de outra forma. Você deve considerar que tudo
                    o que você vê ou lê neste Site está protegido por direitos
                    autorais, ou a GSK recebeu permissão para usá-lo, a menos que
                    seja indicado e não possa ser usado, exceto conforme previsto
                    nos Termos de Uso ou no texto no Site sem a permissão por
                    escrito da GSK. Nenhuma licença ou direito, em qualquer direito
                    de propriedade do grupo de empresas da GSK (incluindo patentes,
                    marcas registradas e direitos autorais), ou qualquer terceiro é
                    concedido ou conferido a você pelo uso deste Site. O uso ou uso
                    indevido dessas marcas registradas, direitos autorais ou outros
                    materiais, exceto quando permitido, é expressamente proibido e
                    pode violar leis de direitos autorais, leis de marcas
                    registradas ou outras leis, estatutos ou regulamentos.  A GSK
                    não reivindica nenhuma propriedade, nem nenhuma afiliação com
                    nenhuma das marcas registradas de terceiros que aparecem neste
                    Site. Essas marcas comerciais de terceiros são usadas apenas
                    para identificar os produtos e serviços de seus respectivos
                    proprietários, e nenhum patrocínio ou endosso por parte da GSK
                    deve ser deduzido pelo uso dessas marcas.</Body>
                  <H2>Sem Aval</H2>
                  <Body>Links para Site de terceiros podem ser fornecidos para o
                    interesse ou a conveniência dos usuários deste Site. A GSK não
                    assume qualquer responsabilidade pelo conteúdo de Site que não
                    sejam da GSK que forneçam links e não aceita qualquer
                    responsabilidade por qualquer informação ou opinião contida em
                    qualquer Site de terceiros ou violação ou omissão nas políticas
                    de privacidade de terceiros.</Body>
                  <H2>Informações Fornecidas por Você</H2>
                  <Body>Exceto pelas informações abrangidas pelo

                    nosso <LinkButton href='https://privacy.gsk.com/pt-br/privacy-notice/general'>
                      Aviso de Privacidade </LinkButton> ,
                    qualquer comunicação ou material que
                    você transmita ou por meio do Site, incluindo dados, perguntas,
                    comentários, ideias, know-how ou similares serão tratados como
                    não-confidencial e de não-propriedade. A GSK não terá nenhuma
                    obrigação de qualquer natureza em relação a tal informação, e
                    deve ser livre para usar, divulgar, reproduzir ou publicar essas
                    informações sem qualquer tipo de limitação, inclusive para fins
                    comerciais, sem pagar aos usuários o direito de fazê-lo.</Body>
                  <H2>Responsabilidade</H2>
                  <Body>Exceto quando proibido por lei e com exceção de quaisquer
                    garantias expressamente previstas nestes Termos de Uso, qualquer
                    condição, garantia, direito ou responsabilidade que de outra
                    forma estariam implícitas nestes Termos de Uso ou impostos por
                    lei estão excluídos.  O seu uso do Site é por sua conta e risco
                    e a GSK não será responsável por quaisquer danos (incluindo, sem
                    limitação, perdas ou danos diretos, incidentais, consequentes,
                    indiretos ou punitivos, ou danos resultantes de lucros
                    cessantes, dados perdidos ou negócios interrupção) decorrente de
                    seu acesso, uso ou incapacidade de acessar ou usar o Site ou
                    quaisquer erros ou omissões em seu conteúdo. Isso inclui danos
                    ao seu computador ou a outra propriedade, incluindo danos
                    causado por vírus de computador ou qualquer outro material
                    tecnicamente prejudicial que possa infectar seu computador ou
                    outra propriedade em função do uso deste Site, incluindo o
                    download de arquivos ou outros conteúdos da internet.</Body>
                  <H2>Não é um Convite de Proposta</H2>
                  <Body>Nada neste Site constitui um convite ou oferta para
                    investir ou negociar em valores mobiliários ou a ADRs da GSK. Em
                    particular, os resultados e desenvolvimentos reais podem ser
                    materialmente diferentes de qualquer previsão, opinião ou
                    expectativa expressa neste Site, e o desempenho passado do preço
                    dos valores mobiliários não deve ser considerado como um guia
                    para o seu futuro desempenho.</Body>
                  <H2>Declarações Prospectivas</H2>
                  <Body>O Site pode conter declarações prospectivas sujeitas a
                    riscos e incertezas que podem fazer com que os resultados reais
                    sejam diferentes dos previstos, incluindo os riscos detalhados
                    nos relatórios da GSK arquivados na Comissão de Segurança e
                    Intercâmbio [Securities and Exchange Commission] (o "SEC"),
                    incluindo o último relatório arquivado da GSK com a SEC.  A GSK
                    reserva o direito, caso você viole os Termos de Uso, de
                    suspender ou encerrar qualquer conta que você tenha criado neste
                    Site.  Caso ocorra qualquer disputa entre você e a GSK sobre
                    estes Termos de Uso ou o uso deste Site, ele será resolvido por
                    meio de negociações de boa-fé.
                  </Body>
                  <H2>Lei Aplicável e Foro</H2>
                  <Body>Este Acordo de Termos de Uso e a sua utilização do Site
                    serão regidos pela lei brasileira. Todos os itens deste Termo de
                    Uso estão regidos pelas leis vigentes na República Federativa do
                    Brasil. Para todos os assuntos referentes à sua interpretação e
                    cumprimento, as partes se submeterão ao Foro Central da Comarca
                    do Rio de Janeiro, Estado do Rio de Janeiro, exceção feita à
                    reclamações apresentadas por USUÁRIOS que se enquadrem no
                    conceito legal de consumidores, que poderão submeter ao foro de
                    seu domicílio.
                  </Body>
                </Col>
              </Row>
            </Col>
          </Row>
        </Grid>

        <Separator />
        <FooterContainerView />
      </div>
    );
  }
}
