
import { Color, FontSize, Spacing } from '@components/obj.constants';
import styled from 'styled-components';

export const OverdueDosesChatbotCloseStyled = styled.div`
  position: absolute;
  font-size: ${FontSize.Large};
  top: 0;
  right: 0;
  z-index: 10;
  padding: ${Spacing.Large};
  color: ${Color.GrayDark};
  cursor: pointer;
`;

export const OverdueDosesChatbotWrapperStyled = styled.div<{
  mobileAdditionalBottomMargin: number;
  additionalBottomMargin: number;
}>`
  position: fixed;
  right: ${Spacing.Medium};

  bottom: ${props => props.additionalBottomMargin + props.mobileAdditionalBottomMargin + parseInt(Spacing.Medium, 0)}px;
  @media all and (min-width: 48em) {
    bottom: ${props => props.additionalBottomMargin + parseInt(Spacing.Medium, 0)}px;
  }
`;

const bottomMargin = 10;
const rightMarginDesktop = 27;
const popSize = 60;
export const OverdueDosesChatbotPopStyled = styled.div`
  position: absolute;
  bottom: ${bottomMargin}px;

  width: ${popSize}px;
  height: ${popSize}px;
  border-radius: ${popSize / 2}px;
  background: ${Color.Primary};

  right: ${Spacing.Medium};
  @media all and (min-width: 48em) {
    right: ${rightMarginDesktop}px;
  }
`;

const shadowColor = `rgb(0 0 0 / 20%)`;
export const OverdueDosesChatbotMessageWrapperStyled = styled.div`
  position: absolute;
  bottom: ${bottomMargin + popSize + parseInt(Spacing.Large, 0)}px;

  width: ${4 * popSize}px;
  padding: ${Spacing.Medium};
  background: ${Color.White};
  border-radius: ${Spacing.Small};
  box-shadow: 0 1px 2px 1px ${shadowColor};

  right: ${Spacing.Medium};
  @media all and (min-width: 48em) {
    right: ${rightMarginDesktop}px;
  }

  // based on https://codepen.io/rikschennink/pen/mjywQb
  ::before {
    content: '';
    position: absolute;
    top: 100%;
    right: ${popSize / 3}px;

    width: 0;
    height: 0;

    border: .75rem solid transparent;
    border-bottom: none;
    border-top-color: ${Color.White};

    filter: drop-shadow(0 0.095rem 0.075rem ${shadowColor});
  }
`;
