import { SEO, SEOConfig } from '@app/core/hoc/seo.hoc';
import * as React from 'react';
import { Route } from 'react-router';
import { AppointmentData } from './appointment.data';

export const APPOINTMENT_PATH = '/consulta';

const config: SEOConfig = {
  title: 'Vacina Certa - Consulta',
  robots: 'noindex',
  meta: [
    {
      name: 'description',
      content: 'Uma ferramenta prática para auxiliá-lo na orientação sobre vacinação para todas as idades',
    },
  ],
};
const Appointment = SEO(config)(AppointmentData);

export const AppointmentRoute = <Route path={APPOINTMENT_PATH} exact={false} component={Appointment} />;
