/* tslint:disable:all */
import * as React from "react"
import { SVGProps } from "react"

const SvgImunoComprometidos = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={69}
    height={69}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.5 69C53.554 69 69 53.554 69 34.5 69 15.446 53.554 0 34.5 0 15.446 0 0 15.446 0 34.5 0 53.554 15.446 69 34.5 69Z"
      fill="#FEF5E2"
    />
    <mask
      id="a"
      style={{
        maskType: "luminance",
      }}
      maskUnits="userSpaceOnUse"
      x={20}
      y={12}
      width={30}
      height={45}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7 12.938h28.463v43.124H20.7V12.938Z"
        fill="#fff"
      />
    </mask>
    <g mask="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.419 43.935h-4.744v-2.45c3.605-1.754 6.1-5.428 6.1-9.68v-2.022a.701.701 0 0 0 .444-.174c.015-.012.031-.023.045-.037a.689.689 0 0 0 .127-.182c.001-.003 0-.006.002-.01.017-.039.02-.084.03-.126.01-.046.026-.09.027-.136l.002-.009v-2.021c0-2.717-.961-5.213-2.556-7.179-.015-.018-.025-.042-.043-.057C41.5 17.872 40.476 16.5 35 16.5c-6.23 0-11.59 3.988-11.59 10.588v2.021c0 .004.003.006.003.01 0 .045.017.09.027.135.01.042.013.087.03.126.001.004 0 .006.002.01.032.07.077.128.127.182.014.015.03.025.044.037a.635.635 0 0 0 .212.128.698.698 0 0 0 .232.046h.002v2.022c0 4.252 2.494 7.927 6.099 9.68v2.45h-4.743c-2.616 0-4.745 2.116-4.745 4.718v6.062c0 .743.609 1.348 1.356 1.348h25.751c.748 0 1.356-.605 1.356-1.348v-6.063c0-2.6-2.129-4.717-4.744-4.717Z"
        fill="#4F2748"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.444 31.805V30.5s2.92-5.5 9.487-5.5c6.569 0 9.488 5.5 9.488 5.5v1.305c0 5.203-4.256 9.434-9.488 9.434-5.231 0-9.487-4.231-9.487-9.433ZM34.931 42.587a10.82 10.82 0 0 0 3.389-.547v2.568c0 .373.303.674.678.674h3.626c-.89 1.43-3.136 4.044-7.693 4.044-4.597 0-6.825-2.61-7.703-4.044h3.637a.675.675 0 0 0 .678-.673v-2.57a10.82 10.82 0 0 0 3.388.548"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.445 45.282h.246c.681 1.343 3.232 5.391 9.24 5.391s8.559-4.047 9.241-5.39h.247c1.868 0 3.388 1.511 3.388 3.369v6.063h-25.75l-.001-6.063c0-1.858 1.52-3.37 3.39-3.37"
      fill="#DD6386"
    />
    <path
      d="M48.5 29c1.496-.311 1.968-.678 2.5-1.5-1.36-1.992-4.454-2.366-6-.5v3l1 1c.5.5.5 0 .5 0 2.147 2.004 3.348 1.514 5.5 1l-3.5-3Z"
      fill="#FCC74B"
      stroke="#4F2748"
    />
    <path
      d="M44.5 23c-.855-3.422-4.495-5.555-9-5.5-5.443.067-9.2 1.694-10.5 6-.23.764-.442 1.598-.5 2.5-.06.927.038 2.427 0 3.5 3.514-3.432 7.486-5.422 10.5-5.5 3.08-.08 6.205 1.832 10 5.5-.107-1.838 0 .5 0-2.5-.05-.581-.154-2.616-.5-4Z"
      fill="#FCC74B"
    />
  </svg>
);

export default SvgImunoComprometidos;
