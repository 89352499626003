import { CookieConsentContainer } from '@app/components/cookie-consent';
import { PropsBase } from '@app/core/base/props.base';
import { Observer } from '@app/core/decorator/observer.decorator';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { FeaturedH2 } from '@components/atm.typography';
import { Col, Grid, Row, Separator } from '@components/obj.grid';
import * as React from 'react';
import { Container, Service } from 'typedi';

export type MaintenanceViewProps = PropsBase<any>;

@Observer()
@Service()
export class MaintenanceView extends React.Component<MaintenanceViewProps> {
  private analyticsService?: GoogleAnalyticsService = Container.get(GoogleAnalyticsService);

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.analyticsService.pageView('maintenance');
  }

  render() {
    return (
      <div>
        <CookieConsentContainer />
        <Grid>
          <Row center='xs'>
            <Separator />
            <Col xs={12} sm={10} md={9} >
              <Row mb={true}>
                <Col xs={12}>
                  <Separator />
                  <Separator />
                  <Separator />
                  <Separator />
                  <FeaturedH2>Estamos em manutenção</FeaturedH2>
                </Col>
              </Row>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
