import { CloseIconStyled, CloseWrapStyled } from '@components/atm.close/close.styled';
import { hasDocument } from '@components/utils';
import * as React from 'react';
import { ModalBoxBodyStyled, ModalBoxStyled, ModalOpacityStyled, ModalStyled } from './modal.component.style';

export interface ModalProps {
  small?: boolean;
  opened?: boolean;
  onClose?: () => void;
  noGutter?: boolean;
  noAnimation?: boolean;
  maxHeight?: string;
}

interface ModalState {
  opened?: boolean;
}

// TODO: TEMPLATE
export class Modal extends React.Component<ModalProps, ModalState> {
  private readonly ESC_KEY = 27;

  constructor(props: ModalProps) {
    super(props);
    this.state = { opened: this.props.opened ? true : false };
  }

  componentDidMount() {
    if (!hasDocument()) {
      return;
    }
    document.addEventListener('keydown', this.onEsc, false);
  }

  componentWillUnmount() {
    if (!hasDocument()) {
      return;
    }
    document.removeEventListener('keydown', this.onEsc, false);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.opened !== this.state.opened) {
      this.setState({ opened: nextProps.opened });
    }
  }

  open() {
    this.setState({ opened: true });
  }

  close = () => {
    this.setState({ opened: false });
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    return (
      <ModalStyled opened={this.state.opened}>
        <ModalOpacityStyled opened={this.state.opened} onClick={this.close} />
        {this.state.opened ? (
          <ModalBoxStyled {...this.props}>
            <CloseWrapStyled>
              <CloseIconStyled onClick={this.close} />
            </CloseWrapStyled>
            <ModalBoxBodyStyled {...this.props}>{this.props.children}</ModalBoxBodyStyled>
          </ModalBoxStyled>
        ) : (
          ''
        )}
      </ModalStyled>
    );
  }

  private onEsc = event => {
    if (event.keyCode === this.ESC_KEY) {
      this.close();
    }
  };
}
