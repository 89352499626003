import { Spacing } from '@components/obj.constants';
import { useClientRect } from '@components/obj.custom-hooks/client-rect.hook';
import React, { useEffect, useState } from 'react';
import {
  MIN_HEIGHT,
  ShowMoreBoxCollapsibleWrapperStyled, ShowMoreBoxContentStyled, ShowMoreBoxStyled,
} from './show-more-box.component.style';

export interface ShowMoreBoxProps {
  expanded: boolean;
}

export interface SeeMoreBoxState {
  expanded: boolean;
  contentHeight: number;
}

export const ShowMoreBox: React.FunctionComponent<ShowMoreBoxProps> = props => {
  const [rect, ref] = useClientRect();
  const [contentHeight, setContentHeight] = useState(0);
  const minHeight = MIN_HEIGHT;
  const shouldUseSeeMoreFunctionality = minHeight < contentHeight;

  useEffect(() => {
    if (rect) {
      const additionalBottomMargin = parseInt(Spacing.Large, 0);
      setContentHeight(rect.height + additionalBottomMargin);
    }
  }, [rect]);

  const content = <ShowMoreBoxContentStyled ref={ref}>{props.children}</ShowMoreBoxContentStyled>;

  return !shouldUseSeeMoreFunctionality ? (
    content
  ) : (
    <ShowMoreBoxStyled>
      <ShowMoreBoxCollapsibleWrapperStyled
        expanded={props.expanded}
        contentHeight={contentHeight}
        minHeight={minHeight}
      >
        {content}
      </ShowMoreBoxCollapsibleWrapperStyled>
    </ShowMoreBoxStyled>
  );
};

ShowMoreBox.defaultProps = {};
