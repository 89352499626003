import * as React from 'react';
import { Route } from 'react-router';
import { ContactView } from '@app/modules/contact/contact.view';
import { SEO, SEOConfig } from '@app/core/hoc/seo.hoc';

export const CONTACT_PATH = '/contato';

const config: SEOConfig = {
  title: 'Vacina Certa - Contato',
  meta: [
    {
      name: 'description',
      content: 'Entre em contato com a equipe do Vacina Certa!',
    },
  ],
};
const Contact = SEO(config)(ContactView);

export const ContactRoute = <Route path={CONTACT_PATH} exact={true} component={Contact} />;
