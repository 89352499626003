import * as React from 'react';

import { FieldErrorCaptionStyled, FieldHelperCaptionStyled } from './field-caption.component.style';

import { ValidationError } from './validators';

export interface FieldCaptionProps {
  errors?: ValidationError[];
  showAll?: boolean;
  validationPlaceholder?: string;
}

export class FieldCaption extends React.Component<FieldCaptionProps, any> {
  constructor(props: FieldCaptionProps) {
    super(props);
  }

  render() {
    const wrap = this.props.errors.map((error: ValidationError, index: number) => (
      <div key={error.errorName + index}>
        <FieldErrorCaptionStyled>{error.errorMessage}</FieldErrorCaptionStyled>
      </div>
    ));

    if (this.props.showAll) {
      return <div>{wrap}</div>;
    } else {
      if (this.props.errors && this.props.errors.length > 0) {
        return (
          <FieldErrorCaptionStyled key={this.props.errors[0].errorName}>
            {this.props.errors[0].errorMessage}
          </FieldErrorCaptionStyled>
        );
      } else {
        return <FieldHelperCaptionStyled>{this.props.validationPlaceholder}</FieldHelperCaptionStyled>;
      }
    }
  }
}
