import styled from 'styled-components';

export const ProgressStyled = styled.div`
  display: flex;
  justify-content: space-around;

  @media print {
    display: none;
  }
`;
