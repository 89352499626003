import styled from 'styled-components';
import { Color } from '@components/obj.constants';
import { LoadingFrameProps } from './loading-frame.component';

export const LoadingFrameStyled = styled.div`
  position: absolute;
  top: 40%;
  z-index: 1000;
  visibility: ${(props: LoadingFrameProps) => (props.showLoading ? 'visible' : 'hidden')};
  transform: translateY(-50%);
  left: calc(50% - 145px);
`;

export const LoadingFrameOverlayStyled = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;

  background-color: ${Color.Black};
  opacity: 0.5;
  visibility: ${(props: LoadingFrameProps) => (props.showLoading ? 'visible' : 'hidden')};
  transition: all 0.2s ease-out;
`;
