import * as React from 'react';
import { Route } from 'react-router';
// import { LoginData } from '@app/modules/authentication/login.data';
import { SEO, SEOConfig } from '@app/core/hoc/seo.hoc';
import { MaintenanceView } from '../maintenance/maintenance.view';

export const LOGIN_PATH = '/login';

const config: SEOConfig = {
  title: 'Vacina Certa - Login',
  meta: [
    {
      name: 'description',
      content: 'Uma ferramenta prática para auxiliá-lo na orientação sobre vacinação para todas as idades',
    },
  ],
};
const Login = SEO(config)(MaintenanceView);

export const LoginRoute = <Route path={LOGIN_PATH} exact={true} component={Login} />;
