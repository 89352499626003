import { HistoryToken } from '@app/core/route/router';
import { ApolloClientBuilderService } from '@app/core/service/apollo-client-builder.service';
import { GoogleAnalyticsService } from '@app/core/service/google-analytics.service';
import { AuthenticationStore } from '@app/modules/authentication/authentication.store';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { ServerGoogleAnalyticsService } from '@server/services/universal-google-anlytics.service';
import ApolloClient from 'apollo-client';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { useStaticRendering } from 'mobx-react';
import { Container } from 'typedi';
import '../assets/fonts/fonts.css';
import { CosmobotsService } from './core/service/cosmobots.service';
import { GskMapsService } from './core/service/gsk-maps.service';
import { GtmService } from './core/service/gtm.service';
import { PwaInstallService } from './modules/app/pwa-install.service';

/**
 * App config
 *
 * Remember to update metadata in config/webpack/client to reflect
 * changes when running in webpack-dev-server
 */
export interface AppConfig {
  baseUrl: string;
  gtmId: string;
  googleMapsAPIKey: string;
  googleAnalyticsTrackingId: string;
  adminUser: string;
  gskMapUrl: string;
  cosmobotsWidgetId: string;
  debug: boolean;
}

export function configure(config: AppConfig, ssr: boolean = false) {
  if (ssr) {
    // MobX-React SSR - avoid memory leaks
    useStaticRendering(true);
  }

  configureGoogleAnalyticsService(config.googleAnalyticsTrackingId, ssr);
  configureAuthenticationStore(config.adminUser);
  configureApolloClient(config.baseUrl, ssr);
  configureRoutesService(ssr);
  configureGskMapsService(config.gskMapUrl);
  configureCosmbobotsService(config.cosmobotsWidgetId);
  configureGtmService(config.gtmId);
  configurePwaInstallService();
}

function configureAuthenticationStore(adminUser: string) {
  Container.set(AuthenticationStore, new AuthenticationStore(adminUser));
}

function configureApolloClient(url: string, ssr: boolean) {
  // TODO: Uncomment this as soon as we have an API private key
  const middleware = () => {
    const authenticationStore = Container.get(AuthenticationStore);
    if (authenticationStore.token != null) {
      return {
        headers: {
          // 'x-Api-Client': Container.get(ApiTokenService).getToken(),
          Authorization: Container.get(AuthenticationStore).token || null,
        },
      };
    } else {
      return {
        headers: {
          // 'x-Api-Client': Container.get(ApiTokenService).getToken(),
        },
      };
    }
  };

  const client = Container.get(ApolloClientBuilderService).build(url, middleware, ssr);

  Container.set(ApolloClient, client);
}

function configureRoutesService(ssr: boolean) {
  const createHistory = ssr ? createMemoryHistory : createBrowserHistory;

  Container.set(HistoryToken, createHistory());
}

function configureGskMapsService(url: string) {
  Container.set(GskMapsService, new GskMapsService(url));
}

function configureCosmbobotsService(id: string) {
  Container.set(CosmobotsService, new CosmobotsService(id));
}

function configureGoogleAnalyticsService(id: string, ssr: boolean) {
  if (ssr) {
    Container.set(ServerGoogleAnalyticsService, new ServerGoogleAnalyticsService(id));
  } else {
    Container.set(GoogleAnalyticsService, new GoogleAnalyticsService(id));
  }
}

function configurePwaInstallService() {
  Container.set(PwaInstallService, new PwaInstallService());
}
function configureGtmService(gtmId: string) {
  Container.set(GtmService, new GtmService(gtmId));
}
