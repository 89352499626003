// tslint:disable: max-line-length
import { AppIcon } from '@components/atm.app-icon';
import { LinkButton } from '@components/atm.button';
import { Hbox } from '@components/obj.box';
import { Col, Grid, Row } from '@components/obj.grid';
import * as React from 'react';
import {
  FooterPartenersStyled,
  FooterPoweredFontStyled,
  FooterStyled,
  FooterTaqtileSectionStyled,
} from './footer.style';
// tslint:enable: max-line-length

export interface FooterProps {
  onTermsClick?: (e) => void;
  onPrivacyPolicyClick?: (e) => void;
  onContactClick?: (e) => void;
}

export class Footer extends React.Component<FooterProps> {
  render() {
    return (
      <FooterStyled>
        <Grid fluid={true}>
          <Row between='xs' mb={true}>
            {/* Separar usando float */}
            <Col sm={4} xs={12} smOffset={0}>
              <img src='/assets/img/img_logo_color.png' width='225px' height='44px' />
            </Col>
            <Col sm={2} xs={12} smOffset={0}>
              <LinkButton onClick={this.props.onTermsClick}>Termos de uso</LinkButton>
            </Col>
            <Col sm={2} xs={12} smOffset={0}>
              <LinkButton onClick={this.props.onPrivacyPolicyClick}>Políticas de privacidade</LinkButton>
            </Col>
            <Col sm={2} xs={12} smOffset={0}>
              <LinkButton onClick={this.props.onContactClick}>Contato</LinkButton>
            </Col>
          </Row>
          <Row end='xs' mb={true}>
            <Col>
              <a href='https://br.gsk.com/' target='_blank'>
                <FooterPartenersStyled src='/assets/img/gsk.svg' />
              </a>
            </Col>
          </Row>
          <Row center='xs' >
            <Col xs={12}>
              <FooterPoweredFontStyled>
                Este material é destinado exclusivamente para profissionais de
                saúde habilitados a prescrever e dispensar vacinas no Brasil
                <br /> PM-BR-ABX-WCNT-220002 - Abr/2022
              </FooterPoweredFontStyled>
            </Col>
          </Row>
        </Grid>
        <FooterTaqtileSectionStyled>
          <Grid>
            <Row center='xs' mb={true}>
              <Col xs={12}>
                <FooterPoweredFontStyled>
                  © 2001 - 2022 GlaxoSmithKline Brasil Ltda. Todos os direitos reservados. <br />
                  Estrada dos Bandeirantes 8464 – Rio de Janeiro – RJ CEP 22783-110. CNPJ 33.247743/0001-10
                </FooterPoweredFontStyled>
              </Col>
            </Row>
          </Grid>
          <Grid>
            <Row center='xs'>
              <Col xs={12}>
                <FooterPoweredFontStyled>
                  <Hbox hAlign={'center'}>
                    <Hbox.Item noGrow={true}> Powered by</Hbox.Item>
                    <Hbox.Separator />
                    <Hbox.Item noGrow={true}>
                      {' '}
                      <AppIcon.TaqtileLogo />{' '}
                    </Hbox.Item>
                  </Hbox>
                </FooterPoweredFontStyled>
              </Col>
            </Row>
          </Grid>
        </FooterTaqtileSectionStyled>
      </FooterStyled>
    );
  }
}
