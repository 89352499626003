/* tslint:disable:all */
import * as React from 'react';

const SvgOcupacional = props => (
  <svg width={69} height={69} {...props}>
    <defs>
      <path id='ocupacional_svg__a' d='M0 34.5h43.125V0H0z' />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <circle cx={34.5} cy={34.5} r={34.5} fill='#FEF5E2' />
      <g transform='translate(12.938 17.25)'>
        <path
          d='M28.34 4.929H14.785V3.08a1.83 1.83 0 0 1 1.848-1.848h9.857A1.83 1.83 0 0 1 28.34 3.08V4.93zm11.705 0H29.57V3.08A3.087 3.087 0 0 0 26.491 0h-9.857a3.087 3.087 0 0 0-3.08 3.08V4.93H3.08A3.087 3.087 0 0 0 0 8.009v23.41A3.087 3.087 0 0 0 3.08 34.5h36.965a3.087 3.087 0 0 0 3.08-3.08V8.009a3.087 3.087 0 0 0-3.08-3.08z'
          fill='#4F2748'
        />
        <path
          d='M3.08 6.16h1.232v9.242a3.087 3.087 0 0 0 3.08 3.08h1.85v2.464c0 .341.275.616.615.616h4.929c.34 0 .616-.275.616-.616v-2.464h12.321v2.464c0 .341.276.616.616.616h4.929c.34 0 .616-.275.616-.616v-2.464h1.848c1.753 0 3.08-1.577 3.08-3.272V6.16h1.233a1.83 1.83 0 0 1 1.848 1.849v23.41a1.83 1.83 0 0 1-1.848 1.849H3.08a1.83 1.83 0 0 1-1.848-1.848V8.009A1.83 1.83 0 0 1 3.08 6.16'
          fill='#DD6386'
        />
        <path
          d='M5.545 6.16H37.58v9.05c0 1.034-.87 2.04-1.848 2.04H7.392a1.83 1.83 0 0 1-1.847-1.848V6.16z'
          fill='#FCC74B'
        />
        <mask id='ocupacional_svg__b' fill='#fff'>
          <use xlinkHref='#ocupacional_svg__a' />
        </mask>
        <path
          fill='#FFF'
          mask='url(#ocupacional_svg__b)'
          d='M10.473 20.33h3.697v-1.848h-3.697zM28.955 20.33h3.697v-1.848h-3.697z'
        />
      </g>
    </g>
  </svg>
);

export default SvgOcupacional;
