/* tslint:disable:all */
import * as React from 'react';

const SvgBebe = props => (
  <svg width={69} height={69} {...props}>
    <defs>
      <path id='child_svg__a' d='M0 .52h26.725V42.6H0z' />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <circle cx={34.5} cy={34.5} r={34.5} fill='#FEF5E2' />
      <g transform='translate(21.563 13.805)'>
        <mask id='child_svg__b' fill='#fff'>
          <use xlinkHref='#child_svg__a' />
        </mask>
        <path
          d='M26.623 19.502c-.213-.645-.802-1.298-1.853-1.545l.01-.014a5.5 5.5 0 0 1 .197-.261c.963-1.16 1.205-5.068 1.199-5.452-.005-.343.002-.69.01-1.04.031-1.426.065-2.898-.72-4.215-.686-1.147-1.734-1.722-2.766-2.172-.326-.142-.656-.232-.976-.32a5.566 5.566 0 0 1-.785-.255c-.12-.054-.14-.062-.297-.329-2.609-4.433-7.7-3.494-11.79-2.74-2.168.398-4.215.776-5.336.246-.362-.17-.66-.026-.807.146-.14.168-.547.655 1.39 3.326-.976.44-1.948 1.013-2.593 2.096-.786 1.314-.752 2.79-.72 4.215.008.348.014.697.01 1.04-.004.384.236 4.291 1.199 5.45.056.068.117.152.18.24C.99 18.122.33 18.812.101 19.5c-.29.884-.023 2.186 1.6 3.192.767.48 1.554.393 2.025.34.052-.007.116-.013.17-.019 1.285 1.775 2.33 2.455 3.374 3.047 1.615.914 3.388 1.197 4.226 1.283.01.368-.004.958-.03 1.537-.722.105-1.428.259-2.12.56a.571.571 0 0 0-.59-.136c-2.627.93-3.644 2.132-4.901 3.863-.859 1.223-1.147 3.368-1.237 4.954-.077 1.382.01 2.966.125 3.853.07.512.458.625 1.475.625.328 0 .723-.012 1.191-.027a75.734 75.734 0 0 1 2.078-.039l1.352.005c1.59.006 3.768.012 4.668-.005l1.835.011 1.259-.004 2.59-.007c.506-.002 1.007.005 1.473.009 2.67.026 3.305.03 3.395-.666.098-.77.1-2.034.004-3.755-.088-1.584-.374-3.727-1.24-4.96-1.254-1.725-2.272-2.927-4.897-3.857a.568.568 0 0 0-.58.125c-.67-.275-1.357-.403-2.025-.518l-.063-.01a24.002 24.002 0 0 1-.031-1.553c.9-.082 2.876-.364 4.524-1.295.978-.554 1.768-1.21 3.077-3.038.055.004.117.012.17.019.471.053 1.257.141 2.022-.338 1.625-1.008 1.892-2.31 1.602-3.194'
          fill='#4F2748'
          mask='url(#child_svg__b)'
        />
      </g>
      <path
        d='M45.974 35.559c-.426.267-.885.213-1.286.17-.374-.04-.842-.094-1.116.296-1.257 1.777-1.961 2.38-2.824 2.866-1.913 1.083-4.457 1.18-4.483 1.181a.57.57 0 0 0-.493.317c-.056.115-.204.423-.058 2.827a.563.563 0 0 0 .566.524h.01c.01.002.015.005.021.007l.378.066c.535.093 1.047.181 1.53.346-1.116 1.312-2.804 2.444-3.313 2.444-1.026 0-2.476-1.319-3.343-2.422.623-.236 1.291-.351 1.978-.444h.032a.563.563 0 0 0 .566-.523c.144-2.406-.004-2.713-.06-2.83a.568.568 0 0 0-.493-.316c-.023-.002-2.265-.086-4.185-1.173-.994-.563-1.915-1.169-3.12-2.876-.276-.39-.744-.337-1.118-.294-.403.045-.86.097-1.289-.172-.885-.549-1.328-1.297-1.128-1.907.194-.591.966-.934 1.96-.869a.551.551 0 0 0 .43-.158c.518-.146.65-.75.694-.953.098-.46.148-.912.196-1.35.119-1.093.224-2.036 1.034-2.743 1.55-1.355 2.458-3.498 2.4-5.499 2.381.972 5.072.793 5.18.785 1.51-.045 4.065-.261 6.016-1.14-.21 2.295.932 4.581 2.388 5.854.81.71.913 1.652 1.034 2.743.048.438.098.89.196 1.35.03.14.102.464.307.705a.557.557 0 0 0 .57.406c.997-.065 1.766.278 1.96.87.196.613-.248 1.361-1.137 1.912z'
        fill='#FFF'
      />
      <path
        d='M39.522 44.314c2.054.812 2.851 1.802 3.935 3.294.548.778.924 2.374 1.037 4.38.077 1.394.09 2.495.035 3.219-.474.04-1.466.032-2.291.024-.47-.004-.976-.01-1.485-.008-1.316.002-2.033.004-2.593.006l-1.251.004-1.845-.01c-.902.015-3.072.008-4.658.004l-1.353-.004c-.754-.002-1.485.02-2.114.039-.568.017-1.195.035-1.548.02-.087-.889-.14-2.197-.08-3.295.116-2.037.484-3.593 1.033-4.374 1.084-1.493 1.881-2.485 3.927-3.294.85 1.263 2.851 3.4 4.633 3.4 1.098 0 3.444-1.763 4.618-3.405z'
        fill='#DD6386'
      />
      <path
        d='M23.49 26.05c.007-.355-.002-.716-.01-1.078-.03-1.329-.059-2.584.564-3.625.493-.827 1.255-1.285 2.25-1.718.14-.062.283-.109.43-.154a.56.56 0 0 0 .37-.361.547.547 0 0 0-.086-.506 18.699 18.699 0 0 1-1.402-2.085c1.36.215 3.086-.103 5.018-.458 3.933-.725 8.393-1.547 10.599 2.202.198.337.371.592.814.787.311.136.635.226.947.31.292.08.57.157.818.265.992.434 1.755.89 2.248 1.715.622 1.044.593 2.297.564 3.626-.009.362-.017.723-.01 1.08.01.708-.337 3.999-.945 4.733-.073.09-.155.198-.238.313l-.086.118c-.06-.337-.098-.68-.133-1.015-.134-1.212-.27-2.464-1.408-3.456-1.665-1.456-2.438-4.012-1.799-5.947a.554.554 0 0 0-.209-.625.576.576 0 0 0-.668.004c-1.78 1.297-5.15 1.53-6.54 1.572-.033.002-3.273.214-5.513-1.107a.574.574 0 0 0-.622.024.552.552 0 0 0-.224.572c.408 1.865-.399 4.18-1.915 5.507-1.139.994-1.275 2.246-1.408 3.458-.038.334-.075.678-.134 1.014l-.086-.116a6.147 6.147 0 0 0-.24-.317c-.61-.732-.956-4.024-.946-4.731z'
        fill='#4F2748'
      />
    </g>
  </svg>
);

export default SvgBebe;
