import { Color, Spacing } from '@components/obj.constants';
import styled from 'styled-components';

export interface ClickableWrapperStyledProps {
  disabled?: boolean;
}
export const ClickableWrapperStyled = styled.div<ClickableWrapperStyledProps>`
  cursor: pointer;
  padding: ${Spacing.Large};
  margin-bottom: ${Spacing.Large};
  border-radius: ${Spacing.Small};
  border: 2px solid ${Color.GrayLight};
  background: ${props => (props.disabled ? Color.GrayXLight : Color.White)};

  :hover {
    background: ${props => (props.disabled ? Color.GrayXLight : Color.GrayLight)};
  }
  :active {
    background: ${props => (props.disabled ? Color.GrayXLight : Color.GrayXLight)};
  }
  transition: all 0.3s;
`;
