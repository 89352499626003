/* tslint:disable:all */
import * as React from 'react';

const SvgGestante = props => (
  <svg width={69} height={69} {...props}>
    <defs>
      <path id='gestante_svg__a' d='M0 .072h31.009v28.596H0z' />
    </defs>
    <g fill='none' fillRule='evenodd'>
      <circle cx={34.5} cy={34.5} r={34.5} fill='#FEF5E2' />
      <path
        d='M38.79 40.668V33.66h5.588c1.028 0 1.863-.857 1.863-1.91V19.003C46.241 12.33 40.95 6.9 34.442 6.9c-6.506 0-11.799 5.43-11.799 12.104V31.75c0 1.054.836 1.911 1.863 1.911h5.59v7.009'
        fill='#4F2748'
      />
      <path
        d='M24.506 32.386a.63.63 0 0 1-.62-.637V21.52c.21-.02.417-.053.62-.095v2.042c0 3.836 2.078 7.18 5.137 8.92h-5.137z'
        fill='#4F2748'
      />
      <path
        d='M28.963 17.708c.105-.02.199-.063.278-.129 2.117 2.415 7.375 4.18 13.895 4.541v1.347c0 4.918-3.9 8.92-8.694 8.92-4.794 0-8.694-4.002-8.694-8.92v-2.45a6.327 6.327 0 0 0 3.215-3.31M31.337 33.143c.979.332 2.02.518 3.105.518a9.638 9.638 0 0 0 3.105-.518v6.758c-.146.294-.85.13-3.105.13-2.258 0-2.954.178-3.105-.132v-6.756z'
        fill='#FFF'
      />
      <path
        d='M44.378 32.386h-5.136c3.06-1.738 5.136-5.083 5.136-8.919v-1.296c.207.006.413.01.621.013v9.566a.63.63 0 0 1-.62.636M45 19.004v1.905c-.341-.005-.679-.012-1.01-.023a.596.596 0 0 0-.233-.048c-.054 0-.103.018-.154.032-8.532-.379-14.129-3.117-14.129-5.688V9.453a10.275 10.275 0 0 1 4.968-1.279C40.264 8.174 45 13.033 45 19.004M28.232 10.265v4.917c0 2.594-1.9 4.737-4.347 5.052v-1.23c0-3.59 1.719-6.766 4.347-8.739'
        fill='#4F2748'
      />
      <g transform='translate(18.975 33.66)'>
        <mask id='gestante_svg__b' fill='#fff'>
          <use xlinkHref='#gestante_svg__a' />
        </mask>
        <path
          d='M12.575 3.516a.73.73 0 0 1-.71-.58l-.758-1.544C10.92.537 10.11-.038 9.256.089L6.945.46c-.016.006-.038.006-.054.011-1.825.471-3.256 1.85-3.826 3.69L.027 13.81a.602.602 0 0 0 .37.75l4.54 1.582c-.08.537-.122 1.085-.122 1.638 0 6.005 4.76 10.89 10.615 10.89 5.854 0 10.615-4.885 10.615-10.89 0-.537-.037-1.062-.112-1.588l4.686-1.632a.608.608 0 0 0 .363-.75L27.95 4.162c-.57-1.84-2-3.219-3.831-3.69-.017-.005-.038-.005-.054-.011L21.744.089c-.85-.126-1.66.448-1.847 1.303l-.758 1.543a.724.724 0 0 1-.71.58'
          fill='#4F2748'
          mask='url(#gestante_svg__b)'
        />
      </g>
      <path
        d='M44.668 48.673a11.012 11.012 0 0 0-2.087-4.172c.726-1.653.41-3.668-.928-5.004a.577.577 0 0 0-.827.011.613.613 0 0 0 .01.849 3.308 3.308 0 0 1 .064 4.582 3.11 3.11 0 0 1-4.467.066.577.577 0 0 0-.827.011.614.614 0 0 0 .01.849 4.25 4.25 0 0 0 6.116-.088c.064-.066.123-.131.182-.203a9.847 9.847 0 0 1 1.937 5.875c0 5.344-4.238 9.69-9.446 9.69-5.209 0-9.447-4.346-9.447-9.69 0-2.14.69-4.21 1.949-5.896a4.247 4.247 0 0 0 3.266 1.533h.064a4.249 4.249 0 0 0 3.042-1.342.615.615 0 0 0-.01-.849.577.577 0 0 0-.828.012 3.102 3.102 0 0 1-2.22.98 3.065 3.065 0 0 1-2.247-.92 3.26 3.26 0 0 1-.955-2.278 3.252 3.252 0 0 1 .896-2.305.614.614 0 0 0-.01-.848.578.578 0 0 0-.828.01 4.466 4.466 0 0 0-1.222 3.154c.011.63.144 1.232.39 1.785a10.95 10.95 0 0 0-2.087 4.14l-3.864-1.348 2.861-9.088c.448-1.435 1.558-2.513 2.978-2.891l2.279-.366a.484.484 0 0 1 .539.382l.757 1.544c.198.892.956 1.516 1.847 1.516h5.86c.891 0 1.65-.624 1.846-1.516l.759-1.544a.486.486 0 0 1 .533-.382l2.284.366c1.42.378 2.53 1.456 2.978 2.89l2.86 9.09-4.007 1.395z'
        fill='#DD6386'
      />
    </g>
  </svg>
);

export default SvgGestante;
